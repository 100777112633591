import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { MenuItem } from '../../services/menu.service';
import { GSearchService } from '../../../g-search/services/g-search.service';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocationMonitorService } from '../../../core/modules/shared/services/location-monitor.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { FavoritesService } from '../../../core/modules/user-pref/services/favorites.service';
import { Translations } from '@translation-keys';
import { BreakpointService } from '../../services/breakpoint.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDrawerContainer } from '@angular/material/sidenav';

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent implements OnInit, AfterViewInit, OnDestroy {
    panelOpenState = false;
    isToggled = false;
    @Input({ required: true }) menu!: MenuItem;
    @Input() initWithMenusExpanded: boolean | undefined = false;

    @ViewChildren(MatExpansionPanel) expansionPanels?: QueryList<MatExpansionPanel>;

    subscriptionsRef: Subscription[] = [];

    translations = Translations;
    isLoadingFavorite = false;
    displayHover = false;
    itemHover = false;
    isSimplified = false;
    currentRoute = '';

    constructor(
        protected gSearchService: GSearchService,
        protected router: Router,
        protected locationMonitorService: LocationMonitorService,
        protected favoritesServices: FavoritesService,
        protected cdr: ChangeDetectorRef,
        protected breakpointService: BreakpointService,
        protected destroyRef: DestroyRef,
        protected matDrawer: MatDrawerContainer,
    ) {
        const routerRef = router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.closeMenu();
                this.cdr.detectChanges();
            }
        });

        this.subscriptionsRef.push(routerRef);
    }

    ngOnInit() {
        this.breakpointService.isSimplified$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(status => (this.isSimplified = status));
    }

    ngAfterViewInit() {
        const locationMonitorRef = this.locationMonitorService.isLastPath$.subscribe(() => {
            if (!this.panelOpenState) {
                const shouldOpen = this._checkCurrentRoute(this.menu, false);

                if (shouldOpen) {
                    this.expansionPanels?.forEach(panel => panel.open());
                }
            }
        });
        this.subscriptionsRef.push(locationMonitorRef);

        if (this.menu.label !== this.translations.menus.core.children.favorites.title) {
            const favoritesRef = this.favoritesServices.favorites$.subscribe(() => {
                this._checkCurrentRoute(this.menu, false);
            });
            this.subscriptionsRef.push(favoritesRef);
        }
    }

    _checkCurrentRoute(menu: MenuItem, status: boolean): boolean {
        const path = window.location.pathname;
        if (menu.ng1ActiveRouteRegexs && !status) {
            status = menu.ng1ActiveRouteRegexs.some(regex => new RegExp(regex).test(path));
        }

        if (path === menu.url || path === `/${menu.url}`) {
            status = true;
        }

        if (!status && menu.children) {
            menu.children.forEach(child => {
                status = this._checkCurrentRoute(child, status);
                if (!status) {
                    status = path === child.url;
                }
            });
        }

        return status;
    }

    isRouteActive(menuItem: MenuItem, currentRoute?: string): boolean {
        if (!currentRoute) {
            currentRoute = window.location.pathname;
        }

        if (menuItem.ng1ActiveRouteRegexs) {
            for (const regex of menuItem.ng1ActiveRouteRegexs) {
                const regexPattern = new RegExp(regex);
                if (regexPattern.test(currentRoute)) {
                    return true;
                }
            }
        }

        for (const child of menuItem.children) {
            if (this.isRouteActive(child, currentRoute)) {
                return true;
            }
        }

        return false;
    }

    closeMenu() {
        this.gSearchService.close();
    }

    ngOnDestroy() {
        this.subscriptionsRef.forEach(subscription => subscription.unsubscribe());
    }

    addFavorite($event: MouseEvent, label: string) {
        $event.preventDefault();
        $event.stopPropagation();
        this.displayHover = false;
        this.isLoadingFavorite = true;
        this.cdr.detectChanges();
        const ref = this.favoritesServices.addFavorite(label).subscribe(() => {
            this.isLoadingFavorite = false;
            this.cdr.detectChanges();
            ref.unsubscribe();
        });
    }

    removeFavorite($event: MouseEvent, label: string) {
        $event.preventDefault();
        $event.stopPropagation();
        this.displayHover = false;
        this.isLoadingFavorite = true;
        this.cdr.detectChanges();
        const ref = this.favoritesServices.removeFavorite(label).subscribe(() => {
            this.isLoadingFavorite = false;
            this.cdr.detectChanges();
            ref.unsubscribe();
        });
    }

    trackBySideMenuItem($index: number, menu: MenuItem) {
        return menu.label;
    }

    menuClick() {
        this.matDrawer._closeModalDrawersViaBackdrop();
    }
}
