<h2 mat-dialog-title> {{ 'sd-table.selected-rows' | translate }} </h2>
<mat-dialog-content>

    <sd-table
        *ngIf="data.endpoint"
        [endpoint]="data.endpoint"
        [selectedRows]="data.selectedRows"
        [clientColumns]="data.clientColumns"
        [disableHeaderActions]="true"
        [paginate]="false"
        (selectedRowsChange)="selectedRows = $event"
    ></sd-table>

</mat-dialog-content>
<mat-dialog-actions align="start">
    <button mat-button mat-dialog-close [mat-dialog-close]="selectedRows"> {{ 'common.close' | translate }} </button>
</mat-dialog-actions>
