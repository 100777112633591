<div class="sd-table-custom-actions">
    
    <div *ngFor="let action of actions">

        <mat-icon *ngIf="action.type === 'visual-icon'" [fontIcon]="action.icon ?? ''"></mat-icon>
        
        <button
          *ngIf="action.type === 'button' && action.callback"
          mat-raised-button
          [matTooltip]="action.tooltip ?? ''"
          matTooltipPosition="below"
          [color]="action.color"
          (click)="action.callback()">
            <mat-icon class="icon" [fontIcon]="action.icon ?? ''"></mat-icon>
        </button>

        <a
          *ngIf="action.type === 'link'"
          mat-raised-button
          [href]="action.url"
          [matTooltip]="action.tooltip ?? ''"
          matTooltipPosition="below"
          [color]="action.color">
            <mat-icon class="icon" [fontIcon]="action.icon ?? ''"></mat-icon>
        </a>
    </div>

</div>
