import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
} from '@angular/core';
import { GSearchDataSearchStates } from '../../types';
import { MenuItem, MenuService } from '../../../layout/services/menu.service';
import { Translations } from '@translation-keys';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-data-search-menu',
    templateUrl: './data-search-menu.component.html',
    styleUrl: './data-search-menu.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSearchMenuComponent implements OnInit, OnChanges {
    @Input({ required: true }) searchTerms = '';
    @Output() qtdMenuFoundedChange: EventEmitter<number> = new EventEmitter<number>();

    translations = Translations;

    /**
     * Count of total items
     */
    count = 0;
    availableStates = GSearchDataSearchStates;
    state: GSearchDataSearchStates = GSearchDataSearchStates.LOADING;
    paths: string[] = [];
    routesByPaths: { [p: string]: Array<MenuItem> } = {};

    constructor(public menuService: MenuService, public cdr: ChangeDetectorRef, public destroyRef: DestroyRef) {}

    ngOnChanges() {
        this.state = GSearchDataSearchStates.LOADING;
        this.updateData();
    }

    ngOnInit() {
        this.state = GSearchDataSearchStates.LOADING;
        this.updateData();
        this.menuService.menusByModule.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.updateData());
    }

    updateData() {
        const routes = this.menuService.getFilteredMenuRoutes(this.searchTerms);
        const groupedResults: { [key: string]: Array<MenuItem> } = {};

        for (const result of routes) {
            const path = result.menuSearchPath ?? '';
            if (path.includes('Favorites')) continue;
            if (!groupedResults[path]) {
                groupedResults[path] = [];
            }
            groupedResults[path].push(result);
        }

        this.paths = Object.keys(groupedResults).filter(key => key != '');
        this.routesByPaths = groupedResults;

        this.state =
            this.paths.length > 0
                ? GSearchDataSearchStates.READY_WITH_RESULTS
                : GSearchDataSearchStates.READY_WITHOUT_RESULTS;

        this.count = routes.length;
        this.qtdMenuFoundedChange.emit(this.count);
        this.cdr.detectChanges();
    }
}
