import { Component, Inject } from '@angular/core';
import { Translations } from '@translation-keys';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-dirty-popup-close',
    templateUrl: './dirty-popup-close.component.html',
    styleUrl: './dirty-popup-close.component.scss',
})
export class DirtyPopupCloseComponent {
    protected readonly translations = Translations;

    constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string; description: string; message: string }) {}
}
