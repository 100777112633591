import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GSearchPanelComponent } from './components/g-search-panel/g-search-panel.component';
import { DataSearchComponent } from './components/data-search/data-search.component';
import { DataSearchUpstreamComponent } from './components/data-search-upstream/data-search-upstream.component';
import { DataSearchResultItemComponent } from './components/data-search-result-item/data-search-result-item.component';
import { DataSearchResultHeaderComponent } from './components/data-search-result-header/data-search-result-header.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { DataSearchMenuComponent } from './components/data-search-menu/data-search-menu.component';
import { LayoutModule } from '../layout/layout.module';
import { AngularMaterialModule } from '@shared/angular-material.module';
import { PipesModule } from '@shared/pipes.module';

@NgModule({
    declarations: [
        GSearchPanelComponent,
        DataSearchComponent,
        DataSearchUpstreamComponent,
        DataSearchResultItemComponent,
        DataSearchResultHeaderComponent,
        DataSearchMenuComponent,
    ],
    imports: [CommonModule, AngularMaterialModule, NgScrollbar, LayoutModule, PipesModule],
    exports: [GSearchPanelComponent],
})
export class GSearchModule {}
