import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ComponentType } from '@angular/cdk/portal';

@Injectable({
    providedIn: 'root',
})
export class SdExtraInfoService {
    constructor(protected _snackBar: MatSnackBar) {}

    openSnackBar(component: ComponentType<unknown>, data?: unknown) {
        return this._snackBar.openFromComponent(component, {
            data,
            horizontalPosition: 'start',
            verticalPosition: 'top',
            panelClass: 'page-extra-info',
        });
    }

    closeSnackBar(snackBarRef: MatSnackBarRef<unknown>) {
        snackBarRef.dismiss();
    }

    closeAnySnackBar() {
        this._snackBar.dismiss();
    }
}
