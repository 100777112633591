@if(notification.notificationType === 'schooldrive') {
    <ng1-link [url]="getStateAsUrl(notification.notificationState)">
        <div class="notification-item">
            <div class="icon-background">
                <i class="{{notification.notificationIcon}}"></i>
            </div>
            <p>{{ translations.core.modules.notifications.modules.schooldrive.commons.labels[notification.notificationTranslate] | translate:{ notificationQuantity: notification.notificationQuantity } }}</p>
        </div>
    </ng1-link>
}

