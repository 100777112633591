import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { debounceTime, distinctUntilChanged, map, Observable, startWith } from 'rxjs';
import { SdSelectCustomParameter, SdSelectedId, SdSelectOption } from '../../types';
import { SelectService } from '../../services/select.service';
import { Translations } from '@translation-keys';

@Component({
    selector: 'sd-select',
    templateUrl: './sd-select.component.html',
    styleUrls: ['./sd-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SdSelectComponent),
            multi: true,
        },
    ],
})
export class SdSelectComponent implements ControlValueAccessor, OnInit {
    @Input() endpoint?: string;
    @Input() data?: SdSelectOption[];
    @Input() label = '';
    @Input() multiple = false;
    @Input() disable = false;
    @Input() required = false;
    @Input() hasReload = true; // when options populated by data, it be false
    @Input() customParameters: SdSelectCustomParameter[] = [];
    @Input() icon = 'arrow_drop_down';
    @Input() displayOnlyIcon = false;

    /* goes into NgModel
     *  can be a number (multiple: false)
     *  or an array (multiple: true)
     */
    @Output() readonly selectedId = new EventEmitter<SdSelectedId>();

    /* returns an object with all SdSelectOption properties add with extra properties provided by the server
     *  can be a object (multiple: false)
     */
    @Input() selectedObject?: SdSelectOption | null;
    @Output() readonly selectedObjectChange = new EventEmitter<SdSelectOption>();

    /* returns an array with all SdSelectOption properties add with extra properties provided by the server
     *  can be an array (multiple: true)
     */
    @Input() selectedObjects: SdSelectOption[] = [];
    @Output() readonly selectedObjectsChange = new EventEmitter<SdSelectOption[]>();

    @ViewChild('search') search?: ElementRef;

    translations = Translations;

    isWaitingForOptions = true;

    filterControl: UntypedFormControl = new UntypedFormControl();

    options: SdSelectOption[] = [];
    selected: SdSelectedId = null;
    allSelected: SdSelectedId = null;
    hasArchivedOption = false;

    optionsByGroup: SdSelectOption[] = [];
    filteredOptionsByGroup: { [group: string]: Observable<SdSelectOption[]> } = {};
    hasManyOptionsCss = false;

    constructor(private _selectService: SelectService) {}

    ngOnInit() {
        if (this.multiple && this.selectedObject) {
            throw Error(
                'selectedObject is not available to multiple=true, please use multiple=false or selectedObjects',
            );
        }

        if (!this.multiple && this.selectedObjects.length > 0) {
            throw Error(
                'selectedObjects is not available to multiple=false, please use multiple=true or selectedObject',
            );
        }

        if (!this.selectedObject && this.selectedObjects.length === 0) {
            this.getOptions();
        } else {
            if (this.selectedObject) {
                this.setOptions([this.selectedObject]);
            } else {
                this.setOptions(this.selectedObjects);
            }
            this.isWaitingForOptions = false;
        }
    }

    getOptions() {
        this.isWaitingForOptions = true;
        this.selectedObject = null;
        this.selectedObjects = [];
        this.setOptions([]);

        if (this.endpoint && this.data) {
            return console.error('endpoint and data is set, this is not supported');
        }

        if (this.endpoint) {
            this.getOptionsByEndpoint(this.endpoint);
        }

        if (this.data) {
            this.hasReload = false;
            this.setOptions(this.data);
        }
    }

    getOptionsByEndpoint(endpoint: string) {
        if (this.selected) {
            if (typeof this.selected === 'number' || typeof this.selected === 'string') {
                endpoint += `?ids-regardless-archive-status=${this.selected}`;
            } else {
                endpoint += `?ids-regardless-archive-status=${this.selected.join(',')}`;
            }
        }
        if (this.customParameters.length > 0) {
            this.customParameters.forEach((parameter, key) => {
                if (!this.selected && key === 0) {
                    endpoint += `?${parameter.key}=${parameter.value}`;
                } else {
                    endpoint += `&${parameter.key}=${parameter.value}`;
                }
            });
        }
        this._selectService.getData(endpoint).subscribe({
            next: (data: SdSelectOption[]) => this.setOptions(data),
            error: e => console.log('error', e),
        });
    }

    setOptions(data: SdSelectOption[]) {
        data.forEach((option: SdSelectOption) => {
            if (option.isArchive) {
                option.label += ` - (archived)`; // todo - translate it
                this.hasArchivedOption = true;
            }
        });
        this.isWaitingForOptions = false;
        this.options = data;
        this.hasManyOptionsCss = window.innerHeight <= data.length * 48 + 40 + 56;
        this.setOptionsByGroup(data);
        this.sortOptions(true);
    }

    getGroups() {
        return Object.keys(this.optionsByGroup);
    }

    countOptionsInGroup(group: string) {
        return this._filter(this.filterControl.value ?? '', group).length;
    }

    setOptionsByGroup(data: SdSelectOption[]) {
        data.forEach(option => {
            if (!option.group) {
                option.group = 'no-group';
            }

            if (!this.optionsByGroup[option.group]) {
                this.optionsByGroup[option.group] = [];
            }

            this.optionsByGroup[option.group].push({
                key: option.id,
                label: option.label,
            });
        });

        data.forEach(option => {
            const group = option.group ?? 'no-group';
            this.filteredOptionsByGroup[group] = new Observable<SdSelectOption[]>();
        });
    }

    registerOnChange(fn: (selected?: SdSelectedId) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    onTouched = () => console.log('onTouched');

    propagateChange(_?: SdSelectedId): void {
        console.log('propagateChange', _);
    }

    writeValue(obj: SdSelectedId = null): void {
        this.selected = obj;
        this.allSelected = obj;
    }

    private _filter(value: string, group: string): SdSelectOption[] {
        return this.options
            .filter(option => option.group === group)
            .filter(option => {
                const searchFilter = option.label.toLowerCase().includes(value.toLowerCase());
                return searchFilter;
            });
    }

    resetAutoComplete() {
        Object.keys(this.optionsByGroup).forEach(group => {
            this.filteredOptionsByGroup[group] = this.filterControl.valueChanges.pipe(
                debounceTime(400),
                distinctUntilChanged(),
                startWith(''),
                map(value => this._filter(value, group)),
            );
        });
    }

    private _getNotSelectedItems(group: string): SdSelectOption[] {
        const selected = this.selected;
        if (selected instanceof Array) {
            return this.options
                .filter(option => option.group === group)
                .filter((option: SdSelectOption) => !selected.includes(option.id));
        } else {
            return this.options.filter(option => option.group === group).filter(option => option.id !== this.selected);
        }
    }

    private _getSelectedItems(group: string): SdSelectOption[] {
        const selected = this.selected;
        if (selected instanceof Array) {
            return this.options
                .filter(option => option.group === group)
                .filter((option: SdSelectOption) => selected.includes(option.id));
        } else {
            return this.options.filter(option => option.group === group).filter(option => option.id === this.selected);
        }
    }

    private _sort(options: SdSelectOption[]): SdSelectOption[] {
        return options.sort((a: SdSelectOption, b: SdSelectOption) => {
            if (!a.sortOrder) a.sortOrder = 0;
            if (!b.sortOrder) b.sortOrder = 0;

            if (a.sortOrder < b.sortOrder) return -1;
            if (a.sortOrder > b.sortOrder) return 1;
            if (a.sortOrder == b.sortOrder) {
                return a.label.localeCompare(b.label);
            }
            return 0;
        });
    }

    sortOptions(init = false) {
        Object.keys(this.optionsByGroup).forEach(group => {
            const selectItems = this._getSelectedItems(group).sort();
            const notSelectedItems = this._sort(this._getNotSelectedItems(group));

            this.optionsByGroup[group] = [...selectItems, ...notSelectedItems];
        });
        if (init) {
            this.resetAutoComplete();
        }
    }

    reloadOptions($event) {
        if (this.isWaitingForOptions) return;
        this.getOptions();
        $event.stopPropagation();
    }

    getSelectedCount() {
        if (this.allSelected === null) return 0;
        if (typeof this.allSelected === 'number') return 1;
        return this.allSelected.length;
    }

    /* check all */
    isIndeterminate(): boolean {
        const selected = this.selected;
        if (!selected) return false;
        if (typeof selected === 'number') return false;
        return selected.length > 0 && selected.length !== this.options.length;
    }

    isChecked(): boolean {
        const selected = this.selected;
        if (!selected) return false;
        if (typeof selected === 'number') return false;
        return selected.length === this.options.length;
    }

    toggleSelection($event: MatCheckboxChange) {
        if (!this.multiple) {
            return console.error('Error on calling sd-select::toggleSelection, it must be multiple');
        }
        if ($event.checked === true) {
            this.selected = this.options.map(option => option.id);
        } else {
            this.selected = [];
        }
        this.allSelected = this.selected;
        this.onClose();
    }

    onOpenedChange(status: boolean): void {
        if (status) {
            this.onOpen();
        } else {
            this.onClose();
        }
    }

    onOpen() {
        this.checkPanelPosition();
        window.addEventListener('scroll', () => this.checkPanelPosition());
        this.filterControl.setValue('');
        this.resetAutoComplete();
        this.search?.nativeElement.focus();
    }

    onClose() {
        window.removeEventListener('scroll', () => this.checkPanelPosition());
        if (this.selectedObject || this.selectedObjects.length > 0) {
            this.getOptions();
        }

        if (!this.multiple) {
            const option = this.options.filter(option => option.id === this.selected)[0] ?? null;
            this.selectedObject = option;
            this.selectedObjectChange.emit(option);
            this.propagateChange(this.selected);
            this.sortOptions();
            return;
        }

        /* only run if is multiple */
        this.allSelected = this.selected;
        this.selectedId.emit(this.selected);

        const selected = this.selected;
        if (Array.isArray(selected)) {
            this.selectedObjects = this.options.filter(option => selected.includes(option.id));
            this.selectedObjectsChange.emit(this.options.filter(option => selected.includes(option.id)));
        } else {
            this.selectedObjects = [];
            this.selectedObjectsChange.emit([]);
        }

        this.propagateChange(this.selected);
        this.sortOptions();
    }

    checkPanelPosition() {
        const pane = document.querySelector('.cdk-overlay-pane');
        if (pane && pane.querySelector('.sd-select-expand-panel') !== null) {
            const styles = window.getComputedStyle(pane);
            const leftValue = parseInt(styles.left);

            const difference = window.innerWidth - leftValue;

            let newRight = difference + 150;
            const panel = pane.querySelector('.mat-mdc-select-panel');
            if (panel) {
                newRight = parseInt(panel['offsetWidth']) / 2 + difference;
            }
            if (difference < 300) {
                pane['style'].left = 'unset';
                pane['style'].right = newRight + 'px';
            }
        }
    }
}
