@if (state === availableStates.READY_WITH_RESULTS) {
    <app-data-search-result-header
        [title]="upstreamName"
        [isLoading]="false"
    />

    @for (item of data; track item.link) {
        <app-data-search-result-item [item]="item" />
    }
    <div class="total">
        {{ translations.gSearch.commons.labels.totalItemsDisplay | translate:{ displaying: data.length, total: count } }}
        @if (count > data.length) {
            <span class="show-more" aria-hidden="true" (click)="search(data.length + 100)"> | {{ translations.gSearch.commons.actions.showMore.label | translate }} </span>
        }
    </div>
}
