import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, forkJoin } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    private lang = new BehaviorSubject<string>('en');
    public lang$ = this.lang.asObservable();

    constructor(public translateService: TranslateService, public http: HttpClient) {}

    setLanguage(language: string): void {
        const { translateService, http } = this;

        const forkJoinSources = [http.get(`/api/translations/${language}.json`)];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        forkJoin(forkJoinSources).subscribe((response: any) => {
            const translatedKeys = response[0] ?? {};
            translateService.setTranslation(language, translatedKeys, true);
            translateService.use(language);
            this.lang.next(language);
        });
    }
}
