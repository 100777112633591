@if (isSimplified) {
    <section class="toolbar responsive-navigation-header">
        @if (displaySearch && hasSearch) {
        <mat-form-field class="example-form-field">
            <i matPrefix class="prefix fi fi-rr-search"></i>
            <input #inputSearch matInput type="text" [(ngModel)]="value" (ngModelChange)="onSearchChange()">
            <i matSuffix class="suffix fi fi-sr-cross" aria-hidden="true" (click)="clearSearch()" (keydown)="clearSearch()"></i>
        </mat-form-field>
        }

        <div>
            <div class="menu-structure" (click)="openModules()" aria-hidden="true">
                <i class="fi fi-br-menu-burger"></i>
            </div>
            {{ currentMenu?.label ?? '' | translate }}
        </div>

        <div class="filters">
            @if (hasFilters) {
                <i [class]="filterClass()" (click)="displayFilters()" (keydown)="displayFilters()" aria-hidden="true"></i>
            }

            @if (hasSearch) {
                <i class="fi fi-rr-search" (click)="enableSearch()" (keydown)="enableSearch()" aria-hidden="true"></i>
            }
        </div>
    </section>
}
