import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SdSelectComponent } from './components/sd-select/sd-select.component';
import { PipesModule } from '@shared/pipes.module';
import { AngularMaterialModule } from '@shared/angular-material.module';

@NgModule({
    declarations: [SdSelectComponent],
    imports: [FormsModule, ReactiveFormsModule, CommonModule, PipesModule, AngularMaterialModule],
    exports: [SdSelectComponent],
})
export class SdSelectModule {}
