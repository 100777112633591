<h2 mat-dialog-title> {{ data.name }} </h2>

<mat-dialog-content>
    <iframe *ngIf="data.link" [src]="data.link | safeUrl" title="preview"></iframe>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" (click)="download()"> {{ translations.commons.actions.download.label | translate }} </button>
    <button mat-raised-button mat-dialog-close> {{ translations.commons.actions.close.label | translate }} </button>
</mat-dialog-actions>
