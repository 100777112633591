import { NgModule } from '@angular/core';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NgOptimizedImage } from '@angular/common';
import { PipesModule } from '@shared/pipes.module';
import { NotificationItemComponent } from './components/notification-item/notification-item.component';
import { Ng1LinkModule } from '../shared/modules/ng1-link/ng1-link.module';

@NgModule({
    declarations: [NotificationsComponent, NotificationItemComponent],
    imports: [NgOptimizedImage, PipesModule, Ng1LinkModule],
    exports: [NotificationsComponent],
})
export class NotificationsModule {}
