import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs';
import { DirtyPopupCloseComponent } from '../components/dirty-popup-close/dirty-popup-close.component';
import { Translations } from '@translation-keys';
import { PopupComponent } from '../components/popup/popup.component';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class PopupWrapperService {
    private translations = Translations;
    private outletName?: string;
    private title = this.translations.core.modules.popup.commons.labels.pendingChanges;
    private message = this.translations.core.modules.popup.commons.labels.unsavedChangesMessage;
    private description = this.translations.core.modules.popup.commons.labels.unsavedChangesDescription;
    private hasUnsavedChanges?: () => boolean;

    constructor(protected dialog: MatDialog, protected router: Router) {}

    public setHasUnsavedChangesFunction(func: () => boolean) {
        this.hasUnsavedChanges = func;
    }

    public setTitle(title: string) {
        this.title = title;
    }

    public setMessage(message: string) {
        this.message = message;
    }

    public setDescription(description: string) {
        this.description = description;
    }

    public confirmCloseIfUnsavedChanges() {
        if (!this.isLastPopup) return;
        const currentDialog = this.lastPopup;
        if (this.hasUnsavedChanges && this.hasUnsavedChanges()) {
            const { title, message, description } = this;
            this.dialog
                .open(DirtyPopupCloseComponent, { data: { title, message, description }, width: '550px' })
                .afterClosed()
                .pipe(take(1))
                .subscribe(status => {
                    if (status) {
                        currentDialog.close();
                    }
                });
        } else {
            currentDialog.close();
        }
    }

    private get lastPopup() {
        const openDialogs = this.dialog.openDialogs;
        return [...openDialogs][openDialogs.length - 1];
    }

    private get isLastPopup(): boolean {
        const lastPopupOutletName = (this.lastPopup.componentInstance as PopupComponent).outletName;
        return this.outletName === lastPopupOutletName;
    }

    /* outlets */
    public setOutlet(outletName: string) {
        this.outletName = outletName;
    }

    public getOutletName() {
        return this.outletName;
    }

    public get route() {
        let outletRoot = this.router.routerState.snapshot.root.children.find(
            routeOutlets => routeOutlets.outlet === this.outletName,
        );
        if (!outletRoot) return null;

        const fullUrl: Array<string> = [];
        while (outletRoot.children.length > 0) {
            fullUrl.push(outletRoot.routeConfig!.path!);
            outletRoot = outletRoot.children[0];
        }

        return outletRoot ? { ...outletRoot, fullUrl: fullUrl.join('/') } : null;
    }
}
