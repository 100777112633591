import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PageScrollService {
    private scrolling = new Subject<void>();
    public scrolling$ = this.scrolling.asObservable();

    onScroll() {
        this.scrolling.next();
    }
}
