<div class="responsive-navigation-footer">

    @if (newButton) {
        <button type="button" color="primary" mat-fab class="tagus new" (click)="new()">
            <i class="fi fi-rr-plus"></i>
        </button>
    }

    <nav>

        @if (!onlyOnceModule) {
            <a [routerLink]="['/home']" class="item">
                <i [class]="isHome() ? 'fi-sr-home' : 'fi-rr-home'"></i>
                <div class="name"> {{ 'Home' | translate }} </div>
            </a>
        }

        @if (currentMenuModule) {
            @for (menuItem of currentMenuModule.children; track menuItem.label) {
                <a [routerLink]="[menuItem.url]" class="item">
                    <i [class]="getIcon(menuItem)"></i>
                    <div class="name"> {{ menuItem.label | translate }} </div>
                </a>
            }
        }

        @if (modules.length > 0) {
            @for (module of modules; track module.label) {
                <a [routerLink]="[module.url + '/dashboard']" class="item">
                    <i [class]="getIcon(module)"></i>
                    <div class="name"> {{ module.label | translate }} </div>
                </a>
            }
        }
    </nav>

</div>

