@if (isReady) {
    <mat-drawer-container class="right-container" [hasBackdrop]="true">
        <mat-drawer #rightDrawer mode="over"  [position]="'end'" (closedStart)="rightDrawerTemplate = null">
            @if (rightDrawerTemplate) {
                <ng-container *ngTemplateOutlet="rightDrawerTemplate"></ng-container>
            }
        </mat-drawer>

        <mat-drawer-content (scroll)="onScroll()">

            <mat-drawer-container class="example-container" [hasBackdrop]="true">
                <mat-drawer #leftDrawer mode="over">
                    @if (drawerTemplate) {
                        <ng-container *ngTemplateOutlet="drawerTemplate"></ng-container>
                    }
                </mat-drawer>
                <mat-drawer-content>
                    <div class="page-container">
                        <!-- set tags css when user is logged  -->
                        @if (!isBlankPage()) {
                            <app-responsive-navigation-modules />
                            <app-tags />
                        }

                        @if (currentRelativeUrl.startsWith('/sd1') && isLegacyAngularReady.value) {
                            <div class="sd1-route sd2 ">
                                <div
                                    [ngClass]="{'blank-page': isBlankPage() }"
                                    [class.dark-theme]="themeService.isDark()"
                                    [class.card-borderd-theme]="themeService.isCardBorder()"
                                    [class.card-border-radius]="themeService.isCardBorderRadius()"
                                >
                                    @if (!isBlankPage() && !isPopup) {
                                        <app-aside-menu />
                                    }
                                    <div
                                        class="main-content d-flex flex-column"
                                        [ngClass]="{'active': isToggled}"
                                        [class.hide-sidebar]="themeService.isHideSidebar()"
                                    >
                                        @if ((!isPopup && isHome() && isSimplified) || (!isSimplified && !isPopup)) {
                                            <app-header />
                                            <app-g-search-panel />
                                        }

                                        @if (isSimplified && !isBlankPage() && !isHome()) {
                                            <app-responsive-navigation-header />
                                        }

                                        @if (isSimplified && !isBlankPage()) {
                                            <app-responsive-navigation-footer />
                                        }
                                    </div>

                                </div>
                            </div>
                        }

                        @if (!currentRelativeUrl.startsWith('/sd1')) {
                            <div class="sd2">
                                <div
                                    [ngClass]="{'blank-page': isBlankPage() }"
                                    [class.dark-theme]="themeService.isDark()"
                                    [class.card-borderd-theme]="themeService.isCardBorder()"
                                    [class.card-border-radius]="themeService.isCardBorderRadius()"
                                >
                                    @if (!isBlankPage() && (isBlankPageSubject | async) === false) {
                                        <app-aside-menu />
                                    }

                                    <div
                                        class="main-content d-flex flex-column"
                                        [ngClass]="{'active': isToggled}"
                                        [class.hide-sidebar]="themeService.isHideSidebar()"
                                    >
                                        @if ((!isSimplified || isHome()) && !isBlankPage() && !isPopup  && (isBlankPageSubject | async) === false) {
                                            <app-header></app-header>
                                        }

                                        @if (isSimplified && !isBlankPage() && !isHome() && !isPopup && (isBlankPageSubject | async) === false) {
                                            <app-responsive-navigation-header />
                                        }

                                        @if (!isBlankPage() && (isBlankPageSubject | async) === false) {
                                            <app-g-search-panel />
                                        }

                                        <div class="router-outlet" [ngClass]="{ home: isHome(), 'a-router-outlet': !isBlankPage(), 'is-sd-module-router-outlet': isSdModule }">
                                            <router-outlet></router-outlet>
                                            @if (!isBlankPage() && (isBlankPageSubject | async) === false) {
                                                <div class="footer"></div>
                                            }
                                        </div>
                                        @if (isSimplified && !isBlankPage()) {
                                            <app-responsive-navigation-footer />
                                        }
                                        <div class="flex-grow-1"></div>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                </mat-drawer-content>
            </mat-drawer-container>

        </mat-drawer-content>

    </mat-drawer-container>
}
