<div *ngIf="details" #content>
    <p>
        <span class="no-scroll" (click)="allowScroll = true" *ngIf="!allowScroll" aria-hidden="true"></span>
        <iframe
            [src]="details.htmlBodyPublicLink.url | safeUrl"
            width="100%"
            height="300"
            frameborder="0"
        ></iframe>
    </p>
</div>
