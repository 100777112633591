import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Data, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../components/popup/popup.component';
import { Overlay } from '@angular/cdk/overlay';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { UpgradeModule } from '@angular/upgrade/static';

@Injectable({
    providedIn: 'root',
})
export class PopupService {
    started = false;
    beforeCloseDialog: Subject<void> = new Subject<void>();
    beforeCloseDialogReloadOnlyData: Subject<string> = new Subject<string>();
    private listenerSubscription?: Subscription;
    private openedPopupOutlets = new BehaviorSubject<Array<string>>([]);

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public dialog: MatDialog,
        private overlay: Overlay,
        public upgrade: UpgradeModule,
    ) {}

    listen() {
        let lastUrl = '';
        this.listenerSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                const paramsAsArray: Array<string> = Object.values(this.route.snapshot.queryParams);
                if (paramsAsArray.some(param => param.includes('backState:'))) {
                    let backState = '';
                    const backParams: { [key: string]: unknown } = {};
                    paramsAsArray.forEach(param => {
                        if (param.includes('backState:')) {
                            backState = param.split('backState:')[1];
                        }
                        if (param.includes('backStateParams:')) {
                            const backStateParamsString = param.split('backStateParams:')[1];
                            backStateParamsString.split(',').forEach(z => {
                                const aux = z.split(':');
                                backParams[aux[0]] = aux[1] === 'null' ? null : aux[1];
                            });
                        }
                    });
                    const $state = this.upgrade.$injector?.get('$state');
                    const url = $state.href(backState, backParams);
                    if (lastUrl !== url) {
                        lastUrl = url;
                        window.location.href = url;
                    }
                }
            }

            if (event instanceof NavigationEnd) {
                this.handlePopupsForOutlets();
            }
        });
    }

    stop() {
        this.listenerSubscription?.unsubscribe();
        this.started = false;
    }

    handlePopupsForOutlets() {
        const tree = this.router.routerState.snapshot;
        const outlets = tree.root.children.map(child => child.outlet);
        const popupOutletNames = ['p1', 'p2', 'p3', 'p4', 'p5', 'p6', 'p7', 'p8', 'p9', 'p10'];
        popupOutletNames.forEach(popupOutletName => {
            const hasDialogForThisOutletAlready = this.dialog.openDialogs.some(
                dialog =>
                    dialog.componentInstance instanceof PopupComponent &&
                    dialog.componentInstance.outletName === popupOutletName,
            );

            if (outlets.includes(popupOutletName)) {
                if (!hasDialogForThisOutletAlready) {
                    const routeOutletData = this.getCurrentRouteData(tree.root, popupOutletName);
                    const openedPopupOutlets = this.openedPopupOutlets.value;
                    if (!openedPopupOutlets.includes(popupOutletName)) {
                        this.openedPopupOutlets.next([...openedPopupOutlets, popupOutletName]);
                        this.openPopup(popupOutletName, routeOutletData);
                    }
                }
            } else {
                this.closePopupsByOutletName(popupOutletName);
            }
        });
    }

    private openPopup(popupOutletName: string, routeOutletData: Data | null) {
        document.body.style.overflow = 'hidden';
        const dialogRef = this.dialog.open(PopupComponent, {
            minWidth: '100dvw',
            minHeight: '100dvh',
            scrollStrategy: this.overlay.scrollStrategies.noop(),
            disableClose: true,
            data: {
                outletName: popupOutletName,
            },
        });
        dialogRef.beforeClosed().subscribe(() => {
            document.body.style.overflow = 'auto';
            this.openedPopupOutlets.next(this.openedPopupOutlets.value.filter(z => z !== popupOutletName));
            if (routeOutletData && routeOutletData['reloadOnlyDataOnClose']) {
                this.beforeCloseDialogReloadOnlyData.next(popupOutletName);
            } else {
                this.beforeCloseDialog.next();
            }

            const currentUrlTree = this.router.parseUrl(this.router.url);
            if (currentUrlTree.root.children[popupOutletName]) {
                delete currentUrlTree.root.children[popupOutletName];
                currentUrlTree.queryParams = {};
                this.router.navigateByUrl(currentUrlTree).then();
            }
        });
    }

    private closePopupsByOutletName(popupOutletName: string) {
        this.dialog.openDialogs
            .filter(
                dialog =>
                    dialog.componentInstance instanceof PopupComponent &&
                    dialog.componentInstance.outletName === popupOutletName,
            )
            .forEach(dialog => {
                dialog.close();
                this.openedPopupOutlets.next(this.openedPopupOutlets.value.filter(z => z !== popupOutletName));
            });
    }

    private getCurrentRouteData(routeSnapshot: ActivatedRouteSnapshot, outlet: string) {
        let outletRoot = routeSnapshot.children.find(routeOutlets => routeOutlets.outlet === outlet);
        if (!outletRoot) return null;

        while (outletRoot.children.length > 0) {
            outletRoot = outletRoot.children[0];
        }
        return outletRoot.data ?? null;
    }
}
