import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable({
    providedIn: 'root',
})
export class CustomDateAdapter extends NativeDateAdapter {
    override parse(value: any): Date | null {
        if (typeof value === 'string' && value.includes('.')) {
            const [day, month, year] = value.split('.').map(Number);
            return new Date(year, month - 1, day);
        }
        return super.parse(value);
    }

    override format(date: Date, format: string): string {
        if (format === 'MMM YYYY') {
            const options: Intl.DateTimeFormatOptions = { month: 'short', year: 'numeric' };
            return new Intl.DateTimeFormat('en-US', options).format(date);
        }

        if (date) {
            const day = this._to2digit(date.getDate());
            const month = this._to2digit(date.getMonth() + 1);
            const year = date.getFullYear();
            return `${day}.${month}.${year}`;
        }
        return '';
    }

    private _to2digit(n: number) {
        return ('00' + n).slice(-2);
    }
}
