import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { TimelineDetailsComponent } from '../components/timeline-details/timeline-details.component';
import {
    TimelineDataLakeEmailsCountMessagesResult,
    TimelineDataLakeEmailsDatesWithDataResult,
    TimelineDataLakeEmailsEmailAddressesResult,
    TimelineDataLakeEmailsMessagesResult,
} from '../types/data-lake-emails-timeline.types';
import {
    TimelineItemProvider,
    TimelineItemProviderData,
    TimelineItemProviderRegistryService,
} from '../../contacts/modules/details/modules/timeline/services/timeline-item-provider-registry.service';
import {
    TimelineColorsEnum,
    TimelineDirectionEnum,
    TimelineItem,
} from '../../core/modules/shared/modules/sd-timeline/types';
import { DateTime } from 'luxon';
import { TimelinetExtensionTitleComponent } from '../components/timelinet-extension-title/timelinet-extension-title.component';

@Injectable({
    providedIn: 'root',
})
export class TimelineItemProviderService extends TimelineItemProvider {
    timelineDetailsComponent = TimelineDetailsComponent;
    timelineTitleExtensionComponent = TimelinetExtensionTitleComponent;

    emailAddressesReference: Subscription | null = null;
    countMessagesReference: null | Subscription = null;
    contactEmails: string[] = [];

    constructor(public contactTimelineService: TimelineItemProviderRegistryService) {
        super('dataLakeEmail', TimelineColorsEnum.purple);
    }

    load() {
        this.subscribeCount = this.subscribeCount.bind(this);
        this.subscribeDatesWithData = this.subscribeDatesWithData.bind(this);
        this.subscribeData = this.subscribeData.bind(this);
        this.destroySubscriptions = this.destroySubscriptions.bind(this);
        this.contactTimelineService.register(this);
    }

    subscribeCount(
        contactId: number,
        utcStartDate: Date,
        utcEndDate: Date,
        timelineItemProviderData: TimelineItemProviderData,
        apollo: Apollo,
    ): void {
        this.destroySubscriptions();
        const emailAddressesSubscription = this.getEmailAddresses(contactId, apollo).subscribe(({ data }) => {
            const contactEmails = data.results[0]?.emails.map(emailObject => emailObject.email) ?? [];

            const getCountMessagesSubscription = this.getCountMessages(
                contactEmails,
                utcStartDate,
                utcEndDate,
                apollo,
            ).subscribe(countData => {
                const count = countData.data.results?.aggregate?.count ?? 0;

                timelineItemProviderData.currentCount.next(count);
            });
            timelineItemProviderData.countSubscriptions.push(getCountMessagesSubscription);
        });
        timelineItemProviderData.countSubscriptions.push(emailAddressesSubscription);
    }

    subscribeDatesWithData(contactId: number, timelineItemProviderData: TimelineItemProviderData, apollo: Apollo) {
        const emailAddressesSubscription = this.getEmailAddresses(contactId, apollo).subscribe(data => {
            const contactEmails = data.data.results[0]?.emails.map(emailObject => emailObject.email) ?? [];

            const getDates = `
            query GetDates($contactEmails: [String!]){
              results: DLE_DateParticipantMatView(where: {email: {_in: $contactEmails}}, distinctOn: date) {
                date
              }
            }`;

            const datesSubscription = apollo
                .watchQuery<TimelineDataLakeEmailsDatesWithDataResult>({
                    query: gql(getDates),
                    variables: {
                        contactEmails,
                    },
                })
                .valueChanges.subscribe(dates => {
                    timelineItemProviderData.currentDatesWithData.next(
                        dates.data.results.map(result => new Date(result.date)),
                    );
                });

            timelineItemProviderData.datesWithDataSubscriptions.push(datesSubscription);
        });
        timelineItemProviderData.datesWithDataSubscriptions.push(emailAddressesSubscription);
    }

    private getEmailAddresses(contactId: number, apollo: Apollo) {
        const getEmailAddresses = `
            query GetEmailAddresses($contactId: bigint!) {
                results: CNT_Contact(where: {id: {_eq: $contactId}}) {
                    id
                    emails(distinctOn: email) {
                        email
                    }
                }
            }`;

        const variables = {
            contactId,
        };

        return apollo.watchQuery<TimelineDataLakeEmailsEmailAddressesResult>({
            query: gql(getEmailAddresses),
            variables,
        }).valueChanges;
    }

    private getCountMessages(contactEmails: string[], utcStartDate: Date, utcEndDate: Date, apollo: Apollo) {
        const getCountMessages = `
            query GetCountMessages($contactEmails: [String!], $utcStartDate: timestamptz!, $utcEndDate: timestamptz!) {
              results: DLE_MessageAggregate(where: {participants: {email: {_in: $contactEmails}}, date: {_gte: $utcStartDate, _lte: $utcEndDate}}) {
                aggregate {
                  count
                }
              }
            }`;

        const variables = {
            contactEmails,
            utcStartDate: DateTime.fromJSDate(utcStartDate, { zone: 'utc' }).toFormat('yyyy-MM-dd HH:mm'),
            utcEndDate: DateTime.fromJSDate(utcEndDate, { zone: 'utc' }).toFormat('yyyy-MM-dd HH:mm'),
        };

        return apollo.watchQuery<TimelineDataLakeEmailsCountMessagesResult>({
            query: gql(getCountMessages),
            variables,
        }).valueChanges;
    }

    subscribeData(
        contactId: number,
        utcStartDate: Date,
        utcEndDate: Date,
        timelineItemProviderData: TimelineItemProviderData,
        sort: 'asc' | 'desc',
        apollo: Apollo,
    ) {
        const emailAddressesSubscription = this.getEmailAddresses(contactId, apollo).subscribe(({ data }) => {
            const contactEmails = data.results[0]?.emails.map(emailObject => emailObject.email) ?? [];
            const getTimelineItems = `
            query GetTimelineItems($contactEmails: [String!], $utcStartDate: timestamptz!, $utcEndDate: timestamptz!, $sort: OrderBy!) {
                results: DLE_Message(where: {participants: {email: {_in: $contactEmails}}, date: {_gte: $utcStartDate, _lte: $utcEndDate}}, orderBy: {date: $sort}) {
                    id
                    date
                    subject
                    attachmentsAggregate {
                      aggregate {
                        count
                      }
                    }
                    sender: participants(where: {isFrom: {_eq: true}}) {
                        email
                    }
                }
            }`;

            this.contactEmails = contactEmails;
            const getTimelineItemsSubscription = apollo
                .watchQuery<TimelineDataLakeEmailsMessagesResult>({
                    query: gql(getTimelineItems),
                    variables: {
                        contactEmails: contactEmails,
                        utcStartDate: DateTime.fromJSDate(utcStartDate, { zone: 'utc' }).toFormat('yyyy-MM-dd HH:mm'),
                        utcEndDate: DateTime.fromJSDate(utcEndDate, { zone: 'utc' }).toFormat('yyyy-MM-dd HH:mm'),
                        sort: sort.toUpperCase(),
                    },
                })
                .valueChanges.subscribe(data => {
                    const results = data.data.results.map(result => ({
                        ...result,
                    }));
                    timelineItemProviderData.currentData.next(results);
                });

            timelineItemProviderData.dataSubscriptions.push(getTimelineItemsSubscription);
        });
        timelineItemProviderData.dataSubscriptions.push(emailAddressesSubscription);
    }

    dataMapper(item): TimelineItem {
        return {
            utcDate: new Date(item.date),
            title: item.subject,
            color: this.color,
            direction: this.contactEmails.includes(item.sender[0]?.email)
                ? TimelineDirectionEnum.left
                : TimelineDirectionEnum.right,
            icon: '',
            detailComponent: this.timelineDetailsComponent,
            titleExtensionComponent: this.timelineTitleExtensionComponent,
            originalItem: item,
            subTitle: item.sender[0].email,
        };
    }

    destroySubscriptions() {
        if (this.emailAddressesReference) {
            this.emailAddressesReference.unsubscribe();
        }

        if (this.countMessagesReference) {
            this.countMessagesReference.unsubscribe();
        }
    }
}
