import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ResponsiveNavigationSwipeService {
    private touchStartPageX = 0;
    private touchStartX = 0;
    private touchEndX = 0;
    private touchStartY = 0;
    private touchEndY = 0;

    private swipeLeftSubject = new Subject<void>();
    private swipeRightSubject = new Subject<void>();
    private swipeUpSubject = new Subject<void>();
    private swipeDownSubject = new Subject<void>();

    swipeLeft$ = this.swipeLeftSubject.asObservable();
    swipeRight$ = this.swipeRightSubject.asObservable();
    swipeUp$ = this.swipeUpSubject.asObservable();
    swipeDown$ = this.swipeDownSubject.asObservable();

    constructor(private ngZone: NgZone) {
        this.listenForSwipeEvents();
    }

    private listenForSwipeEvents() {
        document.addEventListener('touchstart', this.onTouchStart.bind(this), { passive: false });
        document.addEventListener('touchmove', this.onTouchMove.bind(this), { passive: false });
        document.addEventListener('touchend', this.onTouchEnd.bind(this), { passive: false });
    }

    private onTouchStart(event: TouchEvent) {
        this.touchStartPageX = event.changedTouches[0].pageX;
        this.touchStartX = event.changedTouches[0].screenX;
        this.touchStartY = event.changedTouches[0].screenY;
    }

    private onTouchMove(event: TouchEvent) {
        if (this.touchStartPageX <= 40) {
            event.preventDefault();
        }
    }

    private onTouchEnd(event: TouchEvent) {
        this.touchEndX = event.changedTouches[0].screenX;
        this.touchEndY = event.changedTouches[0].screenY;
        this.handleSwipeGesture(event);
    }

    private handleSwipeGesture(event: TouchEvent) {
        const deltaX = this.touchEndX - this.touchStartX;
        const deltaY = this.touchEndY - this.touchStartY;
        const absDeltaX = Math.abs(deltaX);
        const absDeltaY = Math.abs(deltaY);

        if (deltaX > 30 || deltaY < -30) {
            event.preventDefault();
        }

        if (absDeltaX > absDeltaY) {
            if (absDeltaX > 30) {
                if (deltaX > 0) {
                    this.ngZone.run(() => this.swipeRightSubject.next());
                } else {
                    this.ngZone.run(() => this.swipeLeftSubject.next());
                }
            }
        } else {
            if (absDeltaY > 30) {
                if (deltaY > 0) {
                    this.ngZone.run(() => this.swipeDownSubject.next());
                } else {
                    this.ngZone.run(() => this.swipeUpSubject.next());
                }
            }
        }
    }
}
