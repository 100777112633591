@if (state === availableStates.READY_WITH_RESULTS) {
    <app-data-search-result-header
        [title]="'Menu'"
        [isLoading]="false"
    />

    @for (path of paths; track path) {
        <div class="path"> {{ path }} </div>
        <div class="menu-items">
            @for (item of routesByPaths[path]; track item.label) {
                <app-menu-item [menu]="item" />
            }
        </div>

    }
}
