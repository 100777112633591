@if (state === availableStates.LOADING) {
    <div class="loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
}

@if (state === availableStates.ERROR) {
    <div class="error">
        {{ translations.commons.labels.error | translate }}
    </div>
}

@if (state === availableStates.READY_WITHOUT_RESULTS || (upstreamNoDataFoundIds.length === upstreamList.length && upstreamNoDataFoundIds.length > 0 && menuCount === 0)) {
    <div class="no-results">
        {{ translations.commons.labels.noDataFound | translate }}
    </div>
}

@if (state === availableStates.READY_WITH_RESULTS) {
    @if (upstreamList.length === loadingUpstreams.length) {
        @if (loadingUpstreams.length > 0) {
            <div class="searching">
                <mat-spinner [diameter]="15"></mat-spinner>
                searching:
                @for (upstream of loadingUpstreams; track upstream.id; let last = $last) {
                    {{ upstream.name| translate }}@if (!last) {,}
                }
            </div>
        }
    }

    <div class="upstreams">
        <div class="group" [ngClass]="{ 'has-items': menuCount > 0 }">
            <app-data-search-menu [searchTerms]="search" (qtdMenuFoundedChange)="onQtdMenuFoundedChange($event)" />
        </div>

        @for (upstream of upstreamList; track upstream.id) {
            <div class="group">
                <app-data-search-upstream
                    [upstreamName]="upstream.name"
                    [searchTerms]="getSearchTerms(upstream.id)"
                    (stateChanges)="upstreamStateChange(upstream.id, $event)"
                />
            </div>
        }
    </div>
}

