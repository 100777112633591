import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ComponentType } from '@angular/cdk/portal';
import { TimelineColorsEnum, TimelineItem } from '../../../../../../core/modules/shared/modules/sd-timeline/types';

export abstract class TimelineItemProvider {
    abstract timelineDetailsComponent: ComponentType<unknown>;

    protected constructor(public id: string = 'unknown', public color: TimelineColorsEnum = TimelineColorsEnum.blue) {}

    abstract subscribeDatesWithData(
        contactId: number,
        timelineItemProviderData: TimelineItemProviderData,
        apollo: Apollo,
    ): void;

    abstract subscribeCount(
        contactId: number,
        utcStartDate: Date,
        utcEndDate: Date,
        timelineItemProviderData: TimelineItemProviderData,
        apollo: Apollo,
    ): void;

    abstract subscribeData(
        contactId: number,
        utcStartDate: Date,
        utcEndDate: Date,
        timelineItemProviderData: TimelineItemProviderData,
        sort: 'asc' | 'desc',
        apollo: Apollo,
    ): void;

    abstract dataMapper(data: unknown): TimelineItem;

    abstract destroySubscriptions(): void;
}

export interface TimelineItemProviderData {
    currentDatesWithData: BehaviorSubject<Date[]>;
    currentCount: BehaviorSubject<number>;
    currentData: BehaviorSubject<unknown>;
    datesWithDataSubscriptions: Subscription[];
    countSubscriptions: Subscription[];
    dataSubscriptions: Subscription[];
}

@Injectable({
    providedIn: 'root',
})
export class TimelineItemProviderRegistryService {
    private providers: TimelineItemProvider[] = [];

    constructor(protected apollo: Apollo) {}

    register(provider: TimelineItemProvider): void {
        this.providers.push(provider);
    }

    getProviders() {
        return this.providers;
    }
}
