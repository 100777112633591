import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@shared/pipes.module';
import { AngularMaterialModule } from '@shared/angular-material.module';
import { SdSelectModule } from '../sd-select/sd-select.module';
import { SdInputWithOptionsComponent } from './component/sd-input-with-options/sd-input-with-options.component';

@NgModule({
    declarations: [SdInputWithOptionsComponent],
    imports: [FormsModule, ReactiveFormsModule, CommonModule, PipesModule, AngularMaterialModule, SdSelectModule],
    exports: [SdInputWithOptionsComponent],
})
export class SdInputWithOptionsModule {}
