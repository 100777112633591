@if (!usingFilterTemplate) {
    <section class="header">
        <div>
            <i class="fi fi-sr-cross-small" (click)="close()" (keydown)="close()" aria-hidden="true"></i>
            Filter by
        </div>
        <div
            aria-hidden="true"
            class="clear-filters"
            (click)="clearFilters()"
            (keydown)="clearFilters()"
            matRipple
            [matRippleCentered]="true"
            [matRippleRadius]="50"
            [matRippleUnbounded]="true"
        >
            Clear filters
        </div>
    </section>
} @else {
    <section class="header">
        <div>
            <i class="fi fi-rr-arrow-small-left" (click)="back()" (keydown)="back()" aria-hidden="true"></i>
            {{ filterTitle | translate }}
        </div>
    </section>
}

<section class="filters">
    <ng-container *ngTemplateOutlet="template"></ng-container>
</section>


@if (!usingFilterTemplate) {
    <section class="footer">
        <button class="apply" color="primary" type="button" mat-flat-button (click)="apply()">
            <div>
                {{ translations.commons.actions.apply.label | translate }}
            </div>
        </button>
    </section>
}
