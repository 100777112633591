<mat-form-field appearance="fill" [class]="displayOnlyIcon ? 'only-icon' : ''">
    <mat-label>
        <span *ngIf="!isWaitingForOptions"> {{ label }} </span>
        <mat-spinner *ngIf="isWaitingForOptions" [diameter]="25"></mat-spinner>
    </mat-label>
    <mat-select
        #matSelect
        [multiple]="multiple"
        [(ngModel)]="selected"
        (blur)="onTouched()"
        [disabled]="disable"
        (openedChange)="onOpenedChange($event)"
        panelClass="sd-select-expand-panel {{ hasManyOptionsCss ? 'has-many-options' : '' }}"
    >
        <mat-form-field class="search" style="width: 100%">
            <mat-label> {{ translations.commons.labels.search | translate }} </mat-label>
            <input
                #search
                [formControl]="filterControl"
                (keydown)="$event.stopPropagation()"
                autocomplete="off"
                aria-label="Search"
                matInput />
        </mat-form-field>

        <div class="check-all" [hidden]="filterControl.value.length > 0 || multiple === false">
            <mat-checkbox class="mat-option"
                color="primary"
                [checked]="isChecked()"
                [indeterminate]="isIndeterminate()"
                (click)="$event.stopPropagation()"
                (change)="toggleSelection($event)">
                {{ translations.commons.labels.selectAll | translate }}
            </mat-checkbox>
        </div>

        <div *ngFor="let group of getGroups()">

            <div *ngIf="group === 'no-group'; then noGroupOptions else groupOptions;"></div>
            <ng-template #noGroupOptions>
                <mat-option
                    *ngFor="let option of filteredOptionsByGroup[group] | async"
                    [value]="option.id"
                    [matTooltip]="option.label"
                    [matTooltipShowDelay]="250"
                    [matTooltipHideDelay]="50">
                    <img *ngIf="option.imageUrl" [src]="option.imageUrl" height="34px" [alt]="option.label">
                    {{ option.label }}
                </mat-option>
            </ng-template>
    
            <ng-template #groupOptions>
                <mat-optgroup [label]="group" *ngIf="countOptionsInGroup(group) > 0">
                    <mat-option
                        *ngFor="let option of filteredOptionsByGroup[group] | async"
                        [value]="option.id"
                        [matTooltip]="option.label"
                        [matTooltipShowDelay]="250"
                        [matTooltipHideDelay]="50">
                        {{ option.label }}
                    </mat-option>
                </mat-optgroup>
            </ng-template>
        </div>

        <mat-select-trigger *ngIf="multiple">
            <span>
                {{ translations.commons.labels.selected | translate }}: {{ getSelectedCount() }}
            </span>
        </mat-select-trigger> 
       
    </mat-select>
    <mat-icon matSuffix> {{ icon }} </mat-icon>
    <mat-icon *ngIf="hasReload" matSuffix class="refresh-icon" (click)="reloadOptions($event)">refresh</mat-icon>
</mat-form-field>
