<div class="notifications-container">
    <div class="notifications-header">
        <p>{{ translations.core.modules.notifications.title | translate }}</p>
<!--        mark as read button not implemented-->
<!--        @if(notifications.length > 0){-->
<!--            <div>-->
<!--                <button><i class="fi-br-check-double"></i>{{  translations.core.modules.notifications.commons.actions.markAsRead.label | translate }}</button>-->
<!--            </div>-->
<!--        }-->
    </div>

    @if(notifications.length > 0) {
        <div class="notifications-body">
            @for(notification of notifications; track notification){
                <app-notification-item [notification]="notification"></app-notification-item>
<!--                <div class="divider"></div>-->
            }
        </div>
    } @else {
        <div class="empty-notifications-body">
            <img ngSrc="/assets/images/notifications/empty-notifications-bell.svg" alt="Empty notifications bell" height="102" width="115">
            <p class="nothing-here">{{  translations.commons.labels.nothingHere | translate }}</p>
            <p class="message">{{ translations.core.modules.notifications.commons.labels.emptyNotificationsMessage  | translate }}</p>
        </div>
<!--        <div class="divider"></div>-->
    }

<!--    view all notifications button not implemented-->
<!--    <div class="notifications-footer">-->
<!--        <button>{{  translations.core.modules.notifications.commons.actions.viewAllNotifications.label | translate }}</button>-->
<!--    </div>-->
</div>