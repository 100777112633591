import { ComponentType } from '@angular/cdk/portal';

export enum TimelineColorsEnum {
    'purple' = 'timeline__event--type1',
    'blue' = 'timeline__event--type2',
    'green' = 'timeline__event--type3',
}

export enum TimelineDirectionEnum {
    'right' = 'right',
    'left' = 'left',
}

export interface ITimelinetExtensionTitleComponent {
    data: unknown;
}

export interface TimelineItem {
    utcDate: Date;
    color: TimelineColorsEnum;
    icon: string;
    title: string;
    subTitle?: string;
    direction: TimelineDirectionEnum;
    description?: string;
    detailComponent: ComponentType<unknown>;
    titleExtensionComponent?: ComponentType<ITimelinetExtensionTitleComponent>;
    originalItem: unknown;
}
