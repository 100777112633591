import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectedRowsComponent } from './components/selected-rows/selected-rows.component';
import { SdInputWithOptionsModule } from '../sd-input-with-options/sd-input-with-options.module';
import { SdTableComponent } from './components/sd-table/sd-table.component';
import { ColumnComponent } from './components/column/column.component';
import { CustomActionsComponent } from './components/custom-actions/custom-actions.component';
import { CustomFiltersComponent } from './components/custom-filters/custom-filters.component';
import { AngularMaterialModule } from '@shared/angular-material.module';
import { SdSelectModule } from '../sd-select/sd-select.module';
import { PipesModule } from '@shared/pipes.module';

@NgModule({
    declarations: [
        SdTableComponent,
        ColumnComponent,
        CustomActionsComponent,
        CustomFiltersComponent,
        SelectedRowsComponent,
    ],
    imports: [
        CommonModule,
        AngularMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        SdSelectModule,
        PipesModule,
        SdInputWithOptionsModule,
    ],
    exports: [SdTableComponent],
})
export class SdTableModule {}
