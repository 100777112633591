import { Injectable } from '@angular/core';
import Echo from 'laravel-echo';
import { Websocket } from '@auth/services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class WebsocketService {
    createConnection(websocket: Websocket) {
        if (window['Echo']) {
            window['Echo'].stop();
        }

        window['Pusher'] = require('pusher-js');

        window['Echo'] = new Echo({
            broadcaster: 'pusher',
            key: websocket.public_key,
            cluster: websocket.cluster,
            wsHost: websocket.host,
            wsPort: websocket.port,
            wssPort: websocket.port,
            disableStats: true,
            auth: {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    'X-Spa-Session-Id': window['spaSessionId'],
                },
            },
        });
    }
}
