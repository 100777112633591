import { NgModule } from '@angular/core';
import { AsideMenuComponent } from './modules/aside-menu/aside-menu.component';
import { MenuComponent } from './modules/menu/menu.component';
import { MenuItemComponent } from './modules/menu-item/menu-item.component';
import { HeaderComponent } from './modules/header/header.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { PopupComponent } from './modules/popup/components/popup/popup.component';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@shared/pipes.module';
import { AngularMaterialModule } from '@shared/angular-material.module';
import { Ng1LinkModule } from '../core/modules/shared/modules/ng1-link/ng1-link.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopupHeaderComponent } from './modules/popup/components/popup-header/popup-header.component';
import { UserSettingsModule } from '../core/modules/user-settings/user-settings.module';
import { NotificationsModule } from '../core/modules/notifications/notifications.module';
import { DirtyPopupCloseComponent } from './modules/popup/components/dirty-popup-close/dirty-popup-close.component';
import { PopupFormActionsComponent } from './modules/popup/components/popup-form-actions/popup-form-actions.component';

@NgModule({
    declarations: [
        MenuComponent,
        AsideMenuComponent,
        MenuItemComponent,
        HeaderComponent,
        PopupComponent,
        PopupHeaderComponent,
        DirtyPopupCloseComponent,
        PopupFormActionsComponent,
    ],
    exports: [
        MenuComponent,
        AsideMenuComponent,
        HeaderComponent,
        MenuItemComponent,
        PopupComponent,
        PopupHeaderComponent,
        PopupFormActionsComponent,
    ],
    imports: [
        CommonModule,
        PipesModule,
        AngularMaterialModule,
        NgScrollbarModule,
        Ng1LinkModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        UserSettingsModule,
        NotificationsModule,
    ],
})
export class LayoutModule {}
