import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdTimelineComponent } from './components/sd-timeline/sd-timeline.component';
import { FormsModule } from '@angular/forms';
import { AngularMaterialModule } from '@shared/angular-material.module';
import { PipesModule } from '@shared/pipes.module';

@NgModule({
    declarations: [SdTimelineComponent],
    imports: [CommonModule, FormsModule, AngularMaterialModule, PipesModule],
    exports: [SdTimelineComponent],
})
export class SdTimelineModule {}
