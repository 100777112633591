import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
} from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ImdtSnackbarData } from '../../types';

@Component({
    selector: 'imdt-snackbar',
    templateUrl: './imdt-snackbar.component.html',
    styleUrl: './imdt-snackbar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImdtSnackbarComponent implements AfterViewInit {
    icons = {
        success: 'fi-sr-comment-check',
        alert: 'fi-sr-comment-xmark',
        warning: 'fi-sr-comment-exclamation',
        info: 'fi-sr-comment',
    };
    snackbarHeight = 0;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA)
        public data: ImdtSnackbarData,
        public elementRef: ElementRef,
        public cdr: ChangeDetectorRef,
    ) {}

    ngAfterViewInit() {
        this.snackbarHeight = this.elementRef.nativeElement.offsetHeight;
        this.cdr.detectChanges();
    }
}
