import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, throwError } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import {
    GSearchDataSearchGetSearchResultResponse,
    GSearchDataSearchUpstreamSearchTerms,
    GSearchGetUpstreamsResponse,
} from '../types';

@Injectable({
    providedIn: 'root',
})
export class GSearchService {
    private isMenuRendering = new BehaviorSubject<boolean>(false);
    private searchValue = new BehaviorSubject<string>('');

    constructor(protected apollo: Apollo) {}

    get isMenuRendering$() {
        return this.isMenuRendering.asObservable();
    }

    get searchValue$() {
        return this.searchValue.asObservable();
    }

    searchChange(search = '') {
        this.isMenuRendering.next(search.trim() !== '');
        this.searchValue.next(search);
    }

    getCurrentSearchValue() {
        return this.searchValue.value;
    }

    close() {
        this.isMenuRendering.next(false);
    }

    getUpstreams() {
        const getUpstreams = `
            query getUpstreams {
                results: GSE_Upstream {
                    id
                    name
                    sortCustomTextInAscendingOrder
                }
            }`;

        return this.apollo
            .watchQuery<GSearchGetUpstreamsResponse>({
                query: gql(getUpstreams),
            })
            .valueChanges.pipe(
                map(result => result.data && result.data.results),
                catchError(error => throwError(() => new Error(error))),
            );
    }

    getSearchResult(searchTerms: GSearchDataSearchUpstreamSearchTerms, limitItems = 10) {
        let sortDirection = 'ASC';

        if (searchTerms.upstream) {
            sortDirection = searchTerms.upstream.sortCustomTextInAscendingOrder ? 'ASC' : 'DESC';
        }

        const getDataSearchGetSearchResult = `
            query GetDataSearchGetSearchResult($searchValue: String!, $upstreamId: bigint, $limitItems: Int) {
                countResult: GSE_SearchEntryAggregate(where: {textPlainContent: {_ilike: $searchValue}, upstream: {id: {_eq: $upstreamId}}}) {
                    aggregate {
                        count
                    }
                }
                results: GSE_SearchEntry(orderBy: [{sortOrder: ASC},{ sortCustomText: ${sortDirection} },{ title: ${sortDirection} }], where: {textPlainContent: {_ilike: $searchValue}, upstream: {id: {_eq: $upstreamId}}}, limit: $limitItems) {
                    title
                    subtitle
                    link
                    upstreamRecordId
                    tagsJson
                }
            }`;

        let searchValue = searchTerms.searchValue.trim();

        // If the string in quotes, try to find exact match
        if (searchValue.startsWith('"') && searchValue.endsWith('"')) {
            searchValue = searchValue.substring(1, searchValue.length - 1);
        } else {
            searchValue = searchValue.replaceAll(' ', '%');
        }

        return this.apollo
            .watchQuery<GSearchDataSearchGetSearchResultResponse>({
                query: gql(getDataSearchGetSearchResult),
                variables: {
                    upstreamId: searchTerms.upstreamId,
                    searchValue: `%${searchValue}%`,
                    limitItems,
                },
            })
            .valueChanges.pipe(
                map(result => result.data),
                catchError(error => throwError(() => new Error(error))),
            );
    }
}
