import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { AuthService, CurrentSession } from '@auth/services/auth.service';
import { Translations } from '@translation-keys';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { UpgradeModule } from '@angular/upgrade/static';
import { AclService } from '@auth/services/acl.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PopupService } from '../../../popup/services/popup.service';
import { PopupWrapperService } from '../../../popup/services/popup-wrapper.service';

@Component({
    selector: 'app-user-aside-menu',
    templateUrl: './user-aside-menu.component.html',
    styleUrl: './user-aside-menu.component.scss',
})
export class UserAsideMenuComponent implements OnInit {
    @Input() displayGroups = ['user', 'tenant', 'switch-workspace', 'sign-out'];
    user: CurrentSession | undefined;
    currentTenant!: number;
    displayTenants = true;

    userTenants: Array<{ id: number; label: string }> = [
        {
            id: 1,
            label: 'ESSR',
        },
    ];

    readonly translations = Translations;

    constructor(
        public authService: AuthService,
        private destroyRef: DestroyRef,
        public router: Router,
        public upgrade: UpgradeModule,
        public acl: AclService,
        public popupWrapperService: PopupWrapperService,
    ) {
        this.currentTenant = 1;
    }

    ngOnInit() {
        this.user = this.authService.currentSessionData;
        this.authService.currentSession.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(session => {
            this.user = session;
        });
    }

    onTenantChange($event: number) {
        console.log('tenant change', $event);
    }

    logout() {
        this.authService.logout().then(() => {
            localStorage.removeItem('documentation_roles');
            localStorage.removeItem('permissions');
            localStorage.removeItem('roles');
            localStorage.removeItem('satellizer_token');
            localStorage.removeItem('token');
            sessionStorage.clear();
            this.router.navigate(['/sign-in']).then();
        });
    }

    getQueryParams() {
        if (window.location.pathname.includes('sd1')) {
            const st = this.upgrade.$injector.get('st');
            const $stateParams = this.upgrade.$injector.get('$stateParams');

            const queryParams = {
                backState: st.current,
                backStateParams: $stateParams,
            };

            const parsedQueryParams: Array<string> = [];
            Object.entries(queryParams).forEach(([key, value]) => {
                if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
                    const nestedParams = Object.entries(value)
                        .filter(([nestedKey]) => nestedKey !== '#')
                        .map(([nestedKey, nestedValue]) => `${nestedKey}:${nestedValue}`)
                        .join(',');
                    parsedQueryParams.push(`${key}:${nestedParams}`);
                } else {
                    parsedQueryParams.push(`${key}:${value}`);
                }
            });
            return parsedQueryParams;
        }
        return null;
    }
}
