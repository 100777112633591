import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SdInputWithOptionsValue } from '../../types';
import { SdSelectOption, SdSelectOptionId } from '../../../sd-select/types';

@Component({
    selector: 'sd-input-with-options',
    templateUrl: './sd-input-with-options.component.html',
    styleUrls: ['./sd-input-with-options.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SdInputWithOptionsComponent),
            multi: true,
        },
    ],
})
export class SdInputWithOptionsComponent implements ControlValueAccessor {
    @Input() label = '';
    @Input() placeholder = '';
    @Input() options: SdSelectOption[] = [];

    value = '';
    selectedOptions: SdSelectOptionId[] = [];

    isDisabled = false;
    isInvalid = false;
    isDirty = false;
    isTouched = false;

    writeValue(obj: null | SdInputWithOptionsValue): void {
        if (obj) {
            this.value = obj.input;
            this.selectedOptions = obj.options;
        }
    }

    onChange($event: string) {
        this.isDirty = true;
        this.isTouched = true;
        this.propagateChange({
            input: $event,
            options: this.selectedOptions,
        });
    }

    onOptionChange($event: SdSelectOptionId[]) {
        this.isDirty = true;
        this.isTouched = true;
        this.propagateChange({
            input: this.value,
            options: $event,
        });
    }

    registerOnChange(fn: (val: SdInputWithOptionsValue) => void): void {
        this.propagateChange = fn;
    }
    propagateChange(_?: SdInputWithOptionsValue): void {
        console.log('propagateChange', _);
    }

    onTouched = () => console.log('onTouched');
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
