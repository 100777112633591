import { Component, OnInit, DestroyRef, Output, EventEmitter } from '@angular/core';
import { Translations } from '@translation-keys';
import { NotificationService } from '../../services/notification.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DropdownNotifications } from '../../types';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
    @Output() notificationsIsEmpty = new EventEmitter<boolean>();
    notifications: DropdownNotifications[] = [];
    translations = Translations;

    constructor(private notificationService: NotificationService, private destroyRef: DestroyRef) {}

    ngOnInit(): void {
        this.listenNotifications();
    }

    private listenNotifications() {
        this.notificationService.dropdownNotifications$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(notifications => {
                this.notifications = notifications;
                this.checkIfNotificationsIsEmpty();
            });
    }

    checkIfNotificationsIsEmpty() {
        this.notificationsIsEmpty.emit(this.notifications.length <= 0);
    }
}
