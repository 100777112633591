import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, copyArrayItem, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { TranslatePipe } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, Observable, Subject } from 'rxjs';
import { SelectedRowsComponent } from '../selected-rows/selected-rows.component';
import { SdInputWithOptionsValue } from '../../../sd-input-with-options/types/sd-input-with-options.types';
import { ComponentType } from '@angular/cdk/portal';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import {
    SdTableAllColumns,
    SdTableBatchActions,
    SdTableClientColumn,
    SdTableColumnDto,
    SdTableColumnStaticEnum,
    SdTableCustomActions,
    SdTableCustomFiltersDto,
    SdTableCustomFiltersValue,
    SdTableCustomParameter,
    SdTableMetadataDto,
    SdTableResponseDto,
    SdTableRowDto,
} from '../../types';
import { SdSelectOption } from '../../../sd-select/types';
import { QueryParam, SdTableService } from '../../services/sd-table.service';
import { SdDialogMessageService } from '../../../sd-dialog-message/services/sd-dialog-message.service';
import { SdExtraInfoService } from '../../../sd-extra-info/services/sd-extra-info.service';
import { CustomFiltersComponent } from '../custom-filters/custom-filters.component';

// Constants
const GENERATE_DEBUG_LOGS = false;

@Component({
    selector: 'sd-table',
    templateUrl: './sd-table.component.html',
    styleUrls: ['./sd-table.component.scss', './sd-table-card.component.scss'],
    providers: [TranslatePipe],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class SdTableComponent implements OnInit, OnChanges {
    @Input() endpoint!: string;
    @Input() responsiveLayout: 'card' | 'table' = 'card';
    @Input() customActions: SdTableCustomActions[] = [];
    @Input() batchActions: SdTableBatchActions[] = [];
    @Input() clientColumns: SdTableClientColumn[] = [];
    @Input() customParameters: SdTableCustomParameter[] = [];
    @Input() selectedRows: number[] = [];
    @Input() expandComponent?: Component;
    @Input() defaultSort?: Sort | string;
    @Input() disableHeaderActions = false;
    @Input() paginate = true;
    @Input() fullHeight = false;
    @Input() titleAnchor = '';
    @Input() identify?: string;
    @Input() extraInfo?: ComponentType<unknown>;
    @Input() extraDataForExtraInfo?: unknown;

    @Input() tableTitle?: string;
    @Output() selectedRowsChange: EventEmitter<number[]> = new EventEmitter<number[]>();

    /* ONLY FOR ANGULAR 1 */
    @Input() forceReload = 0;
    /* */

    effectiveLayout: 'card' | 'table' = 'table';

    selectColumnsOptions: SdSelectOption[] = [];
    selectFilterColumnsOptions: SdSelectOption[] = [];
    allColumns: string[] = [];
    dataLength = 0;
    data: SdTableRowDto[] = [];
    parameters: {
        filter: string;
        sort?: Sort;
        pagination?: { page: number; pageSize: number };
    } = {
        filter: '',
    };

    isLoadingResults = false;
    typedColumns: { [name: string]: SdTableColumnDto }[] = [];
    customFilters: SdTableCustomFiltersDto[] = [];

    selection = new SelectionModel<SdTableRowDto>(true, []);
    snackBarRef?: MatSnackBarRef<unknown>;

    visibleColumns: string[] = [];
    visibleColumnLabel: { [key: string]: string } = {};
    visibleColumnsSelected: string[] = [];
    filterColumns: string[] = [];
    allFilterColumns: string[] = [];
    searchSubject = new Subject<string>();

    inputWithOptionsValue: SdInputWithOptionsValue = { input: '', options: [] };

    allColumnsByGroup: { [key: string]: { key: string; label: string }[] } = {};
    columnsByGroup: { [key: string]: Observable<UntypedFormControl> } = {};
    customFiltersValues: SdTableCustomFiltersValue = {};

    /** True when the viewport size is small or extra-small  */
    isResponsive = false;

    /* table default children components */
    @ViewChild('search') search?: ElementRef;
    @ViewChild('searchColumnSelect') searchColumnSelect?: ElementRef;
    @ViewChild('filterSelect') filterSelect?: MatSelectionList;
    @ViewChild('customFilterComponent')
    customFilterComponent?: CustomFiltersComponent;
    @ViewChild(MatSort, { static: false }) sort?: MatSort;
    @ViewChild(MatPaginator, { static: false }) paginator?: MatPaginator;

    /* expand */
    expandedElement?: SdTableRowDto;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    expandContent?: any;
    @ViewChild('expandContent', { read: ViewContainerRef }) set content(content: ViewContainerRef) {
        if (content && this.expandComponent) {
            this.expandContent = content;
            const ref = this.expandContent.createComponent(this.expandComponent);
            ref.instance.expandData = this.expandedElement;
        }
    }

    @ViewChild('tableContainer', { read: ViewContainerRef }) set defineTableHeight(content: ViewContainerRef) {
        if (content && !this.fullHeight) {
            content.element.nativeElement.style.maxHeight =
                window.innerHeight - content.element.nativeElement.offsetTop - 110 + 'px';
        }
    }

    /* variables end */
    constructor(
        private _tableService: SdTableService,
        private changeDetector: ChangeDetectorRef,
        private translate: TranslatePipe,
        private dialog: MatDialog,
        private breakpointObserver: BreakpointObserver,
        private dialogMessage: SdDialogMessageService,
        private extraInfoService: SdExtraInfoService,
        private el: ElementRef,
    ) {}

    ngOnInit(): void {
        this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
            let effectiveLayoutIsCard = false;
            this.isResponsive = result.matches;
            if (result.matches) {
                if (this.responsiveLayout === 'card') {
                    effectiveLayoutIsCard = true;
                }
            }
            this.effectiveLayout = effectiveLayoutIsCard ? 'card' : 'table';
        });

        this.validateDefaultSort();

        this.searchSubject.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => {
            this.request();
        });
        this.request(true);
    }

    /**
     * XXX
     * */
    ngOnChanges(changes: SimpleChanges) {
        /* ONLY FOR ANGULARJS (1) UPDATE TABLE FOR THE ANGULAR (2) USE THE INSTANCE :: this.tableComponent.request() */
        if (changes['forceReload']?.currentValue > 0) {
            this.request();
        }
    }

    /**
     * XXX
     * */
    refresh() {
        this.data = [];
        this.request(true);
    }

    request(getMetadata = false) {
        this.isLoadingResults = true;

        const queryParamList: QueryParam[] = this.getQueryParameters(getMetadata);
        this._tableService.getData(this.endpoint, queryParamList).subscribe({
            next: (value: SdTableResponseDto) => {
                this.data = value.data.rows;
                this.dataLength = value.data.count;

                const currentSelectedItems = this.selection.selected;
                this.selection.clear();

                const itemsIds = this.data.map(z => z.id);
                currentSelectedItems.forEach(item => {
                    if (itemsIds.includes(item.id)) {
                        this.selection.select(this.data.filter(z => z.id === item.id)[0]);
                    }
                });

                const metadata = value.metadata;
                if (metadata) {
                    this.setMetadata(value.metadata);
                    this.selection = new SelectionModel<SdTableRowDto>(true, []);
                    this.selection.changed.asObservable().subscribe(() => {
                        if (this.titleAnchor) {
                            this.scrollToTable();
                        }
                        this.selectedRowsChange.emit(this.selection.selected.map(z => z.id));
                        if (this.selection.selected.length > 0 && this.extraInfo) {
                            const data = {
                                selected: this.selection.selected,
                                extra: this.extraDataForExtraInfo,
                            };
                            this.snackBarRef = this.extraInfoService.openSnackBar(this.extraInfo, data);
                        } else {
                            if (this.snackBarRef) {
                                this.extraInfoService.closeSnackBar(this.snackBarRef);
                            }
                        }
                    });
                }

                if (this.selectedRows.length > 0) {
                    this.visibleColumns.unshift('select');
                    this.data.forEach(row => {
                        this.selection.select(row);
                    });
                }

                this.isLoadingResults = false;
                /* update children with ngIf (statics elementRefs) */
                this.changeDetector.detectChanges();
                this.setPaginationTranslations();
            },
            error: () => {
                GENERATE_DEBUG_LOGS && console.log('error');
            },
        });
    }

    /**
     * XXX
     * */
    getQueryParameters(getMetadata = false): QueryParam[] {
        const queryParamsList: QueryParam[] = [];

        queryParamsList.push({
            id: 'visible-columns',
            value: JSON.stringify(this.visibleColumns ?? []),
        });

        if (!this.parameters.sort && this.defaultSort) {
            this.parameters.sort = this.defaultSort as Sort;
        }
        if (this.parameters?.sort) {
            queryParamsList.push({
                id: 'sort',
                value: JSON.stringify({
                    sortColumn: this.parameters.sort.active,
                    direction: this.parameters.sort.direction,
                }),
            });
        }

        if (this.parameters?.pagination) {
            queryParamsList.push({
                id: 'pagination',
                value: JSON.stringify(this.parameters.pagination),
            });
        }

        if (this.parameters.filter.length > 0) {
            queryParamsList.push({
                id: 'filter',
                value: this.parameters.filter,
            });
            queryParamsList.push({
                id: 'filter-columns',
                value: JSON.stringify(this.filterColumns),
            });
        }

        const customFilters = JSON.parse(JSON.stringify(this.customFiltersValues));
        if (this.customParameters.length > 0) {
            this.customParameters.forEach(param => {
                customFilters[param.key] = param.value;
            });
        }

        if (getMetadata) {
            queryParamsList.push({ id: 'with-metadata', value: 'true' });
        }

        if (this.selectedRows.length > 0) {
            queryParamsList.push({ id: 'selected-rows', value: JSON.stringify(this.selectedRows) });
        }

        queryParamsList.push({
            id: 'custom-filters',
            value: JSON.stringify(customFilters),
        });

        return queryParamsList;
    }

    /**
     * XXX
     * */
    setMetadata(metadata: SdTableMetadataDto) {
        this.selectColumnsOptions = [];
        const columnKey: string[] = [];
        Object.keys(metadata.allColumns).forEach(column => {
            columnKey.push(column);
            if (!metadata.allColumns[column]?.group) {
                metadata.allColumns[column].group = 'no-group';
            }
            const { label, group } = metadata.allColumns[column];
            this.selectColumnsOptions.push({
                id: column,
                label,
                group,
                column,
                sortOrder: columnKey.indexOf(column),
            });
        });

        const filterColumnKey: string[] = [];
        Object.keys(metadata.allColumns)
            .filter(
                column =>
                    metadata.allColumns[column].type !== 'client-render' &&
                    metadata.allColumns[column]?.sortable != false,
            )
            .forEach(column => {
                filterColumnKey.push(column);
                if (!metadata.allColumns[column]?.group) {
                    metadata.allColumns[column].group = 'no-group';
                }
                const { label, group } = metadata.allColumns[column];
                this.selectFilterColumnsOptions.push({
                    id: column,
                    label,
                    group,
                    column,
                    sortOrder: filterColumnKey.indexOf(column),
                });
            });
        this.inputWithOptionsValue.options = this.selectFilterColumnsOptions.map(option => option.id);

        this.typedColumns = metadata.allColumns;
        Object.keys(this.typedColumns).forEach(column => {
            if (!this.typedColumns[column]?.static) {
                this.typedColumns[column].static = SdTableColumnStaticEnum.NO;
            }
        });
        this.allColumns = Object.keys(metadata.allColumns);
        this.setColumnGroups(metadata.allColumns);

        this.setFilterColumns(metadata.allColumns);

        if (this.visibleColumnsSelected.length === 0) {
            this.setDefaultVisibleColumns(metadata.allColumns);
        }

        this.customFilters = metadata.customFilters ?? [];
    }

    /**
     * XXX
     * */
    setDefaultVisibleColumns(allColumns: SdTableAllColumns[]) {
        this.visibleColumnsSelected = [];
        this.selectedColumns = [];
        Object.keys(allColumns).forEach(column => {
            if (allColumns[column]?.defaultVisible) {
                this.visibleColumnsSelected.push(column);
                this.selectedColumns.push(column);
            }
        });
        this.visibleColumns = this.visibleColumnsSelected;
    }

    /**
     * XXX
     * */
    setFilterColumns(allColumns: SdTableAllColumns[]) {
        this.allFilterColumns = Object.keys(allColumns).filter(
            column => allColumns[column].type !== 'client-render' && allColumns[column]?.sortable != false,
        );
        if (this.filterColumns.length === 0) {
            this.filterColumns = Object.keys(allColumns).filter(
                column => allColumns[column].type !== 'client-render' && allColumns[column]?.sortable != false,
            );
        }
    }

    setColumnGroups(allColumns: SdTableAllColumns[]) {
        this.allColumnsByGroup = {};
        this.allColumns.forEach(column => {
            this.visibleColumnLabel[column] = allColumns[column].label;

            if (!this.allColumnsByGroup[allColumns[column].group]) {
                this.allColumnsByGroup[allColumns[column].group] = [];
            }
            if (allColumns[column].type != 'client-render' && allColumns[column]?.sortable != false) {
                this.allColumnsByGroup[allColumns[column].group].push({
                    key: column,
                    label: allColumns[column].label,
                });
            }
        });

        Object.keys(allColumns).forEach(column => {
            const data = allColumns[column];
            const group = data?.group ?? 'no-group';
            this.columnsByGroup[group] = new Observable<UntypedFormControl>();
        });
    }

    onCustomFilterChange(value) {
        this.customFiltersValues = value;
        this.request();
    }

    /* filter */
    resetFilters() {
        this.typedColumns = [];
        this.allColumns = [];
        this.allColumnsByGroup = {};
        this.visibleColumnLabel = {};
        this.allFilterColumns = [];
        this.customFilters = [];
        this.visibleColumnsSelected = [];
        this.visibleColumns = [];
        this.request(true);
    }

    getGroups() {
        return Object.keys(this.columnsByGroup).filter(group => group !== 'no-group');
    }

    /* sort */
    sortChange(sortData: Sort) {
        this.parameters.sort = sortData;
        this.request();
    }

    disableColumnSort(column: string) {
        if (column === 'select') return true;
        if (this.typedColumns[column]?.sortable === false) return true;
        return false;
    }

    disableColumnDragAndDrop(column: string) {
        return this.typedColumns[column]?.static !== null;
    }

    isStickedOnBegin(column: string): boolean {
        if (this.isResponsive) {
            if (this.typedColumns[column]?.responsiveStatic) {
                return this.typedColumns[column].responsiveStatic === SdTableColumnStaticEnum.BEGIN;
            }
        }

        return this.typedColumns[column]?.static === SdTableColumnStaticEnum.BEGIN;
    }

    isStickedOnEnd(column: string) {
        if (this.isResponsive) {
            if (this.typedColumns[column]?.responsiveStatic) {
                return this.typedColumns[column].responsiveStatic === SdTableColumnStaticEnum.END;
            }
        }

        return this.typedColumns[column]?.static === SdTableColumnStaticEnum.END;
    }

    /* pagination */
    setPaginationTranslations() {
        // todo - fix translations
        if (this.paginator) {
            this.paginator._intl.itemsPerPageLabel = this.translate.transform('sd-table.items-per-page-label');
            this.paginator._intl.firstPageLabel = this.translate.transform('sd-table.first-page-label');
            this.paginator._intl.nextPageLabel = this.translate.transform('sd-table.next-page-label');
            this.paginator._intl.lastPageLabel = this.translate.transform('sd-table.last-page-label');
            this.paginator._intl.previousPageLabel = this.translate.transform('sd-table.previous-page-label');
            this.paginator._intl.getRangeLabel = (page, pageSize, length) => {
                const ofTranslated = this.translate.transform('sd-table.of');
                return `${page * pageSize + 1} - ${pageSize * (page + 1)} ${ofTranslated} ${length}`;
            };
            this.paginator._intl.changes.next();
        }
    }

    pageChange(pageEvent: PageEvent) {
        this.parameters.pagination = {
            page: pageEvent.pageIndex,
            pageSize: pageEvent.pageSize,
        };
        this.request();
    }

    columnDropped(event: CdkDragDrop<string[]>) {
        let canMove = true;
        this.allColumns
            .filter(column => this.visibleColumns.includes(column))
            .forEach((column: string, key: number) => {
                if (this.typedColumns[column]?.static === 'begin') {
                    if (key >= event.currentIndex) {
                        canMove = false;
                    }
                }
                if (this.typedColumns[column]?.static === 'end') {
                    if (key <= event.currentIndex) {
                        canMove = false;
                    }
                }
            });

        if (canMove) {
            moveItemInArray(this.visibleColumns, event.previousIndex, event.currentIndex);
        }
    }

    /* select columns */
    selectedColumns: (number | string)[] = [];
    columnsChange($event: SdSelectOption[]) {
        const selectedColumns = $event.map(option => option['column']) as unknown as string[];
        const selectedColumnsId = $event.map(option => option.id) ?? [];

        const columnsToAdd: string[] = [];
        const columnsToRemove: string[] = [];

        this.selectedColumns.forEach(id => {
            if (!selectedColumnsId.includes(id)) {
                columnsToAdd.push(id + '');
            }
        });

        selectedColumnsId.forEach(id => {
            if (!this.selectedColumns.includes(id)) {
                columnsToRemove.push(id + '');
            }
        });

        this.removeSelectedColumns(columnsToRemove);
        this.addSelectedColumns(columnsToAdd);
        this.visibleColumns = selectedColumns;
        this.visibleColumnsSelected = selectedColumns;
        this.selectedColumns = selectedColumnsId;
        this.request();
    }

    removeSelectedColumns(columns: string[]) {
        const aux = [];
        this.visibleColumns.forEach((column, key) => {
            if (columns.includes(column)) {
                transferArrayItem(this.visibleColumns, aux, key, aux.length);
            }
        });
        this.visibleColumns = this.visibleColumns.filter(column => !columns.includes(column));
    }

    addSelectedColumns(columns: string[]) {
        columns.forEach((column, key) => {
            const originalIndex = this.allColumns.findIndex(z => z === column);
            let displayOnIndex = originalIndex;
            let hasNewIndex = false;
            this.visibleColumns.forEach((visibleColumn, visibleColumnKey) => {
                const currentVisibleColumnIndex = this.allColumns.findIndex(z => z === visibleColumn);
                if (currentVisibleColumnIndex >= originalIndex && !hasNewIndex) {
                    displayOnIndex = visibleColumnKey;
                    hasNewIndex = true;
                }
            });
            copyArrayItem(columns, this.visibleColumns, key, displayOnIndex);
        });
        this.visibleColumnsSelected = this.visibleColumns;
    }

    isIndeterminate(): boolean {
        return this.visibleColumns.length > 1 && this.visibleColumns.length !== this.allColumns.length;
    }

    isChecked(): boolean {
        return this.visibleColumns.length === this.allColumns.length;
    }

    clearSelection() {
        this.selection.clear();
    }

    displaySelected() {
        this.dialog
            .open(SelectedRowsComponent, {
                data: {
                    endpoint: this.endpoint,
                    selectedRows: this.selection.selected.map(z => z.id),
                    clientColumns: this.clientColumns,
                },
            })
            .afterClosed()
            .subscribe(result => {
                if (Array.isArray(result)) {
                    this.selection.clear();
                    result.forEach(id => this.selection.select(this.data.filter(z => z.id === id)[0]));
                }
            });
    }

    toggleSelection($event: MatCheckboxChange) {
        if ($event.checked === true) {
            const columnsToAdd = this.allColumns.filter(column => !this.visibleColumns.includes(column));
            this.addSelectedColumns(columnsToAdd);
        } else {
            this.visibleColumns = [];
            this.visibleColumnsSelected = [];
        }
        this.request();
    }

    /* select rows */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.data.length;
        return numSelected === numRows;
    }

    toggleAllRows() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }

        this.selection.select(...this.data);
    }

    checkboxLabel(row?: SdTableRowDto): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row['position'] ?? 0 + 1}`;
    }

    toggleRow(row: SdTableRowDto, selection) {
        if (this.visibleColumnsSelected.includes('select')) {
            selection.toggle(row);
        }
    }

    isEvenRow(rowId): boolean {
        return this.data.findIndex(row => row.id === rowId) % 2 != 0;
    }

    isNoWrapHeader(column: string) {
        return this.typedColumns[column]?.noWrapHeader === true;
    }

    isNoWrapRow(column: string) {
        return this.typedColumns[column]?.noWrapRows === true;
    }

    expandRow($event, element) {
        if (this.effectiveLayout === 'card') {
            this.dialogMessage.info('Not implemented yet');
        } else {
            this.expandedElement = this.expandedElement === element ? null : element;
        }
        $event.stopPropagation();
    }

    validateDefaultSort() {
        if (this.defaultSort instanceof String) {
            const splitedSort = this.defaultSort.split('::');
            this.defaultSort = {
                active: splitedSort[0],
                direction: splitedSort[1] === 'asc' ? 'asc' : 'desc',
            };
        }
    }

    searchWithOptionsChange($event: SdInputWithOptionsValue) {
        this.inputWithOptionsValue = $event;
        this.filterColumns = this.selectColumnsOptions
            .filter(option => $event.options.includes(option.id) && option['column'])
            .map(option => option['column']) as unknown as string[];

        if (this.parameters.filter == $event.input) {
            this.request();
        } else {
            this.searchSubject.next($event.input);
            this.parameters.filter = $event.input;
        }
    }

    scrollToTable() {
        const el = this.el.nativeElement.querySelector(`#${this.titleAnchor}`);
        if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
        }
    }

    // todo

    // passar se pode selecionar (server) ou nao, um callback ao selecionar (client),
    // no client setFooter() por exemplo no grid dos manage course que somam os itens selecionados
    // maximo de itens selecionaveis (server) se o max for 1 ser radio button
}
