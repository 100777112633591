import { Injectable } from '@angular/core';
import { GlobalAddActions } from '../types';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GlobalAddService {
    private actions: BehaviorSubject<GlobalAddActions> = new BehaviorSubject<GlobalAddActions>([]);
    public actions$ = this.actions.asObservable();

    setActions(actions: GlobalAddActions) {
        this.actions.next(actions);
    }
}
