import { Component, OnInit } from '@angular/core';
import { ITimelinetExtensionTitleComponent } from '../../../core/modules/shared/modules/sd-timeline/types';
import { TimelineDetailsService } from '../../services/timeline-details.service';
import { MatDialog } from '@angular/material/dialog';
import {
    TimelineDataLakeEmailsMessageDetailsAttachments,
    TimelineDataLakeEmailsTimeLineItem,
} from '../../types/data-lake-emails-timeline.types';
import { AttachmentLinkPreviewComponent } from '../attachment-link-preview/attachment-link-preview.component';
import { Translations } from '@translation-keys';

@Component({
    selector: 'app-timelinet-extension-title',
    templateUrl: './timelinet-extension-title.component.html',
    styleUrls: ['./timelinet-extension-title.component.scss'],
})
export class TimelinetExtensionTitleComponent implements OnInit, ITimelinetExtensionTitleComponent {
    public data!: unknown;
    public attachments: TimelineDataLakeEmailsMessageDetailsAttachments[] = [];

    translations = Translations;

    constructor(public timelineDetailsService: TimelineDetailsService, public dialog: MatDialog) {}

    async ngOnInit() {
        const timelineItemData = this.data as TimelineDataLakeEmailsTimeLineItem;
        const { data } = await this.timelineDetailsService.getAttachments(timelineItemData.id);
        this.attachments = data.results[0].attachments ?? [];
    }

    attachmentLinkPreview(attachment: TimelineDataLakeEmailsMessageDetailsAttachments, $event: Event) {
        $event.stopPropagation();
        this.dialog.open(AttachmentLinkPreviewComponent, {
            width: '90vw',
            height: '90vh',
            data: { link: attachment.publicLink.url, name: attachment.name },
        });
    }

    getIcon(attachment: TimelineDataLakeEmailsMessageDetailsAttachments) {
        const nameSplit = attachment.name.split('.');
        switch (nameSplit[nameSplit.length - 1]) {
            case 'pdf':
                return 'picture_as_pdf';
            case 'csv':
                return 'csv';
            case 'jpg':
            case 'jpeg':
            case 'png':
                return 'image';
            default:
                return 'description';
        }
    }
}
