<div class="settings">

    @if (displayGroups.includes('user')) {
        <div class="group">
            <div class="name">
                <i class="fi fi-rr-user"></i>
                {{ user?.displayName || user?.fullName}}
            </div>
            <div class="actions">
                <a
                    [routerLink]="['', { outlets: { p1: ['user', 'profile'] } } ]"
                    [queryParams]="getQueryParams()"
                >
                    <div class="item" [ngClass]="{ active: this.popupWrapperService.route?.fullUrl === 'user/profile' }">
                        {{ translations.core.modules.userSettings.commons.labels.profile | translate }}
                    </div>
                </a>
                @if (acl.can('permission.developer.wip')) {
                    <a class="item">
                        {{ translations.core.modules.userSettings.commons.labels.preferences | translate }}
                    </a>
                    <a class="item">
                        {{ translations.core.modules.userSettings.commons.labels.signInSecurity | translate }}
                    </a>
                }
            </div>
        </div>
    }

    @if (acl.can('permission.developer.wip') && displayGroups.includes('tenant')) {
        <div class="group">
            <div class="name">
                <i class="fi fi-rr-visit"></i>
                ESSR
            </div>
            <div class="actions">
                <a class="item">
                    {{ translations.core.modules.userSettings.commons.labels.profile | translate }}
                </a>
                <a class="item">
                    {{ translations.core.modules.userSettings.commons.labels.users | translate }}
                </a>
                <a class="item">
                    {{ translations.core.modules.userSettings.commons.labels.billing | translate }}
                </a>
            </div>
        </div>
    }

    @if (acl.can('permission.developer.wip') && displayGroups.includes('switch-workspace')) {
        <div class="group">
            <div class="name workspace-name">
                <span>
                    <i class="fi fi-rr-visit"></i>
                    {{ translations.core.modules.userSettings.commons.labels.switchWorkspace | translate }}
                </span>
                @if (displayTenants) {
                    <i class="fi fi-rr-angle-small-down arrow" (click)="displayTenants = false" aria-hidden="true"></i>
                } @else {
                    <i class="fi fi-rr-angle-small-up arrow" (click)="displayTenants = true" aria-hidden="true"></i>
                }
            </div>
            <mat-radio-group
                aria-labelledby="example-radio-group-label"
                [(ngModel)]="currentTenant"
                (ngModelChange)="onTenantChange($event)"
            >
                <div class="options">
                    @if (displayTenants) {
                        @for (tenant of userTenants; track tenant.id) {
                            <mat-radio-button color="primary" [value]="tenant.id">
                                <div class="checkbox-label"> {{ tenant.label }} </div>
                            </mat-radio-button>
                        }
                        <div class="add-tenant">
                            {{ translations.core.modules.userSettings.commons.actions.new.label | translate }}
                        </div>
                    }
                </div>
            </mat-radio-group>
        </div>
    }

    @if (displayGroups.includes('sign-out')) {
        <div class="group">
            <div class="name sign-out" (click)="logout()" aria-hidden="true">
                <i class="fi fi-rr-exit"></i>
                {{ translations.core.modules.userSettings.commons.actions.signOut.label | translate }}
            </div>
        </div>
    }

</div>
