import {
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { PageFiltersComponent } from '../page-filters/page-filters.component';
import { ResponsiveNavigationFiltersService } from '../../services/responsive-navigation-filters.service';
import { NavigationStart, Router } from '@angular/router';
import { MenuItem } from '../../../../services/menu.service';
import { ResponsiveNavigationService } from '../../services/responsive-navigation.service';
import { BreakpointService } from '../../../../services/breakpoint.service';
import { MatDrawerContainer } from '@angular/material/sidenav';
import { ResponsiveNavigationHeaderService } from '../../services/responsive-navigation-header.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-responsive-navigation-header',
    standalone: false,
    templateUrl: './responsive-navigation-header.component.html',
    styleUrl: './responsive-navigation-header.component.scss',
})
export class ResponsiveNavigationHeaderComponent implements OnInit {
    hasSearch = false;
    hasFilters = false;
    template: TemplateRef<unknown> | undefined;
    mobileFilterService: ResponsiveNavigationFiltersService | undefined;

    @ViewChild('inputSearch') inputSearch!: ElementRef;
    @ViewChild('inputSearch', { read: ViewContainerRef }) set content(content: ViewContainerRef) {
        if (content) {
            content.element.nativeElement.focus();
        }
    }

    value = '';
    displaySearch = false;
    isSimplified = false;
    currentMenu: MenuItem | null = null;

    constructor(
        private _bottomSheet: MatBottomSheet,
        public rns: ResponsiveNavigationService,
        public matDrawer: MatDrawerContainer,
        public router: Router,
        public bk: BreakpointService,
        public cdr: ChangeDetectorRef,
        public destroyRef: DestroyRef,
        public responsiveNavigationHeaderService: ResponsiveNavigationHeaderService,
    ) {}

    ngOnInit() {
        this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(event => {
            if (event instanceof NavigationStart) {
                this.responsiveNavigationHeaderService.reset();
            }
        });

        this.rns.currentMenu$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(currentMenu => (this.currentMenu = currentMenu));

        this.bk.isSimplified$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(isSimplified => {
            this.isSimplified = isSimplified;
            this.cdr.detectChanges();
        });

        this.responsiveNavigationHeaderService.settings$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(settings => {
                this.hasFilters = settings.hasFilters;
                this.hasSearch = settings.hasSearch;
                this.template = settings.filterTemplate;
                this.mobileFilterService = settings.filterService;
            });
    }

    enableSearch() {
        this.displaySearch = true;
    }

    clearSearch() {
        this.value = '';
        this.displaySearch = false;
        this.onSearchChange();
    }

    displayFilters() {
        this._bottomSheet.open(PageFiltersComponent, {
            panelClass: 'responsive-navigation-header-filters',
            data: {
                template: this.template,
                mobileFilterService: this.mobileFilterService,
            },
        });
    }

    onSearchChange() {
        this.responsiveNavigationHeaderService.onSearchChange(this.value);
    }

    filterClass() {
        const filledFilter = 'fi fi-sr-filter';
        const nonFilledFilter = 'fi fi-rr-filter';
        if (!this.mobileFilterService) return nonFilledFilter;

        let hasFilledFilter = false;
        Object.values(this.mobileFilterService.currentFilter).forEach(filterValue => {
            if (!filterValue) return;
            if ((Array.isArray(filterValue) && filterValue.length > 0) || !Array.isArray(filterValue)) {
                hasFilledFilter = true;
            }
        });

        return hasFilledFilter ? filledFilter : nonFilledFilter;
    }

    openModules() {
        if (window.location.pathname.includes('sd1')) {
            const item = document.getElementsByClassName('new-angular-content').item(0);
            if (item) {
                item.classList.add('full-size-modules');
            }
        }

        this.rns.openModulesDrawer();
        if (this.matDrawer._drawers.toArray().length > 0) {
            this.matDrawer._drawers.toArray()[0].closedStart.subscribe(() => {
                if (window.location.pathname.includes('sd1')) {
                    const item = document.getElementsByClassName('new-angular-content').item(0);
                    if (item) {
                        item.classList.remove('full-size-modules');
                    }
                }
            });
        }
    }
}
