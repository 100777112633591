import { Injectable } from '@angular/core';
import { BreakpointService } from '../../../../layout/services/breakpoint.service';
import { BehaviorSubject } from 'rxjs';
import { MenuModule, MenuService } from '../../../../layout/services/menu.service';
import { NavigationEnd, Router } from '@angular/router';
import { GlobalAddService } from '../../../../layout/modules/global-add/services/global-add.service';
import { GlobalAddActions } from '../../../../layout/modules/global-add/types';

@Injectable({
    providedIn: 'root',
})
export class ActiveModulesService {
    private init = false;
    private isSimplified = false;

    private activeModules: BehaviorSubject<Array<MenuModule>> = new BehaviorSubject<Array<MenuModule>>([]);
    public activeModules$ = this.activeModules.asObservable();

    constructor(
        public breakpointService: BreakpointService,
        public menuService: MenuService,
        public router: Router,
        public globalAddService: GlobalAddService,
    ) {}

    listen() {
        if (this.init) return;
        this.init = true;
        if (!this.menuService.init) {
            this.menuService.loadRoutes();
        }

        this.breakpointService.isSimplified$.subscribe(isSimplified => (this.isSimplified = isSimplified));
        this.menuService.modules.asObservable().subscribe(() => this.setActiveModules());
        this.menuService.menusByModule.asObservable().subscribe(() => this.setActiveModules());

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.setActiveModules();
            }
        });

        this.activeModules$.subscribe(activeModules => {
            this.globalAddService.setActions(activeModules.map(z => z.globalAddActions).flat() as GlobalAddActions);
        });
    }

    setActiveModules() {
        if (this.isSimplified) {
            const modules = this.getUserAvailableModules();
            let module = this.router.url.split('/')[1];

            if (module.length === 0 || module === 'temp-empty') {
                module = window.location.pathname.split('/')[1];
            }

            if (module === 'sd1') {
                module = 'sd';
            }
            const currentModule = modules.find(z => z.url === module);
            if (currentModule) {
                this.activeModules.next([currentModule]);
            }
        } else {
            this.activeModules.next(this.getUserAvailableModules());
        }
    }

    getUserAvailableModules(): Array<MenuModule> {
        // todo - When adding permissions to modules, this method checks if the permissions are correctly applied
        return this.menuService.modules.value;
    }
}
