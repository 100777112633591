import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAsideMenuComponent } from './components/user-aside-menu/user-aside-menu.component';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';

@NgModule({
    declarations: [UserAsideMenuComponent],
    imports: [CommonModule, MatRadioButton, MatRadioGroup, TranslateModule, FormsModule, RouterLink],
    exports: [UserAsideMenuComponent],
})
export class UserAsideMenuModule {}
