import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import {
    TimelineDataLakeEmailsMessageAttachmentDetailsResult,
    TimelineDataLakeEmailsMessageDetailsResult,
} from '../types/data-lake-emails-timeline.types';
@Injectable({
    providedIn: 'root',
})
export class TimelineDetailsService {
    constructor(public apollo: Apollo) {}

    async getDetails(id: number) {
        const getMessageDetails = `
            query GetMessageDetails($id: bigint!) {
                results: DLE_Message(where: {id: {_eq: $id}}) {
                    id
                    date
                    htmlBodyPublicLink {
                      url
                    }
                    participants {
                        email
                        name
                        isFrom
                        isCc
                        isTo
                    }
                    attachments {
                        name
                        publicLink {
                            url
                          }
                    }
                }
            }`;

        return this.apollo
            .watchQuery<TimelineDataLakeEmailsMessageDetailsResult>({
                query: gql(getMessageDetails),
                variables: {
                    id,
                },
            })
            .result();
    }

    async getAttachments(id: number) {
        const getMessageDetails = `
            query GetMessageAttachments($id: bigint!) {
                results: DLE_Message(where: {id: {_eq: $id}}) {
                    id
                    attachments {
                        name
                        publicLink {
                            url
                          }
                    }
                }
            }`;

        return this.apollo
            .watchQuery<TimelineDataLakeEmailsMessageAttachmentDetailsResult>({
                query: gql(getMessageDetails),
                variables: {
                    id,
                },
            })
            .result();
    }
}
