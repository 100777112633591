import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TimelineDetailsService } from '../../services/timeline-details.service';
import {
    TimelineDataLakeEmailsMessageDetails,
    TimelineDataLakeEmailsTimeLineItem,
} from '../../types/data-lake-emails-timeline.types';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-timeline-details',
    templateUrl: './timeline-details.component.html',
    styleUrls: ['./timeline-details.component.scss'],
})
export class TimelineDetailsComponent implements OnInit, OnDestroy {
    data!: TimelineDataLakeEmailsTimeLineItem;
    details!: TimelineDataLakeEmailsMessageDetails;

    allowScroll = false;

    @ViewChild('content') content?: ElementRef;

    @HostListener('window:scroll', [])
    onWindowScroll() {
        if (this.content) {
            const element = this.content.nativeElement;
            if (element) {
                const bounding = element.getBoundingClientRect();
                const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

                if (
                    !(bounding.top >= 0 && bounding.bottom <= (viewportHeight || document.documentElement.clientHeight))
                ) {
                    this.allowScroll = false;
                }
            }
        }
    }
    constructor(public timelineDetailsService: TimelineDetailsService, public dialog: MatDialog) {}

    async ngOnInit() {
        const { data } = await this.timelineDetailsService.getDetails(this.data.id);
        this.details = data.results[0];
    }

    ngOnDestroy() {
        console.log('bye');
    }
}
