import { HttpHeaders } from '@angular/common/http';
import { HttpLink } from 'apollo-angular/http';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';

const cache = new InMemoryCache();

const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
            console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        });
    }
});

// Define a function to periodically clear the cache
// function clearCachePeriodically(cache: InMemoryCache, intervalInSeconds: number) {
//     const timeInMilliseconds = intervalInSeconds * 1000;
//     setInterval(() => {
//         cache.gc();
//         cache.reset();
//     }, timeInMilliseconds);
// }

export default function ApolloOptionsFactory(httpLink: HttpLink) {
    const language = localStorage.getItem('lang') ?? 'en';
    const headers = new HttpHeaders();
    const httpLinkHandler = httpLink.create({
        uri: '/operational-graphql/v1/graphql',
        headers: headers.set('X-Tab-Language', language),
    });

    /*
    Websocket was broken after we implemented the webhook authentication.
    As we're not using any real time subscription, we're commenting it for now.
    * */

    // Create a WebSocket link:
    // const ws = new WebSocketLink({
    //     uri: `wss://${document.location.host}/operational-graphql/v1/graphql`,
    //     options: {
    //         reconnect: true,
    //     },
    // });
    // const link = split(
    //     // split based on operation type
    //     ({ query }) => {
    //         const mainDefinition = getMainDefinition(query);
    //
    //         if ('operation' in mainDefinition) {
    //             const operationDefinitionNode = mainDefinition as OperationDefinitionNode;
    //             const isSubscription = operationDefinitionNode.operation === 'subscription';
    //             if (isSubscription) {
    //                 console.info(`Subscription requested, returning websocket link.`, { query });
    //             }
    //             return isSubscription;
    //         }
    //
    //         return false;
    //     },
    //     ws,
    //     http,
    // );

    // time in seconds - commented because it's spamming graphql queries and not only cleaning the cache
    // clearCachePeriodically(cache, 5);

    const link = ApolloLink.from([errorLink, httpLinkHandler]);

    return {
        cache: cache,
        // link,
        link,
    };
}
