import { Component, Input, OnInit } from '@angular/core';
import { SdTableCustomActions } from '../../types';

@Component({
    selector: 'sd-table-custom-actions',
    templateUrl: './custom-actions.component.html',
    styleUrls: ['./custom-actions.component.scss'],
})
export class CustomActionsComponent implements OnInit {
    @Input() actions: SdTableCustomActions[] = [];

    ngOnInit(): void {
        this.actions.forEach(action => {
            if (action.type === 'link' && action?.link) {
                action.url = action.link(null);
            }
        });
    }
}
