<div class="title" mat-dialog-title> {{ data.title ?? '' | translate }} </div>

<mat-dialog-content class="mat-typography">
    <p>
        <strong>
            {{ data.message | translate }}
        </strong>
    </p>
    <p class="message">
        {{ data.description ?? '' | translate }}
    </p>
</mat-dialog-content>

<mat-dialog-actions align="end">

    @for (btn of data.rejected_buttons; track btn) {
        <button
            id="reject-{{ btn }}"
            mat-flat-button
            color="accent"
            [ngClass]="data.type"
            [mat-dialog-close]="btn"
        >
            {{ btn | translate }}
        </button>
    }

    @if (data.rejected_buttons.length === 0) {
        <button mat-flat-button color="accent" [mat-dialog-close]="false"> {{ translations.commons.actions.cancel.label | translate }} </button>
    }

    @for (btn of data.resolve_buttons; track btn) {
        <button
            mat-flat-button
            id="resolve-{{ btn }}"
            [color]="data.resolveButtonColor"
            [ngClass]="data.type"
            [mat-dialog-close]="btn"
        >
            {{ btn | translate }}
        </button>
    }

    @if (data.resolve_buttons.length === 0) {
        <button mat-flat-button [color]="data.resolveButtonColor" [mat-dialog-close]="true"> {{ translations.commons.actions.continue.label | translate }} </button>
    }
</mat-dialog-actions>
