import { GSearchGetUpstreamsResponseItem } from './g-search-upstream.types';

export enum GSearchDataSearchStates {
    LOADING = 'loading',
    READY_WITH_RESULTS = 'readyWithResults',
    READY_WITHOUT_RESULTS = 'readyWithoutResults',
    ERROR = 'error',
}

export interface GSearchDataSearchUpstreamSearchTerms {
    upstreamId: number;
    searchValue: string;
    upstream?: GSearchGetUpstreamsResponseItem;
}

export interface GSearchDataSearchGetSearchResultCount {
    aggregate: {
        count: number;
    };
}

export interface GSearchDataSearchGetSearchResult {
    link: string;
    title: string;
    subtitle: string;
    tagsJson: string;
    upstreamRecordId: number;
}

export interface GSearchDataSearchGetSearchResultResponse {
    results: GSearchDataSearchGetSearchResult[];
    countResult: GSearchDataSearchGetSearchResultCount;
}
