<mat-card class="panel mb-25 tagus-card sd2-menu-is-open" *ngIf="shouldDisplayMenu" [ngClass]="{ 'aside-open': isAsideOpen }">
    <mat-card-content>
        <h5 class="mb-0">
            {{ translations.gSearch.commons.labels.results | translate }}
            <mat-icon (click)="closeMenu()">close</mat-icon>
        </h5>
        <div class="results">
            <app-data-search [search]="searchValue" class="data-search" />
        </div>
    </mat-card-content>
</mat-card>
