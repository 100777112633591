import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LocationMonitorService {
    private lastPath = new BehaviorSubject<string>('/');

    get isLastPath$() {
        return this.lastPath.asObservable();
    }

    // called on app.component
    watch() {
        let lastPath = window.location.pathname;
        setInterval(() => {
            if (lastPath !== window.location.pathname) {
                lastPath = window.location.pathname;
                this.lastPath.next(lastPath);
            }
        }, 100);
    }
}
