import { Component, Input } from '@angular/core';
import { DropdownNotifications } from '../../types';
import { UpgradeModule } from '@angular/upgrade/static';
import { Translations } from '@translation-keys';

@Component({
    selector: 'app-notification-item',
    templateUrl: './notification-item.component.html',
    styleUrl: './notification-item.component.scss',
})
export class NotificationItemComponent {
    @Input({ required: true }) notification!: DropdownNotifications;
    translations = Translations;
    constructor(private upgrade: UpgradeModule) {}

    getStateAsUrl(state: string) {
        const $state = this.upgrade.$injector.get('$state');
        return $state.href(state);
    }
}
