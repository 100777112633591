import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ContactsModule } from '../contacts/contacts.module';
import { TimelineItemProviderService } from './services/timeline-item-provider.service';
import { TimelineDetailsComponent } from './components/timeline-details/timeline-details.component';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@shared/pipes.module';

@NgModule({
    declarations: [TimelineDetailsComponent],
    imports: [ContactsModule, CommonModule, PipesModule],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: accountsPayableTimelineItemProviderServiceFactory,
            deps: [TimelineItemProviderService],
            multi: true,
        },
    ],
})
export class AccountsPayableModule {}

export function accountsPayableTimelineItemProviderServiceFactory(service: TimelineItemProviderService) {
    return () => service.load();
}
