<div class="filters">

    <div class="filter" *ngFor="let filter of filters">
        
        <mat-form-field
          *ngIf="filter.type === 'text'"
          [ngClass]="{'default-class': true}">
            <mat-label> {{ filter.label | translate }} </mat-label>
            <input matInput [(ngModel)]="filterValues[filter.id]" (ngModelChange)="filterChanges()">
        </mat-form-field>

        <sd-select
          *ngIf="filter.type === 'select' && filter.selectParams"
          [ngClass]="{'default-class': true}"
          [(ngModel)]="filterValues[filter.id]"
          [endpoint]="filter.selectParams.endpoint"
          [label]="filter.label"
          [hasReload]="false"
          [multiple]="filter.selectParams.multiple"
          (ngModelChange)="filterChanges()"
        ></sd-select>

        <mat-form-field appearance="fill" *ngIf="filter.type === 'date-interval'">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate placeholder="Start date">
              <input matEndDate placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        
    </div>

</div>