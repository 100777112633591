import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Injectable({
    providedIn: 'root',
})
export class BreakpointService {
    protected isSimplified = new BehaviorSubject<boolean>(false);
    protected isComplete = new BehaviorSubject<boolean>(true);
    protected layout = new BehaviorSubject<'simplified' | 'complete'>('complete');

    public isSimplified$ = this.isSimplified.asObservable();
    public isComplete$ = this.isComplete.asObservable();
    public layout$ = this.layout.asObservable();

    constructor(protected bk: BreakpointObserver) {}

    init() {
        const simplifiedBreakpoint = [
            Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Handset,
            Breakpoints.TabletPortrait,
        ];

        this.bk.observe(simplifiedBreakpoint).subscribe(result => {
            this.isSimplified.next(result.matches);
            this.isComplete.next(!result.matches);
            this.layout.next(result.matches ? 'simplified' : 'complete');
        });
    }
}
