import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { SdTableCustomFiltersDto, SdTableCustomFiltersValue } from '../../types';

@Component({
    selector: 'sd-table-custom-filters',
    templateUrl: './custom-filters.component.html',
    styleUrls: ['./custom-filters.component.scss'],
    providers: [{ provide: MAT_DATE_LOCALE, useValue: 'de-CH' }],
})
export class CustomFiltersComponent implements OnInit {
    @Input() filters: SdTableCustomFiltersDto[] = [];

    @Output() customFilterChange = new EventEmitter<SdTableCustomFiltersValue>();

    filterValues: SdTableCustomFiltersValue = {};

    ngOnInit(): void {
        this.setData();
    }

    setData() {
        this.filters.forEach(filter => {
            this.filterValues[filter.id] = null;

            const types = {
                select: this.setSelectDefaultValue,
                date: this.setDateDefaultValue,
                'date-interval': this.setDateIntervalDefaultValue,
            };

            if (Object.keys(types).includes(filter.type)) {
                const typeWithFirstLetterUppercase =
                    filter.type === 'date-interval'
                        ? 'DateInterval'
                        : filter.type.charAt(0).toUpperCase() + filter.type.slice(1);

                this[`set${typeWithFirstLetterUppercase}DefaultValue`](filter);
            }
        });
    }

    setDateDefaultValue(filter: SdTableCustomFiltersDto) {
        if (filter?.dateParams?.defaultValue) {
            this.filterValues[filter.id] = filter.dateParams.defaultValue;
        }
    }

    setDateIntervalDefaultValue(filter: SdTableCustomFiltersDto) {
        if (filter?.dateIntervalParams?.defaultValue) {
            this.filterValues[filter.id] = filter.dateIntervalParams.defaultValue;
        }
    }

    setSelectDefaultValue(filter: SdTableCustomFiltersDto) {
        if (filter?.selectParams?.defaultValues) {
            this.filterValues[filter.id] = filter.selectParams.defaultValues;
        }
    }

    filterChanges() {
        this.customFilterChange.emit(this.filterValues);
    }
}
