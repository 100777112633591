import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { SdTableColumnDto, SdTableClientColumn, SdTableRowDto, SdTableColumnActions } from '../../types';

@Component({
    selector: 'sd-table-column',
    templateUrl: './column.component.html',
    styleUrls: ['./column.component.scss'],
})
export class ColumnComponent implements OnInit, OnChanges {
    @Input() column!: SdTableColumnDto;
    @Input() index = 0;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() data: any;
    @Input() rowData!: SdTableRowDto;
    @Input() columnName!: string;
    @Input() clientColumns: SdTableClientColumn[] = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentClientColumn: any = {};

    ngOnInit(): void {
        this.initData();
    }

    ngOnChanges() {
        this.initData();
    }

    initData() {
        const { column, clientColumns, columnName } = this;

        if (this.currentClientColumn) {
            this.currentClientColumn[this.index] = {
                actions: [],
            };

            if (column.type === 'client-render' && clientColumns) {
                const filteredColumns = clientColumns.filter(column => column.column === columnName);
                if (filteredColumns.length === 0) {
                    // console.error('Error on column ' + columnName);
                } else {
                    if (filteredColumns[0]?.class) {
                        this.currentClientColumn[this.index].class = filteredColumns[0].class;
                    }
                    this.currentClientColumn[this.index].actions = filteredColumns[0].actions.map(action =>
                        this.setActions(action),
                    );
                }
            }
        }
    }

    setActions(action: SdTableColumnActions) {
        if (action.type === 'link' && action.link) {
            action.url = action.link(this.rowData);
        }

        if (action.type === 'text-link' && action.link && action.label) {
            action.url = action.link(this.rowData);
            action.text = action.label(this.rowData);
        }
        return {
            ...action,
        };
    }
}
