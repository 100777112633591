import { Injectable, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RightDrawerService {
    private openDrawer = new Subject<TemplateRef<unknown>>();
    public openDrawer$ = this.openDrawer.asObservable();

    emit(template: TemplateRef<unknown>) {
        this.openDrawer.next(template);
    }
}
