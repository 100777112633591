import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponsiveNavigationHeaderComponent } from './components/responsive-navigation-header/responsive-navigation-header.component';
import { ResponsiveNavigationFooterComponent } from './components/responsive-navigation-footer/responsive-navigation-footer.component';
import { ResponsiveNavigationScreenComponent } from './components/responsive-navigation-screen/responsive-navigation-screen.component';
import { NewComponent } from './components/new/new.component';
import { PageFiltersComponent } from './components/page-filters/page-filters.component';
import { PageBottomSheetComponent } from './components/page-bottom-sheet/page-bottom-sheet.component';
import { PipesModule } from '@shared/pipes.module';
import { AngularMaterialModule } from '@shared/angular-material.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ResponsiveNavigationModulesComponent } from './components/responsive-navigation-modules/responsive-navigation-modules.component';
import { Ng1LinkModule } from '../../../core/modules/shared/modules/ng1-link/ng1-link.module';
import { ResponsiveNavigationScreenItemComponent } from './components/responsive-navigation-screen-item/responsive-navigation-screen-item.component';
import { LayoutModule } from '../../layout.module';
import { NgScrollbar } from 'ngx-scrollbar';

@NgModule({
    declarations: [
        ResponsiveNavigationScreenItemComponent,
        ResponsiveNavigationHeaderComponent,
        ResponsiveNavigationFooterComponent,
        ResponsiveNavigationScreenComponent,
        ResponsiveNavigationModulesComponent,
        NewComponent,
        PageFiltersComponent,
        PageBottomSheetComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        PipesModule,
        AngularMaterialModule,
        TranslateModule,
        Ng1LinkModule,
        LayoutModule,
        NgScrollbar,
    ],
    exports: [
        PageBottomSheetComponent,
        ResponsiveNavigationHeaderComponent,
        ResponsiveNavigationFooterComponent,
        ResponsiveNavigationScreenComponent,
        ResponsiveNavigationModulesComponent,
    ],
})
export class ResponsiveNavigationModule {}
