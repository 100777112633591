import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ContactsModule } from '../contacts/contacts.module';
import { TimelineItemProviderService } from './services/timeline-item-provider.service';
import { TimelineDetailsComponent } from './components/timeline-details/timeline-details.component';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@shared/pipes.module';
import { AttachmentLinkPreviewComponent } from './components/attachment-link-preview/attachment-link-preview.component';
import { SdTableModule } from '../core/modules/shared/modules/sd-table/sd-table.module';
import { AngularMaterialModule } from '@shared/angular-material.module';
import { TimelinetExtensionTitleComponent } from './components/timelinet-extension-title/timelinet-extension-title.component';

@NgModule({
    declarations: [TimelineDetailsComponent, AttachmentLinkPreviewComponent, TimelinetExtensionTitleComponent],
    imports: [ContactsModule, CommonModule, PipesModule, AngularMaterialModule, SdTableModule],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: timelineItemProviderServiceFactory,
            deps: [TimelineItemProviderService],
            multi: true,
        },
    ],
})
export class DataLakeEmailsModule {}

export function timelineItemProviderServiceFactory(service: TimelineItemProviderService) {
    return () => service.load();
}
