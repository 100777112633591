import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MenuToggleService {
    private isToggled = new BehaviorSubject<boolean>(false);

    constructor() {
        const savedStatus = localStorage.getItem('sidebarStatus');
        if (savedStatus && !!savedStatus === false) {
            this.toggle();
        }
    }

    get isToggled$() {
        return this.isToggled.asObservable();
    }

    toggle() {
        const newValue = !this.isToggled.value;
        this.isToggled.next(newValue);
        localStorage.setItem('sidebarStatus', newValue + '');
    }
}
