import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
// import { environment } from './environments/environment';

// if (environment.production) {
//     enableProdMode();
// }

import * as angular from 'angular';
import { setAngularJSGlobal } from '@angular/upgrade/static';

window.angular = angular;

setAngularJSGlobal(angular);

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
