import { forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import rootTranslationsForAutoComplete from './root-translations-for-autocomplete';

export default function TranslationsFactory(http: HttpClient, translate: TranslateService) {
    // validateTranslations();
    return () =>
        new Promise<boolean>((resolve: (res: boolean) => void) => {
            setTranslations(http, translate, resolve);
        });
}
export const Translations = rootTranslationsForAutoComplete;

export function setTranslations(http: HttpClient, translate: TranslateService, resolve: (res: boolean) => void) {
    const defaultLocale = 'en';
    const storageLocale = sessionStorage.getItem('lang') ?? localStorage.getItem('lang');
    const locale = storageLocale ?? defaultLocale;
    /* all places with translations to now */
    const fork_join_sources = [http.get(`/api/translations/${locale}.json`)];
    let translatedKeys = {};

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    forkJoin(fork_join_sources).subscribe((response: any) => {
        translatedKeys = response[0];

        translate.setTranslation(locale, translatedKeys, true);

        translate.setDefaultLang(defaultLocale);
        translate.use(locale);
        if (resolve) resolve(true);
    });
}
