import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Subject, take } from 'rxjs';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Translations } from '@translation-keys';
import { FavoritesService } from '../../core/modules/user-pref/services/favorites.service';
import { NotificationService } from '../../core/modules/notifications/services/notification.service';
import { Notifications } from '../../core/modules/notifications/types';
import { UpgradeModule } from '@angular/upgrade/static';
import { ObjectUtilsService } from '../../core/modules/shared/services/object-utils.service';
import { GlobalAddAction } from '../modules/global-add/types';

export interface MenusResponse {
    [key: string]: MenusResponseItem;
}

export interface MenusResponseItem {
    label: string;
    url: string;
    state: string;
    isNav: boolean;
    icon: string | null;
    permissions: Array<string>;
    stateParams: object | null;
    notificationLookupKey: string | null;
    ng1ActiveRouteRegexs: Array<string> | null;
    children: Array<MenusResponseItem>;
    globalAddActions?: Array<GlobalAddAction>;
}

export interface MenuModule {
    label: string;
    url: string;
    stateUrl?: string;
    icon: string;
    children: Array<MenuItem>;
    globalAddActions?: Array<GlobalAddAction>;
}

export interface MenuItem {
    label: string;
    isNav: boolean;
    url: string;
    permissions: Array<string>;
    notificationLookupKey: string | null;
    notifications?: number;
    icon: string | null;
    children: Array<MenuItem>;
    /**/
    isNg1Link: boolean;
    menuSearchPath?: string;
    favoriteLabel?: string;
    isFavorite?: boolean;
    favoritesGroup?: Array<string>;
    translatedLabel?: string;
    ng1ActiveRouteRegexs?: Array<string>;
}

export interface MenuItemsByModule {
    [key: string]: Array<MenuItem>;
}

export interface DocumentationRole {
    name: string;
    state: string;
}

export interface DocumentationResponse {
    success: boolean;
    documentations: DocumentationRole[];
}

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    menuByUrl: { [key: string]: MenuItem } = {};

    translations = Translations;
    notifications = new BehaviorSubject<Notifications>(this.notificationService.blankValues);

    modules: BehaviorSubject<Array<MenuModule>> = new BehaviorSubject<Array<MenuModule>>([]);
    menusByModule = new BehaviorSubject<MenuItemsByModule>({});

    menusLoaded = new Subject<void>();

    constructor(
        private upgrade: UpgradeModule,
        public translatePipe: TranslatePipe,
        public http: HttpClient,
        public favoritesService: FavoritesService,
        public translateService: TranslateService,
        public notificationService: NotificationService,
        public objectUtils: ObjectUtilsService,
    ) {}

    favorites: string[] = [];
    init = false;

    loadRoutes() {
        if (!this.init) {
            this.listenFavorites();
            this.listenNotifications();
            this.listenToLanguageChange();
            this.init = true;
        }
        const ref = this.http.get<MenusResponse>(`/api/menus`).subscribe(response => {
            this.setModules(response);
            this.setMenus(response);
            this.updateMenus();
            this.menusLoaded.next();
            ref.unsubscribe();
        });
    }

    async getFirstPageUrl(loadRoutes = false) {
        if (loadRoutes) this.loadRoutes();

        return new Promise<string>((res, rej) => {
            this.menusLoaded.pipe(take(1)).subscribe(() => {
                const menuItemsByModule = this.objectUtils.deepCopy(this.menusByModule.value);

                let firstPageUrl: string | undefined;

                const modules = Object.keys(menuItemsByModule);

                modules.forEach(module => {
                    if (firstPageUrl) return;
                    const routes = menuItemsByModule[module];

                    if (routes.length > 0) {
                        routes.forEach(route => {
                            if (!firstPageUrl) {
                                firstPageUrl = this._findUrl(route);
                            }
                        });
                    }
                });

                if (firstPageUrl) {
                    res(firstPageUrl);
                } else {
                    rej('');
                }
            });
        });
    }

    private _findUrl(menuItem: MenuItem): string | undefined {
        if (menuItem.children && menuItem.children.length > 0) {
            return this._findUrl(menuItem.children[0]);
        } else {
            return menuItem.url ?? undefined;
        }
    }

    private setModules(menusByModule: MenusResponse) {
        const modules: Array<MenuModule> = [];
        const $state = this.upgrade.$injector ? this.upgrade.$injector.get('$state') : { href: z => z };

        const getMenuModule = (item: MenusResponseItem, module: string, translationPath: string): MenuItem => {
            let url = `${module}/${item.url}`;
            if (item.state.length > 0) {
                url = $state.href(item.state);
            }

            return {
                label: `${translationPath}.${item.label}.title`,
                url: item.isNav ? `${module}/${item.url}/nav` : url,
                icon: item.icon ?? '',
                permissions: item.permissions ?? [],
                isNav: item.isNav,
                isNg1Link: item.state.length > 0,
                notificationLookupKey: item.notificationLookupKey,
                children: item.children.map(z =>
                    getMenuModule(z, `${module}/${item.url}`, `${translationPath}.${item.label}.children`),
                ),
            };
        };

        Object.keys(menusByModule).forEach(module => {
            const menuModule: MenuModule = {
                label: `v2translations.menus.${menusByModule[module].label}.title`,
                url: menusByModule[module].url,
                icon: menusByModule[module].icon ?? '',
                children: (menusByModule[module].children ?? []).map(z =>
                    getMenuModule(z, module, `v2translations.menus.${menusByModule[module].label}.children`),
                ),
                globalAddActions: menusByModule[module].globalAddActions ?? [],
            };

            if (menusByModule[module].state?.length > 0) {
                menuModule.stateUrl = $state.href(menusByModule[module].state, menusByModule[module].stateParams ?? {});
            }

            modules.push(menuModule);
        });
        this.modules.next(modules);
    }

    private setMenus(response: MenusResponse) {
        const menusByModule: MenuItemsByModule = {};

        Object.keys(response).forEach(module => {
            menusByModule[module] =
                this.menuResponseItemToMenuItem(response[module], `v2translations.menus`).children ?? [];
        });
        this.menusByModule.next(menusByModule);
    }

    private menuResponseItemToMenuItem(item: MenusResponseItem, translationPath: string, baseUrl = ''): MenuItem {
        const clonedItem = this.objectUtils.deepCopy(item);
        const $state = this.upgrade.$injector ? this.upgrade.$injector.get('$state') : { href: z => z };
        const menuItem: MenuItem = {
            ...clonedItem,
            label: `${translationPath}.${clonedItem.label}.title`,
            isNg1Link: clonedItem.state?.length > 0,
            children: [],
            ng1ActiveRouteRegexs: clonedItem.ng1ActiveRouteRegexs ?? [],
        };

        if (menuItem.isNg1Link) {
            menuItem.url = $state.href(clonedItem.state, clonedItem.stateParams ?? {});
        } else {
            menuItem.url = menuItem.isNav
                ? `${baseUrl}/${clonedItem.url}/nav`
                : clonedItem.url === 'home'
                ? `/${clonedItem.url}`
                : `${baseUrl}/${clonedItem.url}`;
        }

        if (clonedItem.children) {
            menuItem.children = clonedItem.children.map(z =>
                this.menuResponseItemToMenuItem(
                    z,
                    `${translationPath}.${clonedItem.label}.children`,
                    `${baseUrl}/${clonedItem.url}`,
                ),
            );
        }

        return menuItem;
    }

    private updateMenus() {
        const menusByModule = this.menusByModule.value;
        if (Object.keys(menusByModule).length === 0) return;

        Object.keys(menusByModule).forEach(routeGroup => {
            this.setMenuByUrl(menusByModule[routeGroup]);
            menusByModule[routeGroup] = this.setTranslatedLabel(menusByModule[routeGroup]);
            menusByModule[routeGroup] = this._getRouteNotifications(menusByModule[routeGroup]);
        });
        const menus = this.setFavorites(menusByModule);
        this.menusByModule.next(menus);
    }

    private listenToLanguageChange() {
        this.translateService.onLangChange.subscribe(() => this.updateMenus());
    }

    private listenFavorites() {
        this.favoritesService.favorites$.subscribe(favorites => {
            this.favorites = favorites;
            this.updateMenus();
        });
    }

    private listenNotifications() {
        this.notificationService.notifications$.subscribe(notifications => {
            this.notifications.next(notifications);
            this.updateMenus();
        });
    }

    private setTranslatedLabel(menus: Array<MenuItem>): Array<MenuItem> {
        menus.forEach(menu => {
            menu.translatedLabel = this.translatePipe.transform(menu.label);
            if (menu.children) {
                this.setTranslatedLabel(menu.children);
            }
        });
        return menus;
    }

    private _getRouteNotifications(routes: Array<MenuItem>): Array<MenuItem> {
        const notifications = this.notifications.value;

        const applyNotifications = (menuRoutes: Array<MenuItem>): void => {
            menuRoutes.forEach(route => {
                // check if notification key need change
                let notificationKey = route.notificationLookupKey;
                if (notificationKey === 'email_sms') {
                    notificationKey = 'email/sms';
                }

                // update notifications if it has any value
                if (notificationKey && notifications[notificationKey] > 0) {
                    route.notifications = notifications[notificationKey];
                }

                // if it has children check on children
                if (route.children && route.children.length > 0) {
                    applyNotifications(route.children);
                }
            });
        };

        applyNotifications(routes);
        return routes;
    }

    private addFavoriteGroupRecursive(
        menuItem: MenuItem,
        labels: string[],
        parentLabels: string[] = [],
        onlyIsFavorite = false,
    ) {
        if (labels.includes(menuItem.label)) {
            if (!menuItem.favoritesGroup) {
                menuItem.favoritesGroup = [];
            }
            menuItem.isFavorite = true;
            if (!onlyIsFavorite) {
                menuItem.favoritesGroup = [...new Set(parentLabels)];
            }
        }

        const currentLabels = [...parentLabels, menuItem.translatedLabel ?? menuItem.label];
        menuItem.children.forEach(child => {
            this.addFavoriteGroupRecursive(child, labels, currentLabels, onlyIsFavorite);
        });
    }

    private setFavorites(routes: MenuItemsByModule): MenuItemsByModule {
        this.getAllMenusAsNonFavorite(routes);
        const routesAsNonFavorites = this.objectUtils.deepCopy(routes);

        Object.keys(routesAsNonFavorites).forEach(module => {
            if (module === 'core') return;
            routesAsNonFavorites[module].forEach(z => this.addFavoriteGroupRecursive(z, this.favorites, [], true));
        });

        Object.keys(routes).forEach(module => {
            if (module === 'core') return;
            const moduleName = this.translatePipe.transform(`module.${module}`);
            routes[module].forEach(z => this.addFavoriteGroupRecursive(z, this.favorites, [moduleName]));
        });

        const favoriteRoutes = this.getAllFavorites(routes);
        favoriteRoutes.forEach(route => {
            route.icon = '';
            route.favoriteLabel = route.favoritesGroup ? route.favoritesGroup.join(' / ') : '';
        });

        const orderedRoutes = favoriteRoutes.sort((a, b) =>
            (a.translatedLabel as string).localeCompare(b.translatedLabel as string),
        );

        routesAsNonFavorites['core'] = routesAsNonFavorites['core'].filter(
            z => z.label != this.translations.menus.core.children.favorites.title,
        );

        if (orderedRoutes.length > 0) {
            routesAsNonFavorites['core'].push({
                url: '',
                permissions: [],
                isNav: true,
                isNg1Link: false,
                label: this.translations.menus.core.children.favorites.title,
                icon: 'fi-rr-star',
                children: orderedRoutes,
                notificationLookupKey: null,
            });
        }

        return routesAsNonFavorites;
    }

    private getAllFavorites(menusByModules: MenuItemsByModule): MenuItem[] {
        const favorites: MenuItem[] = [];

        for (const module in menusByModules) {
            menusByModules[module].forEach(menuItem => {
                this.collectFavorites(menuItem, favorites);
            });
        }

        return favorites;
    }

    private collectFavorites(menuItem: MenuItem, favorites: MenuItem[]): void {
        if (menuItem.isFavorite) {
            favorites.push(menuItem);
        }

        menuItem.children.forEach(child => {
            this.collectFavorites(child, favorites);
        });
    }

    private setMenuByUrl(menus: Array<MenuItem>) {
        menus.forEach(menu => {
            const url = menu.url.startsWith('/') ? menu.url : `/${menu.url}`;
            this.menuByUrl[url] = menu;
            if (menu.children) {
                this.setMenuByUrl(menu.children);
            }
        });
    }

    private _searchMenuRoutes(menu: Array<MenuItem>, searchText: string, currentPath = ''): Array<MenuItem> {
        let matchingItems: Array<MenuItem> = [];

        for (const item of menu) {
            if (item.label === this.translations.menus.core.children.favorites.title) continue;
            const translatedLabel = item.translatedLabel as string;
            const itemPath = currentPath ? `${currentPath} / ${item.translatedLabel}` : item.translatedLabel;

            if (translatedLabel.toLowerCase().includes(searchText.toLowerCase())) {
                matchingItems.push({
                    isNav: item.isNav,
                    isNg1Link: item.isNg1Link,
                    children: [],
                    icon: item.icon,
                    notificationLookupKey: item.notificationLookupKey,
                    permissions: item.permissions,
                    menuSearchPath: currentPath.trim(),
                    //     should use item.label untranslated to favorites save correct data
                    label: item.label,
                    url: item.url,
                    isFavorite: item.isFavorite,
                });
            }

            if (item.children) {
                matchingItems = matchingItems.concat(this._searchMenuRoutes(item.children, searchText, itemPath));
            }
        }

        return matchingItems;
    }

    getFilteredMenuRoutes(search: string) {
        const menus = this.menusByModule.value;

        const data: Array<MenuItem> = [];
        Object.keys(menus).forEach(key => {
            this._searchMenuRoutes(menus[key], search, '').forEach(route => data.push(route));
        });

        return data;
    }

    inChild(menuItem: MenuItem): null | string {
        const menus = this.menusByModule.value;
        let menu: null | string = null;
        Object.keys(menus).forEach(key => {
            if (this.isChildMenuItem(menuItem, menus[key])) {
                menu = key;
            }
        });
        return menu;
    }

    isChildMenuItem(menuItem: MenuItem, menuItems: MenuItem[]): boolean {
        for (const item of menuItems) {
            if (item.label === menuItem.label) {
                return true;
            }
            if (item.children && this.isChildMenuItem(menuItem, item.children)) {
                return true;
            }
        }
        return false;
    }

    findMenuByUrl(url: string): MenuItem | null {
        return this.menuByUrl[url] ?? null;
    }

    getPreviousMenuFromLabel(label: string): MenuItem | MenuModule | null {
        let menu: MenuItem | MenuModule | null = null;
        const menus = this.menusByModule.value ?? {};
        Object.keys(menus).forEach(module => {
            if (!menu) {
                menu = this.findParentRoute(menus[module], label, null);
            }
        });

        if (!menu) {
            let currentMenu: MenuItem | null = null;
            let currentModule: string | null = null;
            Object.keys(menus).forEach(module => {
                if (!currentMenu) {
                    currentMenu = this.findRouteByLabel(menus[module], label);
                    if (currentMenu) {
                        currentModule = module;
                    }
                }
            });
            if (currentModule && currentMenu && !(currentMenu as MenuItem).isNav) {
                if (menus[currentModule].some(z => z.label === label)) {
                    menu = this.modules.value.find(module => module.url === currentModule) ?? null;
                }
            }
        }

        return menu;
    }

    findParentRoute(routes: Array<MenuItem>, label: string, parent: MenuItem | null = null): MenuItem | null {
        for (const route of routes) {
            if (route.label === label) {
                return parent;
            }
            if (route.children && route.children.length > 0) {
                const result = this.findParentRoute(route.children, label, route);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }

    findRouteByLabel(menus: Array<MenuItem>, label: string): MenuItem | null {
        const foundedMenu = menus.find(menuItem => menuItem.label === label);
        if (foundedMenu) {
            return foundedMenu;
        }

        for (const menuItem of menus) {
            if (menuItem.children) {
                const foundInChild = this.findRouteByLabel(menuItem.children, label);
                if (foundInChild) {
                    return foundInChild;
                }
            }
        }

        return null;
    }

    getDocumentationRoles() {
        const lang = localStorage.getItem('lang') ?? 'fr';

        return this.http
            .get<DocumentationResponse>('/api/schooldrive/legacy/v2/documentation-roles-menu', { params: { lang } })
            .pipe(map(result => result.documentations));
    }

    getAllMenusAsNonFavorite(menusByModule: MenuItemsByModule) {
        const setMenuAsNonFavorite = (menus: Array<MenuItem>) => {
            menus.forEach(menu => {
                menu.isFavorite = false;
                if (menu.children && menu.children.length > 0) {
                    setMenuAsNonFavorite(menu.children);
                }
            });
        };

        Object.keys(menusByModule).forEach(module => {
            setMenuAsNonFavorite(menusByModule[module]);
        });
    }
}
