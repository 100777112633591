<div *ngIf="attachments.length > 0" class="attachments">
    📎 {{ attachments.length }}
    {{ ( attachments.length > 1 ? translations.commons.labels.attachments : translations.commons.labels.attachment) | translate }}
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>expand_more</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor="let attachment of attachments" (click)="attachmentLinkPreview(attachment, $event)" tabindex="0">
            <mat-icon> {{ getIcon(attachment) }} </mat-icon>
            <span> {{ attachment.name }} </span>
        </button>
    </mat-menu>
</div>
