import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImdtSnackbarComponent } from '../components/imdt-snackbar/imdt-snackbar.component';
import { ImdtSnackbarType } from '../types';

@Injectable({
    providedIn: 'root',
})
export class ImdtSnackbarService {
    constructor(public snackbar: MatSnackBar) {}

    openSuccessSnackbar(title: string, message = '') {
        return this.openSnackbar(title, message, 'success');
    }

    openInfoSnackbar(title: string, message = '') {
        return this.openSnackbar(title, message, 'info');
    }

    openAlertSnackbar(title: string, message = '') {
        return this.openSnackbar(title, message, 'alert');
    }

    openWarningSnackbar(title: string, message = '') {
        return this.openSnackbar(title, message, 'warning');
    }

    private openSnackbar(title: string, message: string, type: ImdtSnackbarType) {
        return this.snackbar.openFromComponent(ImdtSnackbarComponent, {
            data: { title, message, type },
            duration: 3000,
            panelClass: ['imdt-snackbar'],
        });
    }
}
