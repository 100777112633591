import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdDateRangeSelectorWithDataPreviewComponent } from './components/sd-date-range-selector-with-data-preview/sd-date-range-selector-with-data-preview.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '@shared/angular-material.module';
import { PipesModule } from '@shared/pipes.module';

@NgModule({
    declarations: [SdDateRangeSelectorWithDataPreviewComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, AngularMaterialModule, PipesModule],
    exports: [SdDateRangeSelectorWithDataPreviewComponent],
})
export class SdDateRangeSelectorWithDataPreviewModule {}
