import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineModule } from './modules/details/modules/timeline/timeline.module';
import { PipesModule } from '@shared/pipes.module';
import { AngularMaterialModule } from '@shared/angular-material.module';

@NgModule({
    declarations: [],
    imports: [PipesModule, AngularMaterialModule, TimelineModule, CommonModule],
})
export class ContactsModule {}
