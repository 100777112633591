import { Component, DestroyRef, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NewComponent } from '../new/new.component';
import { MenuItem, MenuModule, MenuService } from '../../../../services/menu.service';
import { Router } from '@angular/router';
import { ResponsiveNavigationService } from '../../services/responsive-navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GlobalAddService } from '../../../global-add/services/global-add.service';

@Component({
    selector: 'app-responsive-navigation-footer',
    standalone: false,
    templateUrl: './responsive-navigation-footer.component.html',
    styleUrl: './responsive-navigation-footer.component.scss',
})
export class ResponsiveNavigationFooterComponent implements OnInit {
    newButton = false;
    onlyOnceModule = false;
    currentMenuModule: MenuModule | null = null;
    modules: Array<MenuModule> = [];

    constructor(
        protected _bottomSheet: MatBottomSheet,
        public rns: ResponsiveNavigationService,
        public router: Router,
        public destroyRef: DestroyRef,
        public globalAddService: GlobalAddService,
        public menuService: MenuService,
    ) {}

    ngOnInit() {
        this.globalAddService.actions$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(actions => {
            this.newButton = actions && actions.length > 0;
        });

        const menusByModule = this.menuService.menusByModule.value;
        this.onlyOnceModule =
            Object.keys(menusByModule).filter(z => z !== 'main' && menusByModule[z].length > 0).length === 1;

        this.rns.currentModule$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(currentModule => {
            this.currentMenuModule = currentModule;

            this.modules = [];
            if (!this.currentMenuModule && this.router.url === '/home') {
                const modules = this.menuService.modules.value;
                this.modules = modules.filter(z => z.url.length > 0 && z.children.length > 0);
            }
        });
    }

    new() {
        this._bottomSheet.open(NewComponent);
    }

    getIcon(route: MenuItem | MenuModule) {
        const pathname = window.location.pathname;
        if (this.currentMenuModule?.url === 'sd' && pathname.includes('sd1')) {
            const hasMatchingChildUrl = (item: MenuItem) => {
                if (!item.children) return false;
                for (const child of item.children) {
                    if (child.url === pathname || hasMatchingChildUrl(child)) {
                        return true;
                    }
                }
                return false;
            };

            return hasMatchingChildUrl(route as MenuItem) ? route.icon?.replace('rr', 'ss') : route.icon;
        }

        const currentRoute =
            this.router.url.includes(route.url) || this.router.url.includes(route.url.split('/nav').join(''));

        return currentRoute ? route.icon?.replace('rr', 'ss') : route.icon;
    }

    isHome() {
        let url = this.router.url;
        if (url === '' || url === '/') {
            url = window.location.pathname;
        }
        return url === '/home';
    }
}
