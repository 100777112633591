import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { BehaviorSubject, catchError, map, throwError } from 'rxjs';
import { TagItem, TagsResponse } from '../types';

@Injectable({
    providedIn: 'root',
})
export class TagsService {
    public tags = new BehaviorSubject<TagItem[]>([]);
    public tags$ = this.tags.asObservable();

    constructor(protected apollo: Apollo) {}

    getTags() {
        const getTags = `
            query getTags {
                results: CORE_EnumTag {
                    id
                    descriptiveId
                    textTranslationKey
                    foregroundColorHex
                    backgroundColorHex
                }
            }`;

        return this.apollo
            .watchQuery<TagsResponse>({
                query: gql(getTags),
            })
            .valueChanges.pipe(
                map(result => {
                    const tags = result.data.results ?? [];
                    this.tags.next(tags);
                    return tags;
                }),
                catchError(error => throwError(() => new Error(error))),
            );
    }
}
