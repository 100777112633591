import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { GSearchService } from '../../services/g-search.service';
import {
    GSearchDataSearchStates,
    GSearchDataSearchUpstreamSearchTerms,
    GSearchGetUpstreamsResponseItem,
} from '../../types';
import { Translations } from '@translation-keys';
import { Subscription } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-data-search',
    templateUrl: './data-search.component.html',
    styleUrl: './data-search.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSearchComponent implements OnInit, OnChanges {
    @Input({ required: true }) search!: string;
    @Output() readonly stateChange = new EventEmitter<GSearchDataSearchStates>();

    translations = Translations;
    availableStates = GSearchDataSearchStates;

    state: GSearchDataSearchStates = GSearchDataSearchStates.LOADING;
    upstreamList: GSearchGetUpstreamsResponseItem[] = [];
    upstreamNoDataFoundIds: Array<number> = [];

    subscriptionsRef: Array<Subscription> = [];
    isProcessing = false;
    menuCount = 0;
    loadingUpstreams: GSearchGetUpstreamsResponseItem[] = [];

    constructor(
        protected gSearchService: GSearchService,
        protected changeDetector: ChangeDetectorRef,
        protected destroyRef: DestroyRef,
    ) {}

    ngOnInit() {
        this.getDataSubscribe();
    }

    getDataSubscribe() {
        this.loadingUpstreams = [];
        this.isProcessing = true;
        this.state = GSearchDataSearchStates.LOADING;
        this.changeDetector.detectChanges();
        const ref = this.gSearchService
            .getUpstreams()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: upstreams => {
                    this.isProcessing = false;
                    this.upstreamList = upstreams;
                    this.state =
                        upstreams.length > 0
                            ? GSearchDataSearchStates.READY_WITH_RESULTS
                            : GSearchDataSearchStates.READY_WITHOUT_RESULTS;

                    this.loadingUpstreams = this.upstreamList;
                    this.stateChange.emit(this.state);
                    this.changeDetector.detectChanges();
                },
                error: () => {
                    this.state = GSearchDataSearchStates.ERROR;
                    this.stateChange.emit(GSearchDataSearchStates.ERROR);
                    this.changeDetector.detectChanges();
                },
            });
        this.subscriptionsRef.push(ref);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes['search'].isFirstChange() && this.isProcessing) {
            this.subscriptionsRef.forEach(subscription => subscription.unsubscribe());
            this.getDataSubscribe();
        }
    }

    getSearchTerms(upstreamId: number): GSearchDataSearchUpstreamSearchTerms {
        const upstream = this.upstreamList.find(upstream => upstream.id === upstreamId);
        return {
            upstreamId,
            searchValue: this.search,
            upstream,
        };
    }

    upstreamStateChange(id: number, $event: GSearchDataSearchStates) {
        const upstream = this.upstreamList.find(z => z.id === id);
        if (!upstream) return;
        if ($event === 'loading') {
            this.loadingUpstreams.push({ ...upstream });
            this.loadingUpstreams = [...new Set(this.loadingUpstreams)];
        } else {
            this.loadingUpstreams = this.loadingUpstreams.filter(z => z.id !== id);
        }
        if ($event === 'readyWithoutResults') {
            this.upstreamNoDataFoundIds.push(id);
            this.upstreamNoDataFoundIds = [...new Set(this.upstreamNoDataFoundIds)];
        } else {
            this.upstreamNoDataFoundIds = this.upstreamNoDataFoundIds.filter(z => z !== id);
        }
        this.changeDetector.detectChanges();
    }

    onQtdMenuFoundedChange($event: number) {
        this.menuCount = $event;
        this.changeDetector.detectChanges();
    }
}
