import { SdTableRowDto } from './sd-table.types';
import { Component } from '@angular/core';

export interface SdTableColumnDto {
    type: SdTableColumnTypeEnum;
    defaultVisible: boolean;
    sortable: boolean;
    static: SdTableColumnStaticEnum;
    responsiveStatic: SdTableColumnStaticEnum | null;
    group: string | null;
    class: string | null;
    label: string | null;
    translateValues: boolean | null;
    noWrapRows: boolean | null;
    noWrapHeader: boolean | null;
}

export interface SdTableColumnAction {
    column: string;
    actions: SdTableColumnActions[];
}

export interface SdTableAllColumns {
    [name: string]: SdTableColumnDto;
}

export interface SdTableColumnActions {
    type: SdTableActionColumnType;
    color?: string;
    tooltip?: string;
    text?: string;
    icon?: string;
    permission?: string;

    url?: string;
    target?: string;
    link?: (data: SdTableRowDto | null) => string;
    label?: (data: SdTableRowDto) => string;
    callback?: (data: SdTableRowDto | SdTableRowDto[]) => void;
    component?: Component;
    componentData?: SdTableRowDto;
}

export type SdTableActionColumnType = 'button' | 'popup' | 'link' | 'text' | 'text-link' | 'visual-icon';

export enum SdTableColumnTypeEnum {
    /** render on client */
    SELECT = 'select',
    CLIENT_RENDER = 'client-render',

    /** dates */
    DATE = 'date',
    DATETIME = 'datetime',
    UTC_DATE = 'utc-date',

    /** string */
    STRING = 'string',
    HTML = 'html',

    /** boolean */
    BOOLEAN = 'boolean',

    /** number */
    NUMBER = 'number',
    CURRENCY = 'currency',
}

export enum SdTableColumnStaticEnum {
    NO = 'no',
    BEGIN = 'begin',
    END = 'end',
}
