<div class="mat-elevation-z5 hard-load" *ngIf="visibleColumns.length === 0 && isLoadingResults">
    <mat-spinner [diameter]="30"></mat-spinner>
</div>
<div class="mat-elevation-z5 sd-table" [id]="titleAnchor" [class.card-table]="effectiveLayout === 'card'" [class.not-card-table]="effectiveLayout !== 'card'" *ngIf="visibleColumns.length > 0 || !isLoadingResults">

    <div class="loading-shade" *ngIf="isLoadingResults">
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>

    <!-- Header -->
    <div class="header">
        <h1 *ngIf="tableTitle" (click)="scrollToTable()" (keydown)="scrollToTable()" tabindex="0">
            {{ tableTitle | translate }}
        </h1>
        <div class="header-actions" *ngIf="!disableHeaderActions">
            <div class="start">
                <mat-icon (click)="request(true)" color="primary" fontIcon="refresh"></mat-icon>
                <div class="custom-actions">
                    <sd-table-custom-actions
                        [actions]="customActions"
                    ></sd-table-custom-actions>
                </div>
            </div>

            <mat-icon class="end icon settings-icon" fontIcon="tune"></mat-icon>
            <div class="end settings">
                <button
                    mat-icon-button
                    matTooltip="{{ 'common.reset-filters' | translate}}"
                    matTooltipPosition="below"
                    class="light-grey"
                    (click)="resetFilters()">
                    <mat-icon class="icon" fontIcon="cancel"></mat-icon>
                </button>
                
                <div class="custom-filters">
                    <sd-table-custom-filters
                        #customFilterComponent
                        [filters]="customFilters"
                        (customFilterChange)="onCustomFilterChange($event)"
                    ></sd-table-custom-filters>
                </div>
    
                <div class="search-and-select-columns">
                    <sd-select
                        [(ngModel)]="selectedColumns"
                        [data]="selectColumnsOptions"
                        [label]="'common.columns' | translate"
                        [multiple]="true"
                        [hasReload]="false"
                        (selectedObjectsChange)="columnsChange($event)"
                    ></sd-select>

                    <sd-input-with-options
                        [label]="'common.search' | translate"
                        [options]="selectFilterColumnsOptions"
                        [(ngModel)]="inputWithOptionsValue"
                        (ngModelChange)="searchWithOptionsChange($event)"
                    ></sd-input-with-options>
                </div>
            </div>
        </div>
    </div>

    <div class="table-section" #tableContainer>

            <div class="no-data-found" *ngIf="data.length === 0">
                {{ 'sd-table.no-data-found' | translate }}
            </div>
            
            <table
                *ngIf="data.length > 0"
                #table
                mat-table
                [dataSource]="data"
                [ngClass]="{ 'full-height': fullHeight }"
                matSort="sort" 
                (matSortChange)="sortChange($event)" 
                multiTemplateDataRows 
                cdkDropList 
                cdkDropListOrientation="horizontal" 
                (cdkDropListDropped)="columnDropped($event)">

                <ng-container [matColumnDef]="column" *ngFor="let column of visibleColumns; let first = first; let i = index;" [sticky]="isStickedOnBegin(column)" [stickyEnd]="isStickedOnEnd(column)">
                    <th
                        cdkDrag
                        cdkDragLockAxis="x"
                        [cdkDragDisabled]="disableColumnDragAndDrop(column)"
                        mat-header-cell
                        [ngClass]="{'select': column === 'select', 'no-wrap': isNoWrapHeader(column), 'th-is-card': effectiveLayout === 'card' }"
                        class="{{ typedColumns[column]?.class ?? '' }}"
                        *matHeaderCellDef mat-sort-header
                        [disabled]="disableColumnSort(column)">
                        <mat-checkbox
                            *ngIf="column === 'select'"
                            color="primary"
                            [ngClass]="{'is-expand-select': expandComponent && first}"
                            (change)="$event ? toggleAllRows() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            [aria-label]="checkboxLabel()"
                        ></mat-checkbox>
                        <span *ngIf="column !== 'select'" [ngClass]="{'is-expand-select': expandComponent && first, 'can-move': !disableColumnDragAndDrop(column)}">
                            {{ visibleColumnLabel[column] }}
                        </span>
                    </th>
                    <td 
                        class="{{ typedColumns[column]?.class ?? '' }}"
                        [attr.data-label]="visibleColumnLabel[column]"
                        mat-cell
                        *matCellDef="let element"
                        [ngClass]="{'select': column === 'select', 'is-expand-column': first && expandComponent, 'no-wrap': isNoWrapRow(column)}">
                        <button *ngIf="first && expandComponent" mat-icon-button aria-label="expand row" (click)="expandRow($event, element)">
                            <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                            <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                        </button>
                        
                        <mat-checkbox
                            *ngIf="column === 'select'"
                            color="primary"
                            (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(element) : null"
                            [checked]="selection.isSelected(element)"
                            [aria-label]="checkboxLabel(element)">
                        </mat-checkbox>
                        <span *ngIf="column !== 'select'">
                            <sd-table-column
                                [index]="i"
                                [column]="typedColumns[column]"
                                [data]="element[column]"
                                [rowData]="element"
                                [columnName]="column"
                                [clientColumns]="clientColumns"
                            ></sd-table-column>
                        </span>
                    </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="visibleColumns; sticky: true;"></tr>
                <tr
                    mat-row
                    class="element-row"
                    *matRowDef="let element; columns: visibleColumns;"
                    [class.expanded-row]="expandedElement === element"
                    [class.even-row]="isEvenRow(element.id)"
                    (click)="toggleRow(element, selection)"
                ></tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row" [hidden]="expandComponent"></tr>
    
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" [attr.colspan]="visibleColumns.length"> {{ 'sd-table.no-data-found' | translate }} </td>
                </tr>

                <!-- expand -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="visibleColumns.length">
                    <div class="element-detail"
                        [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                            <div class="diagram" *ngIf="expandComponent && element === expandedElement">
                                <div #expandContent></div>
                            </div>
                    </div>
                    </td>
                </ng-container>

            </table>
    </div>

    <div class="table-footer">
        <!-- Batch actions -->
        <div class="batch-actions-container" #batchActionsContainer>
            <div class="batch-actions" *ngIf="batchActions.length > 0 && selection.selected.length > 0">
                <mat-icon class="clear-selected" (click)="clearSelection()" fontIcon="cancel"></mat-icon>
                <div class="batch-action-selected">
                <span>
                    {{ 'sd-table.batch-actions' | translate }} (<span aria-hidden="true" (click)="displaySelected()">{{ selection.selected.length }} {{ 'common.selected' | translate }}</span>):
                </span>
                </div>
                <div class="icons" *ngFor="let action of batchActions">
                    <mat-icon *ngIf="action.type === 'visual-icon' && action.icon" class="visual-icon" [fontIcon]="action.icon"></mat-icon>
                    <mat-icon
                            *ngIf="action.type === 'button'"
                            class="action"
                            mat-raised-button
                            [matTooltip]="action.tooltip ?? ''"
                            [color]="action.color"
                            (click)="action.callbackBatchActions(selection.selected)"
                            [fontIcon]="action.icon ?? ''"
                    ></mat-icon>
                </div>
            </div>
        </div>

        <!-- Paginator -->
        <mat-paginator
                *ngIf="paginate && dataLength > 50"
                [pageSizeOptions]="[50, 100, 200, 300]"
                [length]="dataLength"
                (page)="pageChange($event)"
                showFirstLastButtons
        ></mat-paginator>
    </div>
</div>
