import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'dateFormatter',
})
export class DateFormatterPipe extends DatePipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    override transform(value: Date | string | number | null | undefined): any {
        if (typeof value === 'string') {
            return super.transform(new Date(value), 'dd.MM.yyyy');
        }
        return value;
    }
}
