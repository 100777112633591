import { Injectable, TemplateRef } from '@angular/core';
import { ResponsiveNavigationFiltersService } from './responsive-navigation-filters.service';
import { BehaviorSubject } from 'rxjs';
import { ResponsiveNavigationHeaderSettings } from '../types/responsive-navigation-header-settings.type';

@Injectable({
    providedIn: 'root',
})
export class ResponsiveNavigationHeaderService {
    private settings: BehaviorSubject<ResponsiveNavigationHeaderSettings> =
        new BehaviorSubject<ResponsiveNavigationHeaderSettings>({
            filterTemplate: undefined,
            filterService: undefined,
            hasFilters: false,
            hasSearch: false,
        });
    settings$ = this.settings.asObservable();

    private searchValue: BehaviorSubject<string> = new BehaviorSubject<string>('');
    searchValue$ = this.searchValue.asObservable();

    reset() {
        this.settings.next({
            filterTemplate: undefined,
            filterService: undefined,
            hasFilters: false,
            hasSearch: false,
        });
    }

    setHasSearch(status = true) {
        const settings = this.settings.value;
        settings.hasSearch = status;
        this.settings.next(settings);
    }

    setHasFilters(status = true) {
        const settings = this.settings.value;
        settings.hasFilters = status;
        this.settings.next(settings);
    }

    setMobileFilterService(service: ResponsiveNavigationFiltersService | undefined) {
        const settings = this.settings.value;
        settings.filterService = service;
        this.settings.next(settings);
    }

    setFiltersTemplate(template: TemplateRef<unknown>) {
        const settings = this.settings.value;
        settings.filterTemplate = template;
        this.settings.next(settings);
    }

    onSearchChange(value: string) {
        this.searchValue.next(value);
    }
}
