import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SdTableResponseDto } from '../types';

export interface QueryParam {
    id: string;
    value: string;
}

@Injectable({
    providedIn: 'root',
})
export class SdTableService {
    constructor(@Inject(HttpClient) private _httpService: HttpClient) {}

    public getData(endpoint: string, queryParamsList: QueryParam[] = []): Observable<SdTableResponseDto> {
        const headers = new HttpHeaders().set('Content-type', 'application/json');

        let params = new HttpParams();
        for (const queryParam of queryParamsList) {
            params = params.append(queryParam.id, queryParam.value);
        }

        return this._httpService
            .get(`${endpoint}`, {
                params,
                headers,
                responseType: 'text',
            })
            .pipe(map(res => JSON.parse(res)));
    }
}
