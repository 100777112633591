import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Ng1LinkComponent } from './components/ng1-link/ng1-link.component';

/**
 * This module provides the ability for Angular (new) to call AngularJs (old) links
 */
@NgModule({
    declarations: [Ng1LinkComponent],
    imports: [CommonModule, RouterModule],
    exports: [Ng1LinkComponent],
})
export class Ng1LinkModule {}
