import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CookieService {
    public get(name: string) {
        const cookies: Array<string> = document.cookie.split(';');
        const cookiesLength: number = cookies.length;
        const cookieName = `${name}=`;
        let c: string;

        for (let i = 0; i < cookiesLength; i += 1) {
            c = cookies[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) == 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }

    public delete(name) {
        this.set(name, '', -1);
    }

    public set(name: string, value: string, expireDays: number, path = '') {
        const d: Date = new Date();
        d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
        const expires = `expires=${d.toUTCString()}`;
        const cpath: string = path ? `; path=${path}` : '';
        document.cookie = `${name}=${value}; ${expires}${cpath}`;
    }
}
