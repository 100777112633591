import { Component, TemplateRef, ViewChild } from '@angular/core';
import { UserAsideMenuComponent } from '../../../layout/modules/user-aside-menu/components/user-aside-menu/user-aside-menu.component';

@Component({
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrl: './user-settings.component.scss',
})
export class UserSettingsComponent {
    @ViewChild('template') template!: TemplateRef<unknown>;

    @ViewChild(UserAsideMenuComponent) userAsideMenuComponent!: UserAsideMenuComponent;

    logout() {
        this.userAsideMenuComponent.logout();
    }
}
