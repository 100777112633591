<div class="header">
    New
    <i class="fi fi-rr-cross responsive-navigation-footer-close-new" (click)="close()" (keydown)="close()" aria-hidden="true"></i>
</div>

<div class="items">

    @for(item of globalAddActions; track item) {
        <div class="item">{{ item.label }}</div>
    }

</div>
