import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AclService {
    static parsedPermissionsString: string;
    static parsedPermissionsObject: ReturnType<typeof JSON.parse>;
    private static getParsedPermissions(stringToParse: string): ReturnType<typeof JSON.parse> {
        if (stringToParse !== AclService.parsedPermissionsString) {
            AclService.parsedPermissionsObject = JSON.parse(stringToParse);
            AclService.parsedPermissionsString = stringToParse;
        }
        return AclService.parsedPermissionsObject;
    }

    can(permission: string): boolean {
        return this._checkPermissions([permission], 'some');
    }

    canAll(permissions: Array<string>): boolean {
        return this._checkPermissions(permissions, 'every');
    }

    canAny(permissions: Array<string>): boolean {
        return this._checkPermissions(permissions, 'some');
    }

    private _checkPermissions(permissions: Array<string>, method: 'some' | 'every'): boolean {
        let storagePermissions: string | null | Array<string> = this._getPermissions();
        if (!storagePermissions) {
            return false;
        }

        try {
            storagePermissions = AclService.getParsedPermissions(storagePermissions) as Array<string>;
            return permissions[method](permission => (storagePermissions as Array<string>).includes(permission));
        } catch (error) {
            return false;
        }
    }

    private _getPermissions(): string | null {
        return sessionStorage.getItem('permissions') ?? localStorage.getItem('permissions');
    }
}
