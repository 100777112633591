import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { GSearchDataSearchStates, GSearchDataSearchUpstreamSearchTerms } from '../../types';
import { GSearchService } from '../../services/g-search.service';
import { GSearchDataSearchGetSearchResult } from '../../types/g-search-data-search.types';
import { Translations } from '@translation-keys';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-data-search-upstream',
    templateUrl: './data-search-upstream.component.html',
    styleUrl: './data-search-upstream.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSearchUpstreamComponent implements OnInit, OnChanges, OnDestroy {
    /**
     * Header name
     */
    @Input() upstreamName = '';
    /**
     * Contains the upstreamId and searchValue to filter search
     */
    @Input({ required: true }) searchTerms!: GSearchDataSearchUpstreamSearchTerms;
    @Output() readonly stateChanges = new EventEmitter<GSearchDataSearchStates>();

    translations = Translations;

    /**
     * Count of total items
     */
    count = 0;
    data: GSearchDataSearchGetSearchResult[] = [];
    availableStates = GSearchDataSearchStates;
    state: GSearchDataSearchStates = GSearchDataSearchStates.LOADING;
    isProcessing = false;
    subscriptionsRef: Array<Subscription> = [];

    constructor(protected gSearchService: GSearchService, private changeDetector: ChangeDetectorRef) {}

    ngOnInit() {
        this.search();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['searchTerms'].firstChange) return;
        const currentValue = changes['searchTerms'].currentValue;
        const previousValue = changes['searchTerms'].previousValue;
        if (
            currentValue['upstreamId'] != previousValue['upstreamId'] ||
            currentValue['searchValue'] != previousValue['searchValue']
        ) {
            this.subscriptionsRef.forEach(subscription => subscription.unsubscribe());
            this.search();
        }
    }

    ngOnDestroy() {
        this.subscriptionsRef.forEach(subscription => subscription.unsubscribe());
    }

    search(limitItems = 10) {
        this.isProcessing = true;
        this.stateChanges.emit(GSearchDataSearchStates.LOADING);
        const ref = this.gSearchService.getSearchResult(this.searchTerms, limitItems).subscribe({
            next: response => {
                this.isProcessing = false;
                this.count = response.countResult.aggregate.count;
                this.data = response.results;
                if (this.count === 0) {
                    this.state = GSearchDataSearchStates.READY_WITHOUT_RESULTS;
                } else {
                    this.state = GSearchDataSearchStates.READY_WITH_RESULTS;
                }
                this.stateChanges.emit(this.state);
                this.changeDetector.detectChanges();
            },
            error: () => {
                this.isProcessing = false;
                this.state = GSearchDataSearchStates.ERROR;
                this.stateChanges.emit(this.state);
                this.changeDetector.detectChanges();
            },
        });
        this.subscriptionsRef.push(ref);
    }
}
