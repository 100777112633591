import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SdDialogMessageService } from '../modules/shared/modules/sd-dialog-message/services/sd-dialog-message.service';
import { AuthStorageService } from '@auth/services/auth-storage.service';
import { ImdtSnackbarService } from '../modules/shared/modules/imdt-snackbar/services/imdt-snackbar.service';
import { Translations } from '@translation-keys';

@Injectable()
export class ApiInterceptorModule implements HttpInterceptor {
    constructor(
        public dialog: SdDialogMessageService,
        public router: Router,
        public authStorageService: AuthStorageService,
        public imdtSnackbarService: ImdtSnackbarService,
    ) {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // We don't want to intercept assets requests
        if (req.url.startsWith('/assets')) {
            return next.handle(req);
        }

        // Fill the headers object
        const optionalHeaders = {
            headers: req.headers,
        };

        const xsrfToken = this.getCookie('XSRF-TOKEN');
        if (xsrfToken) {
            optionalHeaders.headers = optionalHeaders.headers.set('X-CSRF-Token', xsrfToken);
        }

        const currentLanguage = this.authStorageService.getItem('lang');
        if (currentLanguage) {
            optionalHeaders.headers = optionalHeaders.headers.set('X-Tab-Language', currentLanguage);
        }

        const authorization = this.authStorageService.getItem('token');
        if (authorization) {
            optionalHeaders.headers = optionalHeaders.headers.set('Authorization', 'Bearer ' + authorization);
        }

        if (window['spaSessionId']) {
            optionalHeaders.headers = optionalHeaders.headers.set('X-Spa-Session-Id', window['spaSessionId']);
        }

        // we only want to intercept graphql with dynamic headers (using factory it only runs once time when load page)
        if (req.url.indexOf('/operational-graphql') >= 0) {
            const apiReq = req.clone({
                ...optionalHeaders,
                url: req.url,
                withCredentials: true,
            });
            return next.handle(apiReq);
        }

        const url = req.url;

        const apiReq = req.clone({
            ...optionalHeaders,
            url,
            withCredentials: true,
        });
        return next.handle(apiReq).pipe(
            catchError((error: HttpErrorResponse) => {
                let errorMsg = '';
                if (error.error instanceof ErrorEvent) {
                    /* This is client side error */
                    errorMsg = `${error.error.message}`;
                } else {
                    /* This is server side error */
                    try {
                        const errorDetails = JSON.parse(error.error);
                        errorMsg = errorDetails?.message;
                    } catch (e) {
                        errorMsg = 'Error on catch ' + JSON.stringify(error);
                    }
                }

                if (
                    error.status === 401 &&
                    (!error.url?.includes('sign-in') || !error.url?.includes('sign-in/manually'))
                ) {
                    this.router.navigate(['/sign-in']).then();
                    return throwError(() => new Error(error.message));
                }

                if (error.status === 500) {
                    this.imdtSnackbarService.openAlertSnackbar(
                        Translations.core.modules.auth.pages.signInManually.validations.invalidCredentials,
                        errorMsg,
                    );
                    return throwError(() => new Error(errorMsg));
                } else {
                    return throwError(() => JSON.parse(error.error));
                }
            }),
        );
    }

    private getCookie(cname: string): string {
        /* https://pt.stackoverflow.com/questions/385621/recuperar-cookie-com-typescript */
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');

        for (const item of ca) {
            let c = item;
            while (c.startsWith(' ')) {
                c = c.substring(1);
            }
            if (c.startsWith(name)) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
}
