import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { GSearchDataSearchGetSearchResult } from '../../types/g-search-data-search.types';
import { GSearchService } from '../../services/g-search.service';
import { TagsService } from '../../../core/modules/tags/services/tags.service';
import { TagItem } from '../../../core/modules/tags/types/tags.types';

@Component({
    selector: 'app-data-search-result-item',
    templateUrl: './data-search-result-item.component.html',
    styleUrl: './data-search-result-item.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSearchResultItemComponent implements OnInit {
    @Input({ required: true }) item!: GSearchDataSearchGetSearchResult;

    tags: TagItem[] = [];

    constructor(
        protected gSearchService: GSearchService,
        protected tenantSettingsService: TagsService,
        protected cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        if (this.item.tagsJson !== null) {
            const tagDescriptiveIdList = JSON.parse(this.item.tagsJson) as string[];
            this.tags = [];
            tagDescriptiveIdList.forEach(tagDescriptiveId => {
                const tag = this.tenantSettingsService.tags.value.find(tag => tag.descriptiveId === tagDescriptiveId);
                if (!tag) return;
                this.tags.push(tag);
            });
            this.cdr.detectChanges();
        }
    }

    closeSearch() {
        this.gSearchService.close();
    }
}
