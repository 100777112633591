import { Component, EventEmitter, Inject, Output, TemplateRef } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Translations } from '@translation-keys';
import { ResponsiveNavigationFiltersService } from '../../services/responsive-navigation-filters.service';

@Component({
    selector: 'app-page-filters',
    standalone: false,
    templateUrl: './page-filters.component.html',
    styleUrl: './page-filters.component.scss',
})
export class PageFiltersComponent {
    @Output() applyFilters: EventEmitter<void> = new EventEmitter<void>();
    template!: TemplateRef<unknown>;
    usingFilterTemplate = false;
    filterTitle = '';

    protected readonly translations = Translations;
    protected readonly Array = Array;

    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA)
        public data: {
            template: TemplateRef<unknown>;
            mobileFilterService: ResponsiveNavigationFiltersService | undefined;
        },
        private bottomSheetRef: MatBottomSheetRef<PageFiltersComponent>,
    ) {
        this.template = data.template;
    }

    filterBottomSheet(template: TemplateRef<unknown>, filterTitle: string) {
        this.template = template;
        this.bottomSheetRef.disableClose = true;
        this.filterTitle = filterTitle;
        this.usingFilterTemplate = true;
    }

    clearFilters() {
        if (this.data.mobileFilterService) {
            this.data.mobileFilterService.clearFilter();
        }
    }

    close() {
        this.bottomSheetRef.dismiss();
    }

    back() {
        this.template = this.data.template;
        this.bottomSheetRef.disableClose = false;
        this.filterTitle = '';
        this.usingFilterTemplate = false;
    }

    apply() {
        if (this.data.mobileFilterService) {
            this.data.mobileFilterService.apply();
        }
        this.bottomSheetRef.dismiss();
    }
}
