import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { SdSelectOption } from '../types/sd-select.types';

@Injectable({
    providedIn: 'root',
})
export class SelectService {
    constructor(@Inject(HttpClient) private _httpService: HttpClient) {}

    public getData(endpoint: string): Observable<SdSelectOption[]> {
        const headers = new HttpHeaders().set('Content-type', 'application/json');
        return this._httpService
            .get(`${endpoint}`, {
                headers,
                responseType: 'text',
            })
            .pipe(map(res => JSON.parse(res)));
    }
}
