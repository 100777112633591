import { Component, OnInit } from '@angular/core';
import { TagsService } from '../../services/tags.service';

@Component({
    selector: 'app-tags',
    standalone: true,
    imports: [],
    templateUrl: './tags.component.html',
    styleUrl: './tags.component.scss',
})
export class TagsComponent implements OnInit {
    constructor(protected tagsService: TagsService) {}

    ngOnInit() {
        this.tagsService.getTags().subscribe(tags => {
            const generatedStyle = document.createElement('style');

            let generatedClasses =
                '.tag { border-radius: 10px; padding: 5px; } .tag.small { font-size: 11px; font-weight: 600; }';
            tags.forEach(tag => {
                generatedClasses += `.tag.${tag.descriptiveId} { color: ${tag.foregroundColorHex}; background: ${tag.backgroundColorHex}; }`;
            });
            generatedStyle.innerHTML = generatedClasses;
            document.getElementsByTagName('head')[0].appendChild(generatedStyle);
        });
    }
}
