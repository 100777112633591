@if (isSimplified) {
    <div class="home-user">
        <div class="name">
            Hello, {{ currentSession.fullName }}!
        </div>
        <div class="actions">
            <i class="fi fi-sr-eye"></i>
            <i class="fi fi-sr-building" [matMenuTriggerFor]="organizationMenuMobile"></i>
            <mat-menu class="menu-mobile-dropdown" #organizationMenuMobile="matMenu" xPosition="before">
                <button type="button" mat-menu-item>
                    {{ 'ESSR' | translate }}
                </button>
            </mat-menu>

            <i class="fi fi-sr-user" [matMenuTriggerFor]="profileMenuMobile"></i>
            <mat-menu class="menu-mobile-dropdown" #profileMenuMobile="matMenu" xPosition="before">
                <ng1-link [url]="getStateAsUrl('app.profile')">
                    <button type="button" mat-menu-item>
                        <i class="ri-user-3-line"></i> Profile
                    </button>
                </ng1-link>
                <a mat-menu-item routerLink="/account">
                    <i class="ri-settings-3-line"></i> Settings
                </a>
                <a mat-menu-item aria-hidden="true" (click)="userSettingsComponent.logout()">
                    <i class="ri-shut-down-line"></i> Logout
                </a>
            </mat-menu>

        </div>
    </div>
}

<header
    class="header-area bg-white border-radius mb-25"
    [ngClass]="{'active': isToggled, 'sticky': true}"
>
    <div class="d-md-flex header-row align-items-center justify-content-space-between">
        <div class="header-left-side d-flex align-items-center">
            <div class="burger-menu" (click)="toggle()" aria-hidden="true">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </div>

            <div class="backdrop" *ngIf="shouldDisplayMenu" (click)="closeMenu()" aria-hidden="true"></div>

            @if ((currentModule || isHome()) && isSimplified) {
                <div class="module" (click)="openModules()" aria-hidden="true">
                    <i class="fi fi-rr-menu-burger"></i>
                </div>
            }

            <form class="search-box position-relative" autocomplete="off">
                <input type="text" id="searchHeader" class="input-search d-block" [placeholder]="placeholder | translate" [(ngModel)]="search" [ngModelOptions]="{standalone: true}" (ngModelChange)="searchChange($event)" (click)="searchClick()" #searchInput>
                <button type="submit" id="searchHeaderButton"><mat-icon id="searchHeaderIcon" [fontIcon]="'search'" /></button>
            </form>
        </div>

        <ul class="header-right-side d-flex align-items-center mt-0 mb-0">
            <li>
                <button mat-icon-button [matMenuTriggerFor]="notificationsMenu" class="btn p-0 border-none rounded-circle position-relative notification">
                    <i class="fi fi-rr-bell"></i>
                    @if (!notificationsIsEmpty) {
                        <span class="notification-badge"></span>
                    }
                </button>
                <mat-menu class="notifications-header-menu-dropdown" #notificationsMenu="matMenu" xPosition="after">
                    <app-notifications (notificationsIsEmpty)="onNotificationsEmpty($event)"></app-notifications>
                </mat-menu>
            </li>
            @if (documentations.length > 1) {
                <li>
                    <button mat-icon-button [matMenuTriggerFor]="documentationMenu" class="btn p-0 border-none rounded-circle position-relative">
                        <i class="fi fi-rr-interrogation"></i>
                    </button>
                    <mat-menu #documentationMenu="matMenu">
                        @for (documentation of documentations; track documentation.state) {
                            <ng1-link [url]="getStateAsUrl(documentation.state, true)">
                                <button mat-menu-item type="button">
                                    {{ documentation.name }}
                                </button>
                            </ng1-link>
                        }
                    </mat-menu>
                </li>
            }
            @if (documentations.length === 1) {
                <li>
                    <ng1-link [url]="getStateAsUrl(documentations[0].state)">
                        <button mat-icon-button class="btn p-0 border-none rounded-circle position-relative">
                            <i class="fi fi-rr-interrogation"></i>
                        </button>
                    </ng1-link>
                </li>
            }

            <li>
                <button mat-icon-button [matMenuTriggerFor]="languageMenu" class="btn p-0 border-none rounded-circle position-relative">
                    @if (lang === 'dev') {
                        <i class="fi fi-rr-laptop-code dev-icon"></i>
                    } @else {
                        <img [src]="'/assets/images/flags/' + lang + '.png'" alt="lang">
                    }
                </button>
                <mat-menu #languageMenu="matMenu" xPosition="after">
                    @for(lang of languages; track lang) {
                        <button mat-menu-item type="button" (click)="changeLanguage(lang.id)">
                            @if (lang.id === 'dev') {
                                <i class="fi fi-rr-laptop-code dev-icon"></i>
                            } @else {
                                <img class="lang-img" [src]="'/assets/images/flags/' + lang.id + '.png'" [alt]="lang.label">
                            }
                            {{ lang.label | translate }}
                        </button>
                    }
                    @if (acl.can(permissions.core.translations.listRead)) {
                        <button type="button" class="manage-languages" mat-menu-item [routerLink]="['', { outlets: { p1: ['translate'] } }]">
                            <div class="manage-languages-text">
                                Manage languages
                            </div>
                        </button>
                    }
                </mat-menu>
            </li>
            <li class="user-desktop">
                <div class="user-info" (click)="openUserSettings()" aria-hidden="true">
                    <div class="">
                        <div class="user-name">
                            {{ currentSession.displayName || currentSession.fullName}}
                        </div>
                        <div class="user-tenant">
                            {{ 'ESSR' | translate }}
                        </div>
                    </div>
                    <img *ngIf="currentSession.avatarUrl" [src]="currentSession.avatarUrl" class="rounded-circle user-avatar" alt="user avatar">
                </div>
            </li>
        </ul>
    </div>
</header>

<app-user-settings></app-user-settings>

