import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSettingsComponent } from './user-settings.component';
import { PipesModule } from '@shared/pipes.module';
import { AngularMaterialModule } from '@shared/angular-material.module';
import { FormsModule } from '@angular/forms';
import { Ng1LinkModule } from '../shared/modules/ng1-link/ng1-link.module';
import { RouterLink } from '@angular/router';
import { UserAsideMenuModule } from '../../../layout/modules/user-aside-menu/user-aside-menu.module';

@NgModule({
    declarations: [UserSettingsComponent],
    imports: [
        CommonModule,
        PipesModule,
        AngularMaterialModule,
        FormsModule,
        Ng1LinkModule,
        RouterLink,
        UserAsideMenuModule,
    ],
    exports: [UserSettingsComponent],
})
export class UserSettingsModule {}
