<div class="title" mat-dialog-title> {{ data.title | translate }} </div>

<mat-dialog-content class="mat-typography">
    <p class="message">
        {{ data.message | translate }}
    </p>
    <p class="description">
        {{ data.description | translate }}
    </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button color="accent" [mat-dialog-close]="false"> {{ translations.commons.actions.keepEditing.label | translate }} </button>
    <button mat-flat-button color="danger" [mat-dialog-close]="true"> {{ translations.commons.actions.discardChanges.label | translate }} </button>
</mat-dialog-actions>
