import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SdDialogMessageComponent } from './components/sd-dialog-message/sd-dialog-message.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [SdDialogMessageComponent],
    imports: [CommonModule, TranslateModule, MatDialogModule, MatIconModule, MatButtonModule],
    exports: [SdDialogMessageComponent],
})
export class SdDialogMessageModule {}
