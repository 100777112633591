import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SdTableClientColumn } from '../../types';

export interface SelectedRowsData {
    endpoint: string;
    selectedRows: number[];
    clientColumns: SdTableClientColumn[];
}

@Component({
    selector: 'app-selected-rows',
    templateUrl: './selected-rows.component.html',
    styleUrls: ['./selected-rows.component.scss'],
})
export class SelectedRowsComponent {
    selectedRows: number[] = [];
    constructor(
        private dialogRef: MatDialogRef<SelectedRowsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SelectedRowsData,
    ) {
        this.dialogRef.backdropClick().subscribe(() => {
            this.dialogRef.close(this.selectedRows);
        });
    }
}
