<mat-form-field class="filter">
    <mat-label> {{ label }} </mat-label>
    <input matInput [(ngModel)]="value" (ngModelChange)="onChange($event)" [placeholder]="placeholder">
    <span matSuffix (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()" tabindex="0">
        <sd-select
                [(ngModel)]="selectedOptions"
                (ngModelChange)="onOptionChange($event)"
                [data]="options"
                [multiple]="true"
                [hasReload]="false"
                [displayOnlyIcon]="true"
                icon="more_vert"
        ></sd-select>
    </span>
</mat-form-field>
