import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, HostListener, OnInit } from '@angular/core';
import { GSearchService } from '../../services/g-search.service';
import { MenuToggleService } from '../../../layout/services/menu-toggle.service';
import { Translations } from '@translation-keys';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-g-search-panel',
    templateUrl: './g-search-panel.component.html',
    styleUrl: './g-search-panel.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GSearchPanelComponent implements OnInit {
    shouldDisplayMenu = false;
    searchValue = '';
    currentIndex = -1;
    isAsideOpen = true;

    translations = Translations;

    @HostListener('window:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (!this.shouldDisplayMenu) return;

        const actions = ['Escape', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];

        if (!actions.includes(event.key)) {
            return;
        }

        const searchInput = document.getElementById('searchHeader');
        const focusElement = document.activeElement;

        if (searchInput === focusElement && !['ArrowDown', 'Escape'].includes(event.key)) {
            return;
        }

        event.preventDefault();

        if (event.key === 'Escape') {
            this.gSearchService.close();
            return;
        }

        const upstreamResultDetails: { count: number; x: number; y: number; items: NodeListOf<HTMLAnchorElement> }[] =
            [];

        const upstreams = document.querySelector('.upstreams');
        if (upstreams) {
            const groups = upstreams.querySelectorAll('.group');
            groups.forEach(group => {
                const rect = group.getBoundingClientRect();
                const items = group.querySelectorAll('a');
                upstreamResultDetails.push({
                    items,
                    count: items.length ?? 0,
                    x: rect.left, // X position of the group element relative to the viewport
                    y: rect.top, // Y position of the group element relative to the viewport
                });
            });
        }

        /* no items found */
        if (!upstreamResultDetails.some(item => item.count > 0)) {
            return;
        }

        if (event.key === 'ArrowUp') {
            this.currentIndex--;

            if (this.currentIndex <= -1) {
                this.currentIndex = -1;
                const search = document.getElementById('searchHeader');
                search?.focus();

                return;
            }

            const { groupIndex, aux } = this.getCurrentUpstream(upstreamResultDetails);

            if (groupIndex === -1) {
                return;
            }
            const element = upstreamResultDetails[groupIndex].items[aux];
            this.focusItem(element);
        }

        if (event.key === 'ArrowRight') {
            const { groupIndex } = this.getCurrentUpstream(upstreamResultDetails);

            if (groupIndex === -1) {
                return;
            }

            for (let i = 0; i < upstreamResultDetails.length; i++) {
                if (i > groupIndex && upstreamResultDetails[i].count > 0) {
                    const index = this.getIndexOfFirstElementByUpstreamIndex(upstreamResultDetails, i);
                    this.focusItem(upstreamResultDetails[i].items[0]);
                    this.currentIndex = index - 1;
                    break;
                }
            }
        }

        if (event.key === 'ArrowLeft') {
            const { groupIndex } = this.getCurrentUpstream(upstreamResultDetails);

            if (groupIndex === -1) {
                return;
            }

            for (let i = upstreamResultDetails.length; i >= 0; i--) {
                if (i < groupIndex && upstreamResultDetails[i].count > 0) {
                    const index = this.getIndexOfFirstElementByUpstreamIndex(upstreamResultDetails, i);
                    this.focusItem(upstreamResultDetails[i].items[0]);
                    this.currentIndex = index - 1;
                    break;
                }
            }
        }

        if (event.key === 'ArrowDown') {
            this.currentIndex++;

            const { groupIndex, aux } = this.getCurrentUpstream(upstreamResultDetails);

            if (groupIndex === -1) {
                return;
            }
            const element = upstreamResultDetails[groupIndex].items[aux];
            this.focusItem(element);
        }
    }

    constructor(
        protected gSearchService: GSearchService,
        protected menuToggleService: MenuToggleService,
        private changeDetector: ChangeDetectorRef,
        public destroyRef: DestroyRef,
    ) {}

    ngOnInit() {
        this.gSearchService.isMenuRendering$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(status => {
            if (status === false) {
                this.currentIndex = -1;
            }
            this.shouldDisplayMenu = status;
            this.changeDetector.detectChanges();
        });

        this.menuToggleService.isToggled$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(status => (this.isAsideOpen = status));

        this.gSearchService.searchValue$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
            this.searchValue = value;
            this.changeDetector.detectChanges();
        });
    }

    closeMenu() {
        this.gSearchService.close();
    }

    getIndexOfFirstElementByUpstreamIndex(
        upstreamResultDetails: { count: number; x: number; y: number; items: NodeListOf<HTMLAnchorElement> }[],
        upstreamIndex: number,
    ) {
        let aux = 0;
        upstreamResultDetails.forEach((item, index) => {
            if (index > upstreamIndex) return;
            if (index < upstreamIndex) {
                aux += item.count;
            }
        });
        return aux + 1;
    }

    private getCurrentUpstream(upstreamResultDetails) {
        let aux = this.currentIndex;
        let groupIndex = -1;
        let foundIndex = false;
        upstreamResultDetails.forEach((upstream, i) => {
            if (foundIndex) return;
            if (aux < upstream.count) {
                groupIndex = i;
                foundIndex = true;
            } else {
                aux -= upstream.count;
            }
        });

        return { groupIndex, aux };
    }

    private focusItem(element: HTMLElement) {
        element.focus();
        const mouseOverEvent = new MouseEvent('mouseover', { bubbles: true, cancelable: true });
        element.dispatchEvent(mouseOverEvent);
    }
}
