import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MenuItem, MenuModule, MenuService } from '../../../services/menu.service';
import { NavigationEnd, Router } from '@angular/router';
import { ResponsiveNavigationModulesComponent } from '../components/responsive-navigation-modules/responsive-navigation-modules.component';
import { FullPageDrawerService } from '../../../services/full-page-drawer.service';
import { UpgradeModule } from '@angular/upgrade/static';

@Injectable({
    providedIn: 'root',
})
export class ResponsiveNavigationService {
    private init = false;

    responsiveNavigationModulesTemplate?: TemplateRef<ResponsiveNavigationModulesComponent>;

    private currentModule: BehaviorSubject<MenuModule | null> = new BehaviorSubject<MenuModule | null>(null);
    currentModule$: Observable<MenuModule | null> = this.currentModule.asObservable();

    private currentMenu: BehaviorSubject<MenuItem | null> = new BehaviorSubject<MenuItem | null>(null);
    currentMenu$: Observable<MenuItem | null> = this.currentMenu.asObservable();

    private previousMenu: BehaviorSubject<MenuItem | MenuModule | null> = new BehaviorSubject<
        MenuItem | MenuModule | null
    >(null);
    previousMenu$: Observable<MenuItem | MenuModule | null> = this.previousMenu.asObservable();

    constructor(
        public router: Router,
        public menuService: MenuService,
        public fullPageDrawerService: FullPageDrawerService,
        public upgrade: UpgradeModule,
    ) {}

    listen() {
        if (this.init) return;
        this.init = true;
        if (!this.menuService.init) {
            this.menuService.loadRoutes();
        }

        this.menuService.modules.asObservable().subscribe(() => this.updateData());
        this.menuService.menusByModule.asObservable().subscribe(() => this.updateData());

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.updateData();
            }
        });
    }

    openModulesDrawer() {
        if (this.responsiveNavigationModulesTemplate) {
            this.fullPageDrawerService.setTemplate(this.responsiveNavigationModulesTemplate);
            this.fullPageDrawerService.open();
        }
    }

    setResponsiveNavigationModulesTemplate(template: TemplateRef<ResponsiveNavigationModulesComponent>) {
        this.responsiveNavigationModulesTemplate = template;
    }

    updateData() {
        this.setCurrentModule();
        this.setCurrentMenu();
    }

    setCurrentModule() {
        const modules: Array<MenuModule> = this.menuService.modules.value;
        let module = this.router.url.split('/')[1];

        if (module.length === 0 || module === 'temp-empty') {
            module = window.location.pathname.split('/')[1];
        }

        if (module === 'sd1') {
            module = 'sd';
        }
        this.currentModule.next(modules.find(z => z.url === module) ?? null);
    }

    setCurrentMenu() {
        let url = this.router.url;

        if (url.length === 0 || url === '/temp-empty' || url === '/') {
            url = window.location.pathname;
        }
        this.currentMenu.next(this.menuService.findMenuByUrl(url) ?? null);
        this.setPreviousMenu();
    }

    setPreviousMenu() {
        const label = this.currentMenu.value?.label;
        if (label) {
            this.previousMenu.next(this.menuService.getPreviousMenuFromLabel(label) ?? null);
        } else {
            this.previousMenu.next(null);
        }
    }
}
