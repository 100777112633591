import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, map, BehaviorSubject } from 'rxjs';
import { UpgradeModule } from '@angular/upgrade/static';
import { ObjectUtilsService } from '../../shared/services/object-utils.service';
import { AuthStorageService } from '@auth/services/auth-storage.service';

export interface Websocket {
    host: string;
    port: string;
    public_key: string;
    cluster: string;
}

export interface CurrentSession {
    id: number;
    displayName: string | null;
    fullName: string | null;
    avatarUrl: string | null;
    languagePreference: string | null;
    hasLegacyAngular: boolean;
    permissions: string[];
    roles: string[];
    menuRoute: string;
    websocket?: Websocket;
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    currentSession: Subject<CurrentSession> = new Subject<CurrentSession>();
    userHasLegacyAngular: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    legacyAngularLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    currentSessionData?: CurrentSession;

    constructor(
        protected _httpService: HttpClient,
        protected upgrade: UpgradeModule,
        protected objectUtilsService: ObjectUtilsService,
        protected authStorageService: AuthStorageService,
    ) {}
    me(isLogin = false) {
        if (!this.authStorageService.getItem('permissions') && !isLogin) {
            return;
        }

        const headers = new HttpHeaders().set('Content-type', 'application/json');

        this._httpService
            .get(`/api/me`, {
                headers,
                responseType: 'text',
            })
            .pipe(map(res => JSON.parse(res)))
            .subscribe(response => {
                this.currentSession.next(response);
                this.currentSessionData = response;
                this.authStorageService.setItem('roles', JSON.stringify(response.roles.sort()));
                this.authStorageService.setItem('permissions', JSON.stringify(response.permissions.sort()));
                this.authStorageService.setItem('color_menubar', response.colorMenuBar);
                this.authStorageService.setItem('documentation_roles', JSON.stringify(response.documentationRoles));
                this.setLegacyAngularAuth();
            });
    }

    verifyToken(token: string, email: string) {
        const headers = new HttpHeaders().set('Content-type', 'application/json');

        return this._httpService
            .get(`/reset-password/verify-token/${token}/${email}`, {
                headers,
                responseType: 'text',
            })
            .pipe(map(res => JSON.parse(res)))
            .toPromise();
    }

    logout() {
        const headers = new HttpHeaders().set('Content-type', 'application/json');

        return this._httpService
            .get(`/api/logout`, {
                headers,
                responseType: 'text',
            })
            .pipe(map(res => JSON.parse(res)))
            .toPromise();
    }

    setLegacyAngularAuth() {
        if (!this.upgrade.$injector || !this.currentSessionData) return;

        const token = this.authStorageService.getItem('token') as string;

        const $auth = this.upgrade.$injector.get('$auth');
        $auth.setToken({ token, data: { token } });

        const aclService = this.upgrade.$injector.get('AclService');

        const sortedRoles = [...this.currentSessionData.roles].sort();
        const sortedPermissions = [...this.currentSessionData.permissions].sort();

        const permissionsPerRole = Math.ceil(sortedPermissions.length / sortedRoles.length);
        const legacyAclAbilities: { [key: string]: Array<string> } = {};
        sortedRoles.forEach((role, index) => {
            const start = index * permissionsPerRole;
            const end = start + permissionsPerRole;
            legacyAclAbilities[role] = sortedPermissions.slice(start, end);
        });
        aclService.setAbilities(legacyAclAbilities);
        this.currentSessionData.roles.forEach(role => aclService.attachRole(role));
        this.legacyAngularLoaded.next(true);
    }

    setUserHasLegacyAngular() {
        this.userHasLegacyAngular.next(true);
    }
}
