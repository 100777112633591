import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Translations } from '@translation-keys';

@Component({
    selector: 'app-attachment-link-preview',
    templateUrl: './attachment-link-preview.component.html',
    styleUrls: ['./attachment-link-preview.component.scss'],
})
export class AttachmentLinkPreviewComponent {
    translations = Translations;

    constructor(@Inject(MAT_DIALOG_DATA) public data: { link: string; name: string }) {}

    download() {
        const fileUrl = this.data.link;
        fetch(fileUrl)
            .then(response => response.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.onload = () => {
                    const dataUrl = reader.result as string;
                    this.saveDataUrlAsFile(dataUrl, this.data.name);
                };
                reader.readAsDataURL(blob);
            });
    }

    private saveDataUrlAsFile(dataUrl: string, fileName: string): void {
        const a = document.createElement('a');
        a.href = dataUrl;
        a.download = fileName;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
}
