<a href="{{ item.link }}" class="content">
    <div class="title">
        {{ item.title }}
        <span class="sub-title" *ngIf="item.subtitle">
            <{{ item.subtitle | translate }}>
        </span>
    </div>
    <span (click)="closeSearch()" class="info" aria-hidden="true">
        #{{ item.upstreamRecordId }}
        @for (tag of tags; track tag.descriptiveId) {
            <span [class]="'tag small ' + tag.descriptiveId"> {{ tag.textTranslationKey | translate }} </span>
        }
    </span>
</a>
