import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AuthStorageService {
    private storage: 'local' | 'session' = 'session';

    setStorage(storage: 'local' | 'session') {
        this.storage = storage;
    }

    getStorage() {
        return this.storage;
    }

    setItem(key: string, value: string) {
        if (this.storage === 'local') {
            localStorage.setItem(key, value);
        }

        if (this.storage === 'session') {
            sessionStorage.setItem(key, value);
        }
    }

    getItem(key: string) {
        if (this.storage === 'local') {
            return localStorage.getItem(key);
        }

        if (this.storage === 'session') {
            return sessionStorage.getItem(key);
        }

        return null;
    }
}
