import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DateFormatterPipe } from './date-formatter.pipe';
import { DatetimeFormatterPipe } from './datetime-formatter.pipe';
import { CurrencyFormatterPipe } from './currency-formatter.pipe';
import { NumberFormatterPipe } from './number-formatter.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { UtcDatetimePipe } from './utc-datetime.pipe';

@NgModule({
    declarations: [
        DateFormatterPipe,
        DatetimeFormatterPipe,
        CurrencyFormatterPipe,
        NumberFormatterPipe,
        UtcDatetimePipe,
        SafeUrlPipe,
    ],
    imports: [],
    exports: [
        TranslateModule,
        DateFormatterPipe,
        DatetimeFormatterPipe,
        CurrencyFormatterPipe,
        NumberFormatterPipe,
        UtcDatetimePipe,
        SafeUrlPipe,
    ],
})
export class PipesModule {}
