import { Component, DestroyRef, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { GlobalAddActions } from '../../../global-add/types';
import { GlobalAddService } from '../../../global-add/services/global-add.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-new',
    standalone: false,
    templateUrl: './new.component.html',
    styleUrl: './new.component.scss',
})
export class NewComponent implements OnInit {
    globalAddActions: GlobalAddActions = [];

    constructor(
        private bottomSheetRef: MatBottomSheetRef<NewComponent>,
        public globalAddService: GlobalAddService,
        public destroyRef: DestroyRef,
    ) {}

    ngOnInit() {
        this.globalAddService.actions$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(actions => (this.globalAddActions = actions));
    }

    close() {
        this.bottomSheetRef.dismiss();
    }
}
