<span class="column-data" *ngIf="!data && column.type !== 'client-render'">
    -
</span>

<div *ngIf="data || column.type === 'client-render'">

    <span class="column-data" *ngIf="column.type === 'string'">
        {{ data }}
    </span>

    <span class="column-data" *ngIf="column.type === 'boolean'">
        {{ 'common.' + data | translate }}
    </span>

    <span class="column-data" *ngIf="column.type === 'number'">
        {{ data | number }}
    </span>

    <span class="column-data" *ngIf="column.type === 'date'">
        {{ data | dateFormatter }}
    </span>

    <span class="column-data" *ngIf="column.type === 'datetime'">
        {{ data | datetimeFormatter }}
    </span>

    <span class="column-data" *ngIf="column.type === 'currency'">
        {{ data | currency: 'CHF' }}
    </span>

    <span class="column-data" *ngIf="column.type === 'html'" [innerHTML]="data"></span>

    <div *ngIf="column.type === 'client-render'">

        <span class="button-container column-data" [ngClass]="currentClientColumn[index]?.class">

            <div *ngFor="let action of currentClientColumn[index]?.actions;">
                
                <mat-icon *ngIf="action.type === 'visual-icon'" class="visual-icon" [fontIcon]="action.icon ?? ''"></mat-icon>

                <a *ngIf="action.type === 'text-link'" [href]="action.url" [target]="action.target">
                    {{ action.text }}
                </a>

                <a
                  *ngIf="action.type === 'button'"
                  aria-hidden="true"
                  mat-icon-button
                  [matTooltip]="action.tooltip ?? '' | translate"
                  matTooltipPosition="below"
                  [color]="action.color"
                  (click)="action.callback(rowData)">
                    <mat-icon class="icon" [fontIcon]="action.icon ?? ''"></mat-icon>
                </a>

                <a
                  *ngIf="action.type === 'link'"
                  [href]="action.url"
                  mat-icon-button
                  [matTooltip]="action.tooltip ?? '' | translate"
                  matTooltipPosition="below"
                  [color]="action.color">
                    <mat-icon class="icon" [fontIcon]="action.icon ?? ''"></mat-icon>
                </a>

                <span *ngIf="['button', 'link'].includes(action.type)" class="mobile-icon-info">
                    {{ action.tooltip ?? '' | translate }}
                </span>

            </div>
            
        </span>

    </div>

</div>