import { NgModule } from '@angular/core';
import { ContactsTimelineComponent } from './components/timeline/timeline.component';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@shared/pipes.module';
import { SdDateRangeSelectorWithDataPreviewModule } from '../../../../../core/modules/shared/modules/sd-date-range-selector-with-data-preview/sd-date-range-selector-with-data-preview.module';
import { SdTimelineModule } from '../../../../../core/modules/shared/modules/sd-timeline/sd-timeline.module';

@NgModule({
    declarations: [ContactsTimelineComponent],
    imports: [CommonModule, PipesModule, SdDateRangeSelectorWithDataPreviewModule, SdTimelineModule],
    exports: [ContactsTimelineComponent],
})
export class TimelineModule {}
