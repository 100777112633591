import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-data-search-result-header',
    templateUrl: './data-search-result-header.component.html',
    styleUrl: './data-search-result-header.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSearchResultHeaderComponent {
    /**
     * Message on content
     */
    @Input({ required: true }) title = '';

    /**
     * Should add loading after message content
     */
    @Input() isLoading = false;
}
