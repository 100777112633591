import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

import { TimelineDetailsComponent } from '../components/timeline-details/timeline-details.component';
import { TimelineDataLakeEmailsMessagesResult } from '../../data-lake-emails/types/data-lake-emails-timeline.types';
import {
    TimelineItemProvider,
    TimelineItemProviderData,
    TimelineItemProviderRegistryService,
} from '../../contacts/modules/details/modules/timeline/services/timeline-item-provider-registry.service';
import {
    TimelineColorsEnum,
    TimelineDirectionEnum,
    TimelineItem,
} from '../../core/modules/shared/modules/sd-timeline/types';
import { Apollo } from 'apollo-angular';

@Injectable({
    providedIn: 'root',
})
export class TimelineItemProviderService extends TimelineItemProvider {
    timelineDetailsComponent = TimelineDetailsComponent;
    interval = 5000;
    private intervalRef!: unknown;

    constructor(public contactTimelineService: TimelineItemProviderRegistryService) {
        super('accountsPayable', TimelineColorsEnum.green);
    }

    load() {
        this.subscribeCount = this.subscribeCount.bind(this);
        this.subscribeDatesWithData = this.subscribeDatesWithData.bind(this);
        this.subscribeData = this.subscribeData.bind(this);
        this.dataMapper = this.dataMapper.bind(this);
        this.destroySubscriptions = this.destroySubscriptions.bind(this);
        // this.contactTimelineService.register(this);
    }

    subscribeCount(
        contactId: number,
        utcStartDate: Date,
        utcEndDate: Date,
        timelineItemProviderData: TimelineItemProviderData,
        apollo: Apollo,
    ): void {
        if (contactId && utcStartDate && utcEndDate && apollo) {
            timelineItemProviderData.currentCount.next(20);
        }
    }

    subscribeDatesWithData(contactId: number, timelineItemProviderData: TimelineItemProviderData, apollo: Apollo) {
        if (!contactId && !apollo) return;
        let date = DateTime.now();
        const dates: Date[] = [];
        setTimeout(() => {
            this.intervalRef = setInterval(() => {
                dates.push(new Date(date.toFormat('yyyy-MM-dd')));
                timelineItemProviderData.currentDatesWithData.next(dates);
                date = date.plus({ month: 1 });
            }, this.interval);
        }, 3000);
    }

    subscribeData(
        contactId: number,
        utcStartDate: Date,
        utcEndDate: Date,
        timelineItemProviderData: TimelineItemProviderData,
    ) {
        if (!contactId && !utcEndDate && !utcStartDate) return;
        let date = DateTime.now();
        let id = 1;
        const items: TimelineDataLakeEmailsMessagesResult = {
            results: [],
        };

        setTimeout(() => {
            setInterval(() => {
                items.results.push({
                    id,
                    date: date.toFormat('yyyy-MM-dd H:mm:ss'),
                    subject: 'simm',
                    sender: { email: 'ximira@ximira.com' },
                    attachmentsAggregate: {
                        aggregate: {
                            count: 0,
                        },
                    },
                });

                const availableItems = items.results.filter(
                    item => new Date(item.date) > utcStartDate && new Date(item.date) < utcEndDate,
                );

                timelineItemProviderData.currentData.next(availableItems);
                id++;
                date = date.plus({ month: 1 });
            }, this.interval);
        }, 3000);
    }

    dataMapper(item): TimelineItem {
        return {
            utcDate: new Date(item.date),
            title: item.subject,
            color: this.color,
            direction: TimelineDirectionEnum.left,
            icon: '',
            detailComponent: this.timelineDetailsComponent,
            originalItem: item,
        };
    }

    destroySubscriptions(): void {
        if (this.intervalRef) {
            clearInterval(this.intervalRef as number);
        }
    }
}
