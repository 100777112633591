import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterModule } from '@angular/router';
import { Ng2LinkComponent } from './components/ng2-link/ng2-link.component';

/**
 * This module provides the ability for AngularJs (old) to call Angular (new) links
 */
@NgModule({
    declarations: [Ng2LinkComponent],
    imports: [CommonModule, RouterModule, RouterLink],
})
export class Ng2LinkModule {}
