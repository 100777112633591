import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FullPageDrawerService {
    private template: BehaviorSubject<TemplateRef<unknown> | null> = new BehaviorSubject<TemplateRef<unknown> | null>(
        null,
    );
    template$: Observable<TemplateRef<unknown> | null> = this.template.asObservable();

    private openDrawer: Subject<void> = new Subject<void>();
    openDrawer$: Observable<void> = this.openDrawer.asObservable();

    setTemplate(template: TemplateRef<unknown> | null): void {
        this.template.next(template);
    }

    open() {
        this.openDrawer.next();
    }
}
